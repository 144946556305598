import zhdApi from './api2.js'
export default {
	...zhdApi,
	login: '/u/login',  //账号密码登录
	searchEnterprise: '/u/search/enterprise', // 根据名称查企业
	searchEnterpriseById: '/u/search/getEnterpriseByShareUserId', // wyb 根据分享分id查企业 免登录
	searchUserInfo:'/user/userInfo',//查询登陆人的信息
	// zhd------------------------------
	register: '/u/register', // 注册
	smsCode: '/c/sms/code/', // 获取手机验证码
	smsCodeV: '/c/sms/code/v', // 验证验证码
	uploadReq: '/register/file/upload/temp', // 上传图片
	// --------------------------------------------
	// 首页api
	userHomeBacklog: '/user/home/backlog', // 待办事项
	userHomeCustomerAnalyse: '/user/home/customer/analyse', // 客户分析
	userHomeCustomerStatistics: '/user/home/customer/statistics', // 客户统计
	userHomeInfoStatistics: '/user/home/info/statistics', // 概况
	userHomeOperatingTrend: '/user/home/operating/trend', // 运营趋势
	userHomeSaleInfo: '/user/home/sale/info', // 销售信息
	userHomeSupplyInfo: '/user/home/supply/info', // 供应商信息
	// --------------------------------------------
	getListSkuCategoryV1: 'sku/category/jsonV1/listSkuCategory', //获取分类的json数据
	bannerCategoryCurrent: '/quote/setSku/list/banner', //查询顶级分类
	supplyCategoryCurrent: '/user/home/supply/info/brand', //查询顶级分类
	saleCategoryCurrent: '/user/home/sale/info/brand', //查询顶级分类
	listSkuCategoryForFirst: 'sku/category/listSkuCategoryForFirst', //查询顶级分类
	listSkuCategoryForChildren: 'sku/category/listSkuCategoryForChildren', //查询子级分类
	listSkuCategoryForChildrenOne: 'sku/category/listSkuCategoryForChildrenOne',  //获取子集分类一层
	jsonV1: 'sku/search/jsonV1', //获取产品库json数据
	skuPage: 'sku/page/es',  //获取商品列表
	skuSpecList: 'sku/spec/list',  //获取规格列表
	selectionRemoveAll: '/selection/remove/all',  //清空购物车
	selectionCreate: 'selection/create',  //加入购物车
	selectionCreateInquiry: 'selection/createInquiry',  //生成询价单
	selectionUpdate: 'selection/update',  //修改购物车
	selection: 'selection',  //移除购物车商品
	selectionList: 'selection/list',  //购物车列表
	userConsignee: 'user/consignee',  //新增地址
	userConsigneePage: 'user/consignee/page',  //收货地址列表
	userConsigneeList: 'user/consignee/list',  //获取收货地址列表
	enterpriseListByEnterpriseId: 'enterprise/listByShareEnterpriseId',  //询价企业
	inquiryPageBuyer: 'inquiry/pageBuyer',  //买家询价单列表
	inquiryInfo: 'inquiry/buyer/info',  //询价详情(我要采购)
	invitation: 'wx/qr/code/userOrSupplier/invitation',  //获取二维码
	inquirySellerInfo: 'inquiry/seller/info',  //询价详情(我要销售)
	uploadFile: '/file/upload/oss',  //文件上传
	userListByShareEnterpriseId: 'user/listByShareUserId',  //获取询价客户
	toAutoQuoteByInquiryId: 'inquiry/toAutoQuoteByInquiryId',  //自动报价
	userListPageByShareEnterpriseId: '/user/pageByShareUserId',  //获取询价客户
	enterpriseListPageByEnterpriseId:'enterprise/pageByCreateInquiry',  //获取询价客户
	inquiryCreate: 'inquiry/create', //新增询价单
	quoteSetCreate: 'quote/set/create',  //新增代理设置
	quoteSetSkuCreate: 'quote/setSku/create',  //新增自动报价商品
	quoteSetSkuPage: 'quote/setSku/page',  //获取商品列表
	quoteSetSkuSkuIds: 'quote/setSku/skuIds',  //获取商品ids
	quoteSetSkuRemove: 'quote/setSku/remove',  //删除自动报价商品
	quoteSetSkuUpdate: 'quote/setSku/update',  //编辑自动报价商品
	quoteSetSkuUpdateBatch: 'quote/setSku/updateBatch',  //批量编辑自动报价商品
	quoteSetList: 'quote/set/list',  //查找列表代理(非代理)列表
	quoteSetUpdate: 'quote/set/update',  //修该代理(非代理)设置
	inquiryPageSeller: 'inquiry/pageSeller',  //客户询价列表
	quoteCreate: 'quote/create', //新增报价
	purchaseCartCreate: 'purchase/cart/create',  //加入采购购物车
	purchaseCartListCart: 'purchase/cart/listCart',  //购物车列表
	inquiryPurchaseInfo: 'inquiry/purchase/info',  //加入采购单
	purchaseCart: 'purchase/cart',  //删除购物车商品
	purchaseCartCreatePurchaseAdmin: 'purchase/cart/createPurchase/admin', // 我要采购->我的询价->购物车->生成采购单(平台采购)
	purchaseCartCreatePurchase: 'purchase/cart/createPurchase',  //生成采购单
	purchaseUndo:'/purchase/undo',//采购作废
	purchaseCollectionUndo:'/purchase/collection/undo',
	purchasePage: 'purchase/page',  //订单列表
	purchaseShipUndo:'/purchase/purchaseShip/undo',//采购单发货作废
	purchaseUndoAudit:'/purchase/undo/audit',
	quotePage: 'quote/page',  //报价单列表
	purchaseDetails: 'purchase',  //订单详情
	quoteInfo: 'quote/info',  //报价信息查看
	quoteModifyQuoteItem: 'quote/modifyQuoteItem',  //报价修改
	purchaseUpdateContractFile: 'purchase/updateContractFile',  //上传合同
	purchaseUpdateBackContractFile: 'purchase/updateBackContractFile',  //回传合同
	purchaseList: 'purchase/list',  //采购单发货商品列表
	purchasePurchaseShip: 'purchase/purchaseShip',  //发货
	purchaseUpdatePayment: 'purchase/updatePayment',  //确认付款
	purchaseCollection: 'purchase/collection',  //采购单收款
	purchaseGetExpress: 'purchase/getExpress',  //查看物流
	addEnterprise: 'enterprise',  //新增企业
	enterprisePage: 'enterprise/page',  //企业列表
	enterpriseList: 'enterprise/list',  //获取企业列表
	shareInquiryShareInfo: 'share/inquiry/shareInfo',  //分享查看询价单
	uAddEnterprise: 'u/add/enterprise',  //新增企业
	uSearchEnterprise: 'u/search/enterprise',  //通过名称查询企业
	platformUserListByEnterpriseId:'/user/platformUserListByEnterpriseId',//查询企业下的员工
	updateCustomerIdForEnterprise:'/enterprise/updateCustomerIdForEnterprise',//企业-主账号转移(所属人)
	purchaseReceipt: 'purchase/receipt',  //确认收货
	userMyUserPage: 'user/myUserPage',  //我的客户
	userMyUserEnterpriseInfo: 'user/myUserEnterpriseInfo',  //我的客户企业详情
	enterpriseMyEnterprisePage: 'enterprise/myEnterprisePage',  //我的供应商列表

	myEnterpriseInfo: 'enterprise/myEnterpriseInfo',  //我的供应商详情

	getUser: 'user/userInfo',  //个人信息详情
	userCreateEmption: 'user/createEmption',  //新增我的买卖商品品牌
	userPageEmption: 'user/pageEmption', //我的买卖
	userDelEmption: 'user/delEmption',  //删除我的买卖
	userGetUserInvoice: 'user/getUserInvoice',  //开票信息详情
	userInvoiceEdit: 'user/invoiceEdit',  //开票信息修改
	enterpriseTreeList: 'enterprise/treeList',  //企业类型列表
	userEditUser: 'user/editUser',  //修改用户特别信息
	userPassword: 'user/password',  //修改账号密码
	purchaseReceiptPage: 'purchase/receiptPage',  //资金列表
	userListUserSetByUserId: 'user/listUserSetByUserId',  //系统设置列表
	userSetUserSetBatch: 'user/setUserSetBatch',  //系统设置批量修改
	stockPage: 'stock/page', //库存列表
	stockInStock: 'stock/inStock',  //入库
	stockListShipByBuyerId: 'stock/listShipByBuyerId', //我的收货单
	stockListByShipId: 'stock/listByShipId',  //已收货单的明细
	stockPageIn: 'stock/pageIn',  //入库明细列表
	stockInfo: 'stock/info',  //入库出库详情
	stockPageOut: 'stock/pageOut',  //出库明细列表
	stockOutStock: 'stock/outStock',  //出库
	stockListBySellerIdStockOut: 'stock/listBySellerIdStockOut',  //查询我的销售单列表
	stockListItemByDocumentId: 'stock/listItemByDocumentId',  //查询我的销售单明细
	categoryListCategory: 'category/listCategory',  //获取大中小分类列表
	userGetUserSet: 'user/getUserSet',  //获取收款二维码
	uRetrievePassword: 'u/retrieve/password',  //用户找回密码
	uWechatLogin: 'u/wechat/login',  //微信扫码登录接口
	uWechatBind: 'u/wechat/bind', //微信扫码绑定
	uBindCancelBind: 'user/unBoundWeChat',//微信解绑
	/** 分享页面 微信扫码登录接口 跟uWechatLogin 不一样 需要多两个参数 */
	uShareWechatLogin: 'u/wechat/loginQuote',
	shareInquiryLocation: '/share/inquiry/location',  //根据经纬度获取当前位置信息
	uAliYunOcr: '/u/aliYunOcr',  //营业执照识别
	uPhoneLogin: 'u/phoneLogin',  //手机验证码登录
	purchaseCartUpdate: '/purchase/cart/update',  //修改商品信息(采购购物车)
	quoteCreateByMyself: '/quote/create/by/myself', //新增报价
	invoicePage: '/invoice/page',  //发票列表
	invoiceUpdateAddress: '/invoice/update/address',  //修改发票收货地址
	purchaseCollectionList: '/purchase/collection/list', //采购单收款明细列表
	// purchaseShipUndo: '/purchase/purchaseShip/undo', //发货作废
	userConsigneeDefault: '/user/consignee/default',  //获取用户默认收货地址
	purchaseUpdateAddress: '/purchase/update/address',  //修改采购单默认地址
	invoiceInfo: '/invoice/info',  //获取订单发票详情
	invoiceCreate: '/invoice/create',  //申请开票
	invoicePass: '/invoice/pass',  //通过开票
	invoiceUnPass: '/invoice/unPass',  //不通过
	pageInitiative: '/quote/page/initiative', //我收到的报价
	purchaseCartCreatePurchaseByQuote: '/purchase/cart/createPurchaseByQuote', //转销售单
	pageByCreateInquiry:'/enterprise/pageByCreateInquiry',//新增可询价企业
	listBrandInfo:'/quote/agent/listBrandInfo',//可询企业对应接口
	quoteAgentList:'/quote/agent/list',//品牌代理列表
	// listBrandInfo:'/quote/agent/listBrandInfo',//品牌代理列表
	quoteSetListBelongIds:'/quote/set/list/belongIds',//折扣列表
	quoteAgentCreate: '/quote/agent/create',  //新增代理设置
	quoteAgentDeleteAll: '/quote/agent/delete',  //新增代理设置
	stockDownload: '/stock/import/template/download',  //库房模板下载
	stockImportFile:'/stock/import/importFile',  //库房表格导入
	exportUrl:'/opt/lida/temp',//模板下载的文件地址
	// optLidaTemp:'C:',//模板下载的文件地址
	// exportUrl: '/excel', // 导出公共
	stockImport:'/stock/import/import',//库存导入
	quoteAgentDelete:'/quote/set',
	getUserInvoiceUserId:'/user/getUserInvoice/userId',
	batchUpperOrLower:'/quote/setSku/upperOrLower/batch',//批量上架/下架
	removeBatch: '/quote/setSku/remove/batch',//批量删除
	listSkuCategoryByCategoryId:'/sku/category/listSkuCategoryByCategoryId',//商品类目列表-通过分类查询

	tabListOfHome:'/user/getUserSet',//系统设置详情
	searchEs: 'sku/search/es',  //es高亮搜索
	getSku: 'sku/getSku',  //获取sku详情
	skuPlatformPage: 'sku/platform/page',  //分页查询sku 默认
	bannerList: 'banner/page',
	enterpriseMyInfo : 'enterprise/myInfo',
	userGetHeadCount : "/user/getHeadCount",
	countDataAndQuoteSwitch : "/user/countDataAndQuoteSwitch",
	adminSetUserSet: "/user/admin/setUserSet",
	quoteBrandList: "/quote/brandList",
	quoteListByMyself  : "/quote/listByMyself",
	quoteWaiveQuote : "/quote/waiveQuote",
	quoteHistoryQuote : "/quote/historyQuote",
	selectDistributionList : "/user/distribution/admin/myDistributionSelect",
	pageByDis : '/quote/pageByDis',
	
	inquiryPageSellerByDis: 'inquiry/pageSellerByDis',  
	
	purchasePageByDis: 'purchase/pageByDis',  
	customByDis : '/user/distribution/admin/customByDis',
	pageSellerByQuote : '/inquiry/pageSellerByQuote'

	
}
