import homePage from "@/components/homePage/index.vue";
export default {
  path: "/myDistribution",
  component: homePage,
  redirect: "/myDistribution/customerInquiry",
  meta: { title: "分销管理" },
  children: [
    {
      path: "customerInquiry",
      component: () =>
        import("@/module/myDistributionMudule/viewPage/customerInquiry.vue"),
      meta: { title: "分销客户询价" },
    },
    {
      path: "customerOffer",
      name: "customerOffer",
      component: () =>
        import("@/module/myDistributionMudule/viewPage/customerOffer.vue"),
      meta: { title: "分销报价" },
    },
    {
      path: "order",
      component: () =>
        import("@/module/myDistributionMudule/viewPage/customerOrder.vue"),
      meta: { title: "分销订单" },
    },
    {
      path: "clientIndex",
      component: () =>
        import("@/module/myDistributionMudule/viewPage/clientIndex.vue"),
      meta: { title: "分销客户" },
    },
    {
      path: "myPurchase",
      component: () =>
        import("@/module/myDistributionMudule/viewPage/myPurchase.vue"),
      meta: { title: "分销采购" },
    },

    
  ],
};
