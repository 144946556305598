<template>
	<el-dialog
	    width="300px"
		:destroy-on-close="true"
		custom-class="lida-table-dialog"
		draggable
	  >
		<div class="a">
			<div class="a-title">选择显示字段</div>
			<div class="a-content">
				<el-checkbox-group
				    v-model="active"
				    @change="dataChange"
				  >
					<el-row v-for="item in data" :key="item.prop" >
						 <el-checkbox :label="item.prop">{{item.label}}</el-checkbox>
					</el-row>
				</el-checkbox-group>
			</div>
			<div class="a-footer">
				<el-button style="margin-right: 20px;" size="mini" @click="close">取消</el-button>
				<el-button style="margin-left: 20px;" type="primary" size="mini" @click="close">确认</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		},
		props:{
			data:{type:Array,default:() => []}
		},
		methods:{
			close(){ //关闭弹框
				this.$emit('update:modelValue', false);
			},
			dataChange(v){
				this.$emit('change',v);
			}
		},
		computed:{
			active(){
				return this.data.map(item => {
					if(!item.hide){
						return item.prop;
					}
				})
			}
		},
	}
</script>

<style scoped lang="less">
	.a{
		padding: 0px;
		.a-title{
			padding: 20px;
			font-weight: bold;
			border-bottom: 1px solid #dddddd;
			padding-top: 24px;
		}
		.a-content{
			overflow-y: auto;
			height: 50vh;
		}
		.el-row{
			padding: 15px 20px;
		}
		.a-footer{
			padding: 15px 0;
			display: flex;
			align-items: center;
			justify-content: center;
			border-top: 1px solid #dddddd;
		}
		
	}
</style>
