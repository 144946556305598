<template>
  <div>
    <el-upload
      class="upload-demo"
      :drag="isType === 'drag'"
      :action="getAction"
      :multiple="multiple"
      :headers="getHeaders"
      :on-remove="deleteFile"
      :on-success="onSuccess"
      :file-list="getFileList"
      :disabled="disabled"
      :listType="getListType"
      :limit="limit"
      :on-preview="handlePictureCardPreview"
      :show-file-list="!hideList"
    >
      <template #tip>
        <div v-if="isType === 'drag'" class="el-upload__tip">
          可支持Excel、PDF、word、JPG、PNG等多种格式
        </div>
        <span v-else></span>
      </template>

      <template #default>

        <p
          v-if="isType === 'add' && limit !== 1 && !disabled"
          class="iconfont icon-jiahao"
          style="font-size: 50px; color: #dddddd;"
        ></p>
        <p v-else-if="disabled" style=" color: #dddddd;">您不能上传文件</p>

        <div class="up-img" v-else-if="isType === 'add' && limit === 1">
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <p
            :style="{ fontSize: iconSize + 'px' }"
            :class="['iconfont', icon]"
            v-else
          ></p>
        </div>
        <div v-else>
          <i class="iconfont icon-yunshangchuan"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </div>
      </template>
    </el-upload>
    <el-dialog
	width="30%"
	v-model="dialogVisible"
	center
	>
	<div >
    <el-button style="margin-top: 15px; margin-left: 15px; " v-if="dialogImageUrl" @click="downLoadFile(dialogImageUrl)"  size="small"
                      class="change-btn"
                      type="danger"
                      plain  >下载附件</el-button>

		<el-image width="100%" style="margin: 0 auto;" :src="dialogImageUrl" alt="" fit="contain" />

	</div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/common/js/request.js";
import api from "@/common/js/api.js";
import { mapState } from "vuex";
import { uploadFileOnSuccess } from "@/common/js/uploadFile.js";
import { getBase64 } from "@/common/js/common.js";
import { downLoadFile } from "@/common/js/common.js";


export default {
  data() {
    return {
      fileList: [],
      imageUrlCopy: "",
      dialogVisible: false,
      dialogImageUrl: "",
    };
  },
  props: {
    modelValue: {
      //绑定的id
      type: Array,
      default: () => {
        return [];
      },
    },
    list: {
      //上传的文件列表
      type: Array,
      default: () => {
        return [];
      },
    },
    isType: {
      type: String,
      default: "drag",
    },
    multiple: {
      //是否多选
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      default: "",
    },
    limit: {
      type: Number,
      default: 1,
    },
    icon: {
      type: String,
      default: "icon-jiahao",
    },
    iconSize: {
      type: Number,
      default: 28,
    },
    /** 是否显示已上传文件列表 很多地方都是true,不需要就设置为false */
    showFileList: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState([
      "token", //获取token
    ]),
    getAction() {
      //上传文件地址
      return request.defaults.baseURL + api.uploadFile + "?fileType=CUSTOMER";
    },
    getHeaders() {
      //请求头部
      return {
        Authorization: this.token || null,
      };
    },
    hideList() {
      return this.limit === 1 && this.isType === "add";
    },
    imageUrl() {
      return this.imageUrlCopy
        ? this.imageUrlCopy
        : this.hideList && this.fileList.length
        ? this.fileList[0].url
        : "";
    },
    getListType() {
      return this.hideList ? "text" : this.listType;
    },
    getFileList() {
      return this.hideList ? [] : this.fileList;
    },
  },
  methods: {
    downLoadFile,
    handlePictureCardPreview(file) {
      console.log(file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    deleteFile(file, fileList) {
      //删除文件的回调
      if (!fileList.length) {
        this.imageUrlCopy = "";
      }
      this.fileList = [...fileList];
      this.$emit("delete");
    },
    onSuccess(res, file, fileList) {
      //上传成功的回调
      uploadFileOnSuccess(res, file, fileList)
        .then((res) => {
          getBase64(file.raw).then((res) => {
            this.imageUrlCopy = res;
          });
          this.fileList = [...fileList];
          this.$emit("update:modelValue", [file.response.data.fileId]);
          var info = JSON.parse(JSON.stringify(file.response.data));
          this.$emit("usuccess", info); //上传成功后的回调
        })
        .catch((res) => {
          this.fileList = [...this.fileList];
        });
    },
  },
  created() {
    this.fileList = this.list;
  },
  watch: {
    fileList(v) {
      var arr = [];
      v.map((item) => {
        if (item.fileId) {
          arr.push(item.fileId);
        } else if (item.response.code === "0") {
          arr.push(item.response.data.fileId);
        }
      });
      this.$emit("update:modelValue", arr);
    },
    list: {
      deep: true,
      handler: function(v) {
        const res = new Map();
        var arr = v.filter(
          (item) => !res.has(item.fileId) && res.set(item.fileId, 1)
        );
        this.fileList = arr;
      },
    },
  },
};
</script>

<style scoped lang="less">
.upload-demo {
  .el-upload-list {
    float: right;
  }
  .icon-yunshangchuan {
    font-size: 50px;
    margin-top: 40px;
    display: inline-block;
    color: #999999;
  }
}
.up-img {
  width: 148px;
  height: 148px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fbfdff;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  overflow: hidden;
  box-sizing: border-box;
  p {
    font-size: 28px;
    color: #999999;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
