<template>
	<div class="menu">
		<div :class="[
			'menu-item',
			item.disable ? 'disable' : '',
			getClass(item)
		]" v-for="(item, index) in getMenus" :key="index" @click.stop="activeMenu(item, item.module, $event)">
			<div class="menu-text">
				<p :class="['iconfont', item.icon]" v-if="item.icon"></p>
				<span>{{ item.name }}</span>
			</div>
			<div :class="['subMenu', isClick ? 'c_subMenu' : '']" v-if="item.children && item.children.length">
				<div class="subMenu-row" v-for="(son, idx) in item.children" :key="index + '-' + idx">
					<!-- <div class="subMenu-col disable">{{ son.name }}</div> -->
					<template v-for="(col, k) in son.children" :key="index + '-' + idx + '-' + k">
						<div :class="['subMenu-col', col.disable ? 'disable' : col.url === getPath ? 'active-subMenu' : '',]"
							v-if="son.children && son.children.length" @click.stop="activeMenu(col, $event)">
							{{ col.name }}
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions,mapMutations, mapState } from "vuex";
import menuData from '@/common/js/menuData.js'
export default {
	data() {
		return {
			menus: menuData,
			isClick: false,
		}
	},
	methods: {
		...mapActions("systemModule", [
			"getUserInfo", //请求用户信息的接口
		]),
		...mapMutations("homeModule", ["setUserInfos"]),
		activeMenu(row, e) { //当前选中的点击事件
			console.log(row.url)
			this.closeSubMenu();
			if (row.url) { //判断有路径就跳转路径
				this.$router.push({
					path: row.url, //页面路径
					query: row.query || {},   //跳转页面所携带的参数
				})
			}
		},
		closeSubMenu() { //关闭右边路由导航=栏
			this.isClick = true;
			setTimeout(() => {
				this.isClick = false;
			}, 0)
		},

		getClass(item) {
			if (this.isPurchase && item.module === 'purchase') return 'active-menu-item'
			if (this.isPurchase && item.module === 'mysale') return ''
			if (item.url === this.getPath) return 'active-menu-item'
			if (JSON.stringify(item).includes(this.$route.fullPath)) return 'active-menu-item'
			return ''
		}

	},
	created() {
		if (!this.userInfos) {
			this.getUserInfo().then((res) => {
				let { code, data } = res.data;
				if (code === "0") {
					this.setUserInfos(data);
				}
			});
		}
	},
	computed: {
		...mapState("homeModule", [
			"userInfos", // 完整用户信息
		]),
		getPath() {
			return this.$route.path;
		},
		isPurchase() {//是否是采购单详情

			return this.$route.fullPath?.includes("type=_purchase");
		},
		// zhd 修改 根据userType动态展示菜单
		// userType === 1 的时候不显示showFlag=true的菜单
		getMenus() {
			console.log(this.userInfos)
			let userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
			let userType = userInfo.userType
			let menuDataList = menuData.filter(e => (this.userInfos && e.distributionShow == this.userInfos.isShow) || !e.distributionShow)
			return userType === '1' ? menuDataList.filter(e => !e.showFlag) : menuDataList
		}
	}
}
</script>

<style scoped lang="less">
.menu {
	width: 100%;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	flex-direction: column;
	font-size: 14px;
	padding-top: 20px;

	.menu-item {
		position: relative;
		height: 45px;
		width: 100%;
		padding: 0 20px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		cursor: pointer;

		.menu-text {
			color: #ffffff;
			display: flex;
			align-items: center;

			p {
				font-size: 14px;
				margin-right: 5px;
			}

			span {
				white-space: nowrap;
			}
		}

		.subMenu {
			position: absolute;
			top: 0;
			left: 100%;
			box-shadow: 1px 0px 4px rgba(204, 204, 204, 0.8);
			z-index: 99999;
			background: #ffffff;
			box-sizing: border-box;
			padding: 20px 0;
			display: none;
			color: #415161;
			display: none;
			align-items: stretch;

			.subMenu-row {
				border-right: 1px solid #eef1f9;

				.subMenu-col {
					white-space: nowrap;
					padding: 8px 30px;
				}

				.subMenu-col:hover {
					color: @dh-color;
				}

				.active-subMenu {
					color: @dh-color;
				}

				.subMenu-col:nth-child(1) {
					padding: 0 30px 12px 30px;
				}

				.subMenu-col:last-of-type {
					padding: 12px 30px 0 30px;
				}
			}

			.subMenu-row:last-of-type {
				border-right: none;
			}
		}
	}

	.menu-item:hover {
		background: @dh-color;
		color: #ffffff;

		.subMenu {
			display: flex;
		}

		.c_subMenu {
			display: none !important;
		}
	}

	.active-menu-item {
		background: @dh-color;
		color: #ffffff;
	}
}
</style>
