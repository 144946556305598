import Vuex from 'vuex'
//console.log(Vuex)
import request from './common/js/request.js';
import api from './common/js/api.js'

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('token') || "",  //登录token
        reqNumber: 0,  //正在请求的次数
        errorNumber: 0, //请求失败的次数
        userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},  //登录返回用户的基本信息
        LDrouters: localStorage.getItem('LDrouters') ? JSON.parse(localStorage.getItem('LDrouters')) :
            [  //立大导航集合
                {meta: {title: '首页'}, path: '/home/index', closable: true, active: true},
                {meta: {title: '采购中心'}, path: '/supplierMessage/index', closable: true, active: true},
                {meta: {title: '销售中心'}, path: '/mysale/orderSetup', closable: true, active: true}
            ],
        position: [], //当前的位置
        bMapKey: 'Nh7xVGfGBlLsTiYF5sddj3iXGDmUmGa4',  //百度地图密匙
    },
    mutations: {
        renewToken(state, data) {
            localStorage.setItem('token', data);//保存token到本地
            state.token = data;
        },
        renewReqNumber(state, data) {
            state.reqNumber = data;   //更新请求次数
        },
        renewUserInfo(state, data) { //更新用户基本信息
            localStorage.setItem('userInfo', JSON.stringify(data))
            state.userInfo = data;
        },
        renewLDrouters(state, data) { //更新导航集合
            localStorage.setItem('LDrouters', JSON.stringify(data))
            state.LDrouters = [...data];
        },
        renewErrorNumber(state, data) { //更新请求失败的次数
            state.errorNumber = data;
        },
        renewPosition(state, data) {
            state.position = [...data];
        }
    },
    actions: {  //模块
        uWechatBind(context, params) {
            return request.get(`${api.uWechatBind}${params}`)
        },
        shareInquiryLocation(context, params) {  //根据经纬度获取当前位置信息
            return request.get(`${api.shareInquiryLocation}`, {params})
        }
    },
    getters: {}
})
