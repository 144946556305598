<template>
	<div class="page">
		<lidaFrom ref="lidaFrom" :span="span" @search="search" :formValues="formValues" :formColumns="formColumns"
			:showSearchBtn="showSearchBtn" :showResetBtn="showResetBtn">
			<template v-slot:formItem>
				<slot name="formItem"></slot>
			</template>
		</lidaFrom>
		<div class="line" v-if="formColumns.length"></div>
		<slot></slot>
		<div class="lida-page-opear" :style="lidaPageOpearStyle">
			<div class="lida-page-opear-left" v-if="subTitle">{{ subTitle }}: <span>{{ pageData.totalCount }}</span>个</div>
			<div v-else></div>
      <div class="lida-page-opear-left" v-if="minTitle">{{ minTitle }}: <span>{{ minTitle }}</span>个</div>
      <div class="lida-page-opear-right" :style="opearRightStyle">
				<el-button @click="tableSearch" size="mini" v-if="showRefreshBtn" :loading="loading">刷新</el-button>
				<el-button @click="setUp" size="mini" v-if="showSetupBtn">设置</el-button>
				<slot name="formButton"></slot>
			</div>
		</div>
		<lida-table :cutHeight="0" :data="tableData" border :total="pageData.totalCount" :page="pageData.pageNo"
			:pageSize="pageData.pageSize" @change="pageChange" :height="height" ref="table" :pagination="pagination" :rowClassName="tableRowClassName">
			<el-table-column type="index" label="序号" width="55" fixed v-if="showOrder" />
			<template v-for="(item, index) in columns" :key="index">
				<lida-table-column v-bind="item" v-if="!item.hide">
					<template #default="scope">
						<div v-if="!!$slots[item.prop]">
							<slot :name="item.prop" :store="scope.store" :row="scope.row" :column="scope.column"
								:$index="scope.$index" :_self="scope._self"></slot>
						</div>
						<lida-table-col :row="scope.row" :prop="item.prop" :popover-width="popoverWidth" v-else />
					</template>
				</lida-table-column>
			</template>
			<slot name="operation"></slot>
		</lida-table>
	</div>
	<setUpDialog v-model="show" @change="setUpChange" :data="columns" />
</template>

<script>
import lidaFrom from './lidaForm.vue';
import request from '@/common/js/request.js';
import lidaTableCol from "./table/lida-table-col.vue";
import setUpDialog from './table/dialog.vue'
export default {
	data() {
		return {
			pageData: {  //页面配置
				totalCount: 0,
				pageNo: 1,
				pageSize: 20
			},
			tableData: [], //页面数据
			loading: false,
			show: false,
		}
	},
	components: {
		lidaFrom,
		lidaTableCol,
		setUpDialog,
	},
	props: {
		formColumns: { type: Array, default: () => [] }, //表单配置数据
		span: { type: Number, default: 5 }, //表单每行显示的个数
		columns: { type: Array, default: () => [] }, //表单数据配置
		query: { type: [Function, String], default: null }, //查询接口 或者方法
		tableRowClassName:{ type: Function, default: null },
		height: [Number, String],  //表单高度
		method: { type: String, default: 'post' }, //请求方式
		subTitle: String,  //小标题
    minTitle: String, //标题
		showSearchBtn: { type: Boolean, default: true }, //是否显示搜索按钮
		showResetBtn: { type: Boolean, default: true }, //是否显示重置按钮
		showRefreshBtn: { type: Boolean, default: true }, //是否显示刷新按钮
		showSetupBtn: { type: Boolean, default: true }, //是否显示设置按钮
		pagination: { type: Boolean, default: true }, //是否显示分页
		handleData: { type: Function, default: null },//处理数据的方法
		opearRightStyle: { type: Object, default: null }, //右边操作样式
		data: { type: Array, default: () => [] }, //页面默认数据
		showOrder: { type: Boolean, default: true }, //是否显示序号
		queryInfo: { type: Object, default: () => { } },  //其他一些筛选字段
	},
	computed: {
		formValues() {
			var obj = {};
			for (var item of this.formColumns) {
				obj[item.prop] = (this.formValues && this.formValues[item.prop]) || null;
			}
			return obj;
		},
		postQuery(data) { //请求数据的方法
			data = this.method === 'get' ? {
				params: data || {}
			} : (data || {});
			return typeof this.query === 'string' ? request[this.method](query, data) :
				typeof this.query === 'function' ? this.query : null;
		},
		lidaPageOpearStyle() {
			return {
				'padding': !this.$slots.formButton && !this.showRefreshBtn && !this.showSetupBtn && !this.subTitle ? '0px' : '10px 0',
			}
		}
	},
	methods: {
		pageChange(page, pageSize) {
			this.pageData.pageNo = page;
			this.pageData.pageSize = pageSize;
			this.getData();
		},
		getData() {
			if (this.postQuery) {
				this.loading = true;
				this.postQuery({
					...this.pageData,
					...this.formValues,
					...this.queryInfo,
				}).then(res => {
					let {
						code,
						data
					} = res.data;
					if (code === '0') {
						let rows;
						if (this.pagination) {
							rows = [...data.rows];
							this.pageData.totalCount = data.totalCount;
						} else {
							rows = data;
						}
						let tableData = this.handleData ? this.handleData(rows) : rows; //处理数据的方法
						if(this.$route.path==='/purchase/myPurchaseUndo'){
							this.tableData=tableData.map(item=>{
								return{
									...item,...{
												sellerName:item.undoApplyUserId===item.sellerId?item.sellerName:item.buyerName,
												buyerName:item.undoAuditUserId===item.buyerId?item.buyerName:item.sellerName
									}
								}
							})
						}else{
							this.tableData=tableData
						}
					}
				}).finally(() => {
					setTimeout(() => {
						this.loading = false;
					}, 3e2)
				})
			}
		},
		tableSearch(values) {
			if (values) {
				this.formValues = values;
			}
			this.getData();
		},
		setUp() { //设置的点击事件
			this.show = true;
		},
		setUpChange(v) {
			this.columns.map(item => {
				item.hide = !v.some(a => a === item.prop);
			})
		},
		refresh() {
			this.tableSearch();
		},
		search(values) {
			this.pageData.pageNo = 1;
			this.tableSearch(values);
		}
	},
	created() {
		this.tableData = this.data;
		this.getData();
	},
	watch: {
		query() {
			this.pageData.pageNo = 1;
			this.pageData.totalCount = 0;
			this.$refs.lidaFrom.reset();
		},
		data(v) {
			this.tableData = v;
		}
	}
}
</script>

<style lang="less">
.line {
	height: 10px;
	background: #F2F5FA;
	width: calc(100% + 20px);
	position: relative;
	left: -10px;
}

.page {
	.lida-page-opear {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 10px;

		.lida-page-opear-left {
			display: flex;

			span {
				color: #D84146;
			}
		}
	}

	.el-table__row {
		td {
			padding: 0;
		}
	}
}
</style>
