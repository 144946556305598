import homePage from '@/components/homePage/index.vue';

export default {
    path: '/repertory',
    component: homePage,
    redirect: '/repertory/quotedPriceMsg',
    meta: {title: "我的库存"},
    children: [
        {
            path: "quotedPrice",
            component: () => import('@/module/repertoryModule/viewPage/quotedPriceMsg.vue'),
            meta: {title: "报价仓库信息"},
        },
        {
            path: "temporary",
            component: () => import('@/module/repertoryModule/viewPage/temporaryMsg.vue'),
            meta: {title: "临时仓库信息"},
        },
        {
            path: "bePut",
            component: () => import('@/module/repertoryModule/viewPage/bePutMsg.vue'),
            meta: {title: "入库信息"},
        },
        {
            path: "stockRemoval",
            component: () => import('@/module/repertoryModule/viewPage/stockRemovalMsg.vue'),
            meta: {title: "出库信息"},
        },
    ]
}
