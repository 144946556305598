import request from '@/common/js/request.js';
import api from '@/common/js/api.js';
export default {
	namespaced: true, //区分模块
	state: {
		userInfos: null,
		chartsBarOption: {
			backgroundColor: 'transparent',
			tooltip: {
			  	trigger: 'axis'
			},
			grid: {
				top: '40px',
				left: '40',
				right: '40',
				bottom: '15%'
				// containLabel: true
			},
			xAxis: {
				type: 'category',
				axisLine: {
					show: true,
					lineStyle: {
					color: '#d1d5df'
					}
				},
				splitArea: {
					color: '#f00',
					lineStyle: {
					color: '#f00'
					}
				},
				axisLabel: {
					color: '#fff'
				},
				data: []
			},
			yAxis: {
			//   name: '单位(次)',
			  type: 'value',
			  splitLine: {
				show: true,
				lineStyle: {
				  color: 'rgba(115,201,220,0.2)'
				}
			  },
			  axisLine: {
				lineStyle: {
				  color: '#d1d5df'
				}
			  },
			  axisLabel: {
				color: '#d1d5df'
			  }
			},
			series: [{
			  name: '客户统计',
			  type: 'bar',
			  barWidth: '15%',
			  itemStyle: {
				normal: {
				  color: {
					type: 'linear',
					x: 0,
					y: 0,
					x2: 0,
					y2: 1,
					colorStops: [{
					  offset: 0,
					  color: 'rgba(86, 79, 252, 1)' // 0% 处的颜色 #0CEBEA rgba(86, 79, 252, 1)
					}, {
					  offset: 1,
					  color: '#5470C6' // 100% 处的颜色  #5470C6
					}],
					globalCoord: false // 缺省为 false
				  },
				  barBorderRadius: 2
				}
			  },
			  data: [],
			  animationEasing: 'elasticOut', // quadraticInOut
			  animationDurationUpdate: 3000,
			  animationEasingUpdate: 'bounceOut' //  bounceOut
			}]
		},
		chartsPieOption: {
			backgroundColor: 'transparent',
			color: ['#10b9f8', '#ff6a6a', '#90ed7d', '#f7a35c', '#ff00e4',
			  '#8085e9', '#e4d354', '#c373ee', '#ff2929', '#dabaa2', '#a5ffed'],
			title: {
			  text: '{b|业务' + '}\n{b|类型}',
			  y: 'center',
			  left: '39.2%',
			  textStyle: {
				textAlign: 'center',
				width: 80,
				rich: {
				  b: {
					fontSize: 14,
					color: '#ffffff',
					padding: [3, 0]
					// lineHeight: 18
				  }
				}
			  }
			},
			legend: {
			  type: 'scroll',
			  orient: 'vertical',
			  right: 10,
			  top: 20,
			  bottom: 20,
			  data: ['茶', '猕猴桃', '桂圆', '柠檬', '葡萄', '香橙'],
			  textStyle: {
				color: '#eee'
			  },
			  pageIconColor: '#1072a4',
			  pageIconInactiveColor: '#88bcd8',
			  pageTextStyle: {
				color: '#88bcd8'
			  }
			},
			tooltip: {
			},
			series: [{
			  name: '业务类型',
			  type: 'pie',
			  roseType: 'radius', // area  radius
			  radius: ['25%', '60%'],
			  center: ['43%', '50%'],
			  data: [
				{ value: 635, name: '茶' },
				{ value: 310, name: '猕猴桃' },
				{ value: 254, name: '桂圆' },
				{ value: 526, name: '柠檬' },
				{ value: 432, name: '葡萄' },
				{ value: 380, name: '香橙' }
			  ],
			  label: {
				show: true,
				position: 'outside',
				fontSize: 9,
				formatter: '{a|{b}: {c}}\n{hr|}\n{b|{d}%}',
				rich: {
				  hr: {
					backgroundColor: 'transparent',
					borderRadius: 100,
					width: 0,
					height: 6,
					padding: [5, 3, -5, -8],
					// padding: [-5, 3, -5, -8],
					shadowColor: '#1c1b3a',
					shadowBlur: 1,
					shadowOffsetX: '0',
					shadowOffsetY: '2'
				  },
				  a: {
					fontSize: 9,
					padding: [1, 3]
					// padding: [-30, 8, -20, 5],
				  },
				  b: {
					fontSize: 10,
					padding: [1, 3]
				  }
				}
			  },
			  labelLine: {
				normal: {
				  length: 5,
				  length2: 10,
				  lineStyle: {
					width: 1
				  }
				}
			  },
			  selectedMode: 'single',
			  animationType: 'scale',
			  animationEasing: 'elasticOut',
			  animationDelay: function(idx) {
				return Math.random() * 200
			  },
			  animationDurationUpdate: 3000,
			  animationEasingUpdate: 'bounceOut', //  bounceOut
			  animationDelayUpdate: function(idx) {
				return Math.random() * 300
			  }
			}]
		  }
	},
	getters: {
		
	},
	mutations: {
		setUserInfos(state,data){
			state.userInfos = data;
		}
	},
	actions: {
		userHomeBacklog(context, params) { // 待办事项
			return request.get(api.userHomeBacklog, { params })
		},
		searchUserInfo(context, params) { // 查询登陆人的信息
			return request.get(api.searchUserInfo, { params })
		},
		userHomeCustomerAnalyse(context, params) { // 客户分析
			return request.post(api.userHomeCustomerAnalyse, params)
		},
		userHomeCustomerStatistics(context, params) { // 客户统计
			return request.get(api.userHomeCustomerStatistics, { params })
		},
		userHomeInfoStatistics(context, params) { // 概况
			return request.get(api.userHomeInfoStatistics, { params })
		},
		userHomeOperatingTrend(context, params) { // 运营趋势
			return request.post(api.userHomeOperatingTrend, params)
		},
		userHomeSaleInfo(context, params) { // 销售信息
			return request.post(api.userHomeSaleInfo, params)
		},
		userHomeSupplyInfo(context, params) { // 供应商信息
			return request.post(api.userHomeSupplyInfo, params)
		},
	}
}
