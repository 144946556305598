<template>
	<div class="login">
		<router-view />
		<div class="bei">www.panlei.cn</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{

			}
		},
	}

</script>

<style scoped lang="less">
	.login{
		background:radial-gradient(circle, #2b237c 0%, #251c72 31%, #0f054c 100%);
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		// justify-content: center;
		position: relative;
		.bei{
			position: absolute;
			bottom: 10px;
			font-size: 12px;
			color: #999999;
		}
	}
</style>
