import homePage from '@/components/homePage/index.vue';
export default {
    path: "/client",
	component:homePage,
	redirect:"/client/index",
	meta:{title:"我的客户"},
	children:[
		{
			path: "index",
			component:() => import('@/module/clientModule/viewPage/index.vue'),
			meta:{title:"我的客户"},
		},{
			path:'enterpriseDetails',
			component:() => import('@/module/clientModule/viewPage/enterpriseDetails.vue'),
			meta:{title:'企业详情'},
		}
	]
}