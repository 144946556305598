import axios from 'axios'
import store from '@/store.js'
import router from '@/router'
import {
	ElMessage
} from 'element-plus'

const requestConfig = {
	baseURL: '', //代理路径
	timeout: 6 * 60 * 1000,
}
if (process.env.NODE_ENV === 'development') { //本地环境
	requestConfig.baseURL = '/api'
}else if (process.env.NODE_ENV === 'test') { //本地环境
	requestConfig.baseURL = 'https://test.lidacn.net/mall/lidamall'
}else if (process.env.NODE_ENV === 'production') { //正式环境
	requestConfig.baseURL = 'https://www.panlei.cn/lidamall/'
}

const request = axios.create(requestConfig)

//请求拦截
request.interceptors.request.use(
	config => {
		config.headers['Authorization'] = store.state.token; //设置
		var data = config.data || {},
		params = config.params || {};
		if(!data.hideLoading && !params.hideLoading){
			var num = store.state.reqNumber;
			num++;
			store.commit('renewReqNumber',num);
		}
		return config;
	},
	error => {
		return Promise.reject(error)
	}
)

//响应拦截
request.interceptors.response.use(
	res => {
		var data = res.config.data || {},
		params = res.config.params || {};
		if(!data.hideLoading && !params.hideLoading){
			setTimeout(() => { //1秒内不重复出现加载圈
				var num = store.state.reqNumber;
				num = num ? num - 1 : 0;
				store.commit('renewReqNumber',num);
			},0)
		}

		if (res.status === 200) {
			if(
				!(res.data instanceof ArrayBuffer) && (
					res.data.code !== '0' ||
					(res.data.code === '0' && res.data.data && res.data.data.code && res.data.data.code !== '0')
				)
			){
				var msg = res.data?.msg || res.data?.data?.msg;
				var code = res.data.data && res.data?.data?.code ? res.data?.data.code : res.data?.code;
				var errorNumber = store.state.errorNumber;
				errorNumber = errorNumber ? errorNumber : 0;
				if(errorNumber === 0){ //防止重复提示错误
					errorNumber++;
					store.commit('renewErrorNumber',errorNumber);
					ElMessage({
						showClose: true,
						message: msg,
						type: 'error',
						onClose:function(){
							var errorNumber = store.state.errorNumber;
							errorNumber = errorNumber ? errorNumber : 0;
							errorNumber--;
							store.commit('renewErrorNumber',errorNumber);
						}
					})
				}
				if(
					code === 'NO_AUTHC' ||
					code === 'TOKEN_EXPIRE'
				){  //判断跳转到首页
					console.log(1);
					router.push('/');
				}
			}else{
				return Promise.resolve(res);
			}
		}else{
			return Promise.reject(res);
		}
	},
	error => {
		return Promise.reject(error)
	}
)

export default request;
