// zhd 每个对象增加showFlag为 true 的在userType === 1 的时候隐藏
const menuData = [
	// {
	// 	name: '采购中心',
	// 	icon: 'icon-xunjiaguanli',
	// 	url: '/supplierMessage/index',
	// 	module:'supplierMessage',
	// },
	// {
	// 	name: '销售中心',
	// 	icon: 'icon-ABSbaojia',
	// 	url: '/saleMessage/index',
	// 	module:'saleMessage',
	// },
	{
		name: '品牌产品',
		icon: 'icon-xunjia',
		module: 'purchaseIndex',
		children:[
			
			{
				name: '选型',
				children: [{
					name: '产品选型',
					url: '/purchase/product',
				},{
					name: '专业选型',
					url: '/purchase/index',
				},
				{
					name: '新增询价单',
					url: '/purchase/addInquiryPage',
				}]
			},
		]
	},
	{
		name: '我要采购',
		icon: 'icon-xunjiaguanli',
		module: 'purchase',
		children: [ {
				name: "我要采购",
				children: [{
					name: '我的询价',
					url: '/purchase/enquiry'
				}, {
					name: '供应商报价',
					url: '/purchase/supplierQuotation'
				},{
					name: '我的采购',
					url: '/purchase/mypurchase'
				},
				{
					name: '采购作废审核',
					url: '/purchase/myPurchaseUndo'
				}
				]
			}]
	}, {
		name: '我要销售',
		icon: 'icon-ABSbaojia',
		module: 'mysale',
		showFlag: true,
		children: [{
			name: '我要销售',
			children: [{
				name: '销售中心',
				url: '/mysale/orderSetup'
			}, {
				name: '客户询价',
				url: '/mysale/customerInquiry',
			}, {
				name: '我的报价',
				url: '/mysale/customerOffer',
			}, {
				name: '我的订单',
				url: '/mysale/order'
			},
			{
				name: '订单作废审核',
				url: '/mysale/orderUndo'
			},
			{
				name: '资金列表',
				url: '/mysale/capitalList'
			}, {
				name: '发票申请',
				url: '/mysale/invoice'
			}]
		}]
	},
	{
		name: '我的客户',
		icon: 'icon-kehu',
		url: '/client/index',
		module: 'client',
		showFlag: true
	},
	{
		name: '我的供应商',
		icon: 'icon-xiaoshoudingdan_icox',
		url: '/supplier/index',
		module: 'supplier',
	},
	{
		name: '我的库存',
		icon: 'icon-kucun',
		url: '/stock/index',
		module: 'stock',
		showFlag: true,
		children: [
			{
				name: '我的库存',
				children: [
					{
						name: '库存信息',
						url: '/stock/index',
					}, {
						name: '入库明细',
						url: '/stock/enterStock',
					}, {
						name: '出库明细',
						url: '/stock/outStock',
					}
				]
			}
		]
	},
	//{
	// 	name: '我的库存',
	// 	icon: 'icon-kehupandian',
	// 	module: 'repertory',
	// 	children: [{
	// 		name: '客户',
	// 		children: [{
	// 			name:'报价仓库信息',
	// 			url: '/repertory/quotedPrice'
	// 		},{
	// 			name:'临时仓库信息',
	// 			url: '/repertory/temporary'
	// 		},{
	// 			name:'出库明细',
	// 			url: '/repertory/stockRemoval'
	// 		},{
	// 			name:'入库明细',
	// 			url: '/repertory/bePut'
	// 		}]
	// 	}]
	// },
	{
		name: '我的信息',
		icon: 'icon-wodechanpin',
		module: 'myMessage',
		url: '/myMessage',
	},
	{
		name: '分销销售',
		icon: 'icon-kehupandian',
		module: 'distribution',
		distributionShow : 2,
		children:[
			{
				name: '分销销售',
				children: [{
					name: '分销客户询价',
					url: '/distribution/customerInquiry',
				},{
					name: '分销报价',
					url: '/distribution/customerOffer',
				},
				{
					name: '分销订单',
					url: '/distribution/order',
				},
				{

					name: '分销客户',
					url: '/distribution/clientIndex',
				}
				]
			},
		]
	},
	{
		name: '分销管理',
		icon: 'icon-xunjia',
		module: 'myDistribution',
		distributionShow : 1,
		children:[
			{
				name: '分销管理',
				children: [{
					name: '分销客户询价',
					url: '/myDistribution/customerInquiry',
				},{
					name: '分销报价',
					url: '/myDistribution/customerOffer',
				},
				{
					name: '分销订单',
					url: '/myDistribution/order',
				},
				{

					name: '分销客户',
					url: '/myDistribution/clientIndex',
				},
				{

					name: '分销采购',
					url: '/myDistribution/myPurchase',
				}
				
			]
			},
		]
	},
	{ 
		name: '系统设置',
		icon: 'icon-shezhi',
		module: 'system',
		url: '/system',
		children: [
			{
				name: '系统设置',
				children: [
					{
						name: '基础设置',
						url: '/system/index'
					}, {
						name: '账号密码设置',
						url: '/system/accountNumber'
					}
				]
			}
		]
	}
];

export default menuData;
