import homePage from '@/components/homePage/index.vue';
export default {
	path: "/purchase",
	component:homePage,
	redirect:"/purchase/index",
	meta:{title:"我要采购"},
	children:[
		{
			path: "index",
			component:() => import('@/module/purchaseMudule/viewPage/index.vue'),
			meta:{title:"专业选型"},
		},
		{
			path: "product",
			component:() => import('@/module/purchaseMudule/viewPage/productModelSelection.vue'),
			meta:{title:"产品选型"},
		},
		{
			path: "enquiry",
			component:() => import('@/module/purchaseMudule/viewPage/myEnquiry.vue'),
			meta:{title:"我的询价"},
		},
		{
			path: "supplierQuotation",
			component:() => import('@/module/purchaseMudule/viewPage/supplierQuotation.vue'),
			meta:{title:"供应商报价"},
		},
		{
			path: "mypurchase",
			component:() => import('@/module/purchaseMudule/viewPage/myPurchase.vue'),
			meta:{title:"我的采购"},
		},
		{
			path: "myPurchaseUndo",
			component:() => import('@/module/purchaseMudule/viewPage/myPurchaseUndo.vue'),
			meta:{title:"采购作废审核"},
		},
		{
			path:'enquiryDetails',
			component:() => import('@/module/purchaseMudule/viewPage/enquiryDetails.vue'),
			meta:{title:"询价详情"},
		},{
			path:'addInquiryPage',  //添加询价单
			component:() => import('@/module/purchaseMudule/viewPage/addInquiryPage.vue'),
			meta:{title:"添加询价单"},
		},{
			path:'purchaseDetails',
			component:() => import('@/module/purchaseMudule/viewPage/purchaseDetails.vue'),
			meta:{title:"采购详情"},
		}
	]
}