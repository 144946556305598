import homePage from '@/components/homePage/index.vue';
export default {
	path: "/myMessage",
	component:homePage,
	redirect:"/myMessage/index",
	children:[
		{
			path: "index",
			component:() => import('@/module/myMessageModule/viewPage/index.vue'),
			meta:{title:"我的信息"},
		}
	]
}
