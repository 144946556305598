import homePage from '@/components/homePage/index.vue';
export default {
	path: "/mysale",
	component: homePage,
	redirect: "/mysale/orderSetup",
	meta: { title: "我要销售" },
	children: [
		{
			path: "orderSetup",
			component: () => import('@/module/mysaleMudule/viewPage/orderSetup.vue'),
			meta: { title: "销售中心" },
		},
		{
			path: "customerInquiry",
			component: () => import('@/module/mysaleMudule/viewPage/customerInquiry.vue'),
			meta: { title: "客户询价" },
		}, {
			path: "customerOffer",
			component: () => import('@/module/mysaleMudule/viewPage/customerOffer.vue'),
			meta: { title: "我的报价" },
		}, {
			path: "customerOfferDetails",
			component: () => import('@/module/mysaleMudule/viewPage/customerOfferDetails.vue'),
			meta: { title: "客户报价详情" },
		}, {
			path: "order",
			component: () => import('@/module/mysaleMudule/viewPage/customerOrder.vue'),
			meta: { title: "我的订单" },
		}, 
		{
			path: "orderUndo",
			component: () => import('@/module/mysaleMudule/viewPage/orderUndo.vue'),
			meta: { title: "订单作废审核" },
		}, 
		{
			path: "enquiryDetails",
			component: () => import('@/module/mysaleMudule/viewPage/enquiryDetails.vue'),
			meta: { title: "询价详情" },
		}, {
			path: 'orderDetails',
			component: () => import('@/module/mysaleMudule/viewPage/orderDetails.vue'),
			meta: { title: "订单详情" },
		}, {
			path: 'capitalList',
			component: () => import('@/module/mysaleMudule/viewPage/capitalList.vue'),
			meta: { title: "资金列表" }
		}, {
			path: 'xinOrderDetails',
			component: () => import('@/module/mysaleMudule/viewPage/xinOrderDetails.vue'),
			meta: { title: "订单详情" },
		}, {
			name: "addOfferOrder",
			path: 'addOfferOrder',
			component: () => import('@/module/mysaleMudule/viewPage/addOfferOrder.vue'),
			meta: { title: "新增报价" },
		}, {
			path: 'invoice',
			component: () => import('@/module/mysaleMudule/viewPage/invoice.vue'),
			meta: { title: "发票申请" },
		}
	]
}