import request from '@/common/js/request.js';
import api from '@/common/js/api.js';
export default {
	namespaced: true, //区分模块
	state: {
		
	},
	getters: {
		
	},
	mutations: {
		
	},
	actions: {
		
	}
}
