<template>
	<div class="lida">
		<router-view />
		<div style="z-index: 999999" class="loadingBox" v-if="loading">
			<div v-loading="loading" element-loading-text="拼命加载中" class="loadingc"/>
		</div>
	</div>
</template>

<script>
	import { mapMutations, mapState, mapActions } from 'vuex'
	import area from '@/common/json/area.json';
	import request from '@/common/js/request.js'
	export default {
		name: 'App',
		data() {
			return {
				//loading: false,
			}
		},
		computed:{
			...mapState([
				'bMapKey',
			]),
			loading(){
				return !!this.$store.state.reqNumber  && this.$route.path !== '/purchase/index'   
			},
		},
		methods:{
			...mapMutations(
				[
					'renewPosition'  //更新当前位置信息
				]),
			...mapActions([
				'shareInquiryLocation'
			])
		},
		created(){
			let t = this;
			var geolocation = new BMap.Geolocation();  //获取当前位置
			geolocation.getCurrentPosition(function (position) {
				if(position.latitude && position.longitude){
					t.shareInquiryLocation({
						ak:t.bMapKey,
						lng:position.longitude,
						lat:position.latitude,
					}).then(res => {
						let {code,data} = res.data;
						if(code === '0'){
							if(data){
								var arr = [
									data.province,
									data.city,
									data.district
								].filter(v => v);
								if(arr.length){
									var a1 = area.filter(v => v.name === arr[0])[0];
									if(arr.length < 2){  //判断如果没获取到市默认选择第一个
										arr.push(a1.children[0].name);
									}
									var a2 = a1.children.filter(v => v.name === arr[1])[0];
									if(arr.length < 3){  //判断如果没有获取到区默认选择第一个
										arr.push(a2.children[0].name);
									}
									//保存本地位置
									t.renewPosition(arr);
								}
							}
						}
					})
				}
			})
		}
	}
</script>

<style scoped lang="less">
	.loadingBox {
		padding: 0;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 10000;
		box-sizing: border-box;
		.loadingc {
			height: 100%;
			width: 100%;
			.el-loading-spinner {
				top: 32% !important;
			}
			/deep/ .el-loading-mask{
				background-color: rgba(255, 255, 255, 0.5) !important;
			}
		}
	}
</style>
