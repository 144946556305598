import { createApp } from "vue";
import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import treeSelect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import App from "./App.vue";
import router from "@/router";
import "@/common/css/reset.css";
import "@/common/css/tableCommon.less";
import "@/common/css/index.css";
import "@/common/css/element-puls.less"; //element-plus的公共css修改
import Module from "@/module"; //处理各个模块
import store from "./store.js";
import lidaTable from "@/components/publicComponent/table"; //全局的table组件
import lidaSelect from "@/components/publicComponent/lidaSelect";
import lidaPage from "@/components/publicComponent/lidaPage";
import chooseEID from "@/components/publicComponent/chooseEID";
// import mitt from "mitt"

//Vue.config.silent = true;  //关闭警告

const app = Vue.createApp(App);

// app.config.globalProperties.$bus = new mitt();

//全局注册treeSelect组件
app.component("treeSelect", treeSelect);
app.component("lidaPage", lidaPage);
app.component("chooseEID", chooseEID);
app
  .use(ElementPlus, {
    locale: zhCn,
  })
  .use(store)
  .use(router)
  .use(Module, {
    router,
    store,
  })
  .use(lidaTable)
  .use(lidaSelect)
  .mount("#app");
