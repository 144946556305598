import request from '@/common/js/request.js';
import api from '@/common/js/api.js';
export default {
	namespaced: true, //区分模块
	state: {
		companyType:[ //公司类型
			{
				label:'分销商（PL）',
				children:[
					{label:'分销商',},
					{label:'代理商'}
				]
			},{
				label:'盘柜厂（PB）',
				children:[
					{label:'房地产'},
					{label:'水电厂'}
				]
			},{
				label:'机械设备制造商（OEM）',
				children:[
					{label:'起重机械'},
					{label:'印刷机械'},
					{label:'装订机械'},
					{label:'陶瓷机械'},
					{label:'烟草机械'},
					{label:'风电设备'}
				]
			},{
				label:'自动化系统集成商（SI）',
				children:[
					{label:'iReflex ic60系列小型断路器'}
				]
			}
		],
		userTypes:[ //用户类型
			{title:'供应商',type:'0'},
			{title:'客户',type:'1'},
		],
		identifyTypeOptions:[
			{title:'非P0',type:'0'},
			{title:'P0',type:'1'},
		],
		purchaseQuantity:[ //采购量
			{title:'1-99万',type:'1'},
			{title:'100-499万',type:'2'},
			{title:'500-999万',type:'3'},
			{title:'1000-1999万',type:'4'},
			{title:'2000万-1亿',type:'5'},
			{title:'1亿-2亿',type:'6'},
			{title:'2亿-5亿',type:'7'},
			{title:'5亿-10亿',type:'8'},
			{title:'10亿-50亿',type:'9'},
			{title:'50亿-100亿',type:'10'},
			{title:'100亿以上',type:'11'},
		],
		annualTurnover:[  //年营业额
			{title:'1-99万',type:'1'},
			{title:'100-499万',type:'2'},
			{title:'500-999万',type:'3'},
			{title:'1000-1999万',type:'4'},
			{title:'2000万-1亿',type:'5'},
			{title:'1亿-2亿',type:'6'},
			{title:'2亿-5亿',type:'7'},
			{title:'5亿-10亿',type:'8'},
			{title:'10亿-50亿',type:'9'},
			{title:'50亿-100亿',type:'10'},
			{title:'100亿以上',type:'11'},
		],
		isWechatFriend:[ //是否加企业微信
			{title:'是',type:'1'},
			{title:'否',type:'0'},
		],
		staffNum:[ //员工人数
			{title:'1-4人',type:'1'},
			{title:'5-9人',type:'2'},
			{title:'10-19人',type:'3'},
			{title:'20-49人',type:'4'},
			{title:'50-99人',type:'5'},
			{title:'100-199人',type:'6'},
			{title:'200-499人',type:'7'},
			{title:'500-999人',type:'8'},
			{title:'1000-1999人',type:'9'},
			{title:'2000人以上',type:'10'},
		],
		classifiedId:[ //员工等级编号
			{
				id:'销售',
				label:'销售',
				children:[
					{id:'X13',label:'X13-顶尖销售总监'},
					{id:'X12',label:'X12-资深销售总监'},
					{id:'X11',label:'X11-高级销售总监'},
					{id:'X10',label:'X10-销售总监'},
					{id:'X9',label:'X9-资深销售经理'},
					{id:'X8',label:'X8-高级销售经理'},
					{id:'X7',label:'X7-销售经理'},
					{id:'X6',label:'X6-资深销售主管'},
					{id:'X5',label:'X5-高级销售主管'},
					{id:'X4',label:'X4-销售主管'},
					{id:'X3',label:'X3-实习销售主管'},
					{id:'X2',label:'X2-销售工程师'},
					{id:'X1',label:'X1-实习销售'},
					{id:'X0',label:'X0-销售(非体制)'},
				]
			},{
				id:'商务',
				label:'商务',
				children:[
					{id:'S10',label:'S10-商务总监'},
					{id:'S9',label:'S9-资深商务经理'},
					{id:'S8',label:'S8-高级商务经理'},
					{id:'S7',label:'S7-商务经理'},
					{id:'S6',label:'S6-资深商务主管'},
					{id:'S5',label:'S5-高级商务主管'},
					{id:'S4',label:'S4-商务主管'},
					{id:'S3',label:'S3-实习商务主管'},
					{id:'S2',label:'S2-商务'},
					{id:'S1',label:'S1-实习商务'},
					{id:'S0',label:'S0-商务(非体制)'}
				]
			},{
				id:'采购',
				label:'采购',
				children:[
					{id:'C10',label:'C10-采购总监'},
					{id:'C9',label:'C9-资深采购经理'},
					{id:'C8',label:'C8-高级采购经理'},
					{id:'C7',label:'C7-采购经理'},
					{id:'C6',label:'C6-资深采购主管'},
					{id:'C5',label:'C5-高级采购主管'},
					{id:'C4',label:'C4-采购主管'},
					{id:'C3',label:'C3-实习采购主管'},
					{id:'C2',label:'C2-采购'},
					{id:'C1',label:'C1-实习采购'},
					{id:'C0',label:'C0-采购(非体制)'},
				]
			},{
				id:'财务',
				label:'财务',
				children:[
					{id:'F10',label:'F10-财务总监'},
					{id:'F9',label:'F9-资深财务经理'},
					{id:'F8',label:'F8-高级财务经理'},
					{id:'F7',label:'F7-财务经理'},
					{id:'F6',label:'F6-资深财务主管'},
					{id:'F5',label:'F5-高级财务主管'},
					{id:'F4',label:'F4-财务主管'},
					{id:'F3',label:'F3-实习财务主管'},
					{id:'F2',label:'F2-会计'},
					{id:'F1',label:'F1-实习会计'},
					{id:'F0',label:'F0-会计(非体制)'},
				]
			},{
				id:'管理',
				label:'管理',
				children:[
					{id:'L10',label:'L10-总监'},
					{id:'L9',label:'L9-资深经理'},
					{id:'L8',label:'L8-高级经理'},
					{id:'L7',label:'L7-经理'},
					{id:'L6',label:'L6-资深主管'},
					{id:'L5',label:'L5-高级主管'},
					{id:'L4',label:'L4-主管'},
					{id:'L3',label:'L3-实习主管'},
					{id:'L2',label:'L2-正式'},
					{id:'L1',label:'L1-行政'},
					{id:'L0',label:'L0-试用(非体制)'},
				]
			},{
				id:'合伙人',
				label:'合伙人',
				children:[
					{id:'T10',label:'T10-Ⅹ创始人'},
					{id:'T9',label:'T9-Ⅸ级创始合伙人'},
					{id:'T8',label:'T8-Ⅷ级合伙人'},
					{id:'T7',label:'T7-Ⅶ区域经理'},
					{id:'T6',label:'T6-Ⅵ级分公司经理'},
					{id:'T5',label:'T5-Ⅴ级部门经理'},
					{id:'T4',label:'T4-Ⅳ级经理'},
					{id:'T3',label:'T3-Ⅲ级主管'},
					{id:'T2',label:'T2-Ⅱ级正式'},
					{id:'T1',label:'T1-Ⅰ级试用合伙'},
					{id:'T0',label:'T0-Ⅰ级试用(非体制)'},
				]
			}
		]
	},
	getters: {

	},
	mutations: {

	},
	actions: {
		addUserConsignee(context,params){ //新增收货地址
			return request.post(api.userConsignee,params)
		},
		editUserConsignee(context,params){ //修改地址
			return request.put(api.userConsignee,params)
		},
		deleteUserConsignee(context,id){ //删除地址
			return request.delete(`${api.userConsignee}/${id}`)
		},
		getUserConsignee(context,id){ //地址详情
			return request.get(`${api.userConsignee}/${id}`)
		},
		userConsigneePage(context,params){ //获取地址列表
			return request.post(api.userConsigneePage,params)
		},
		userConsigneeList(context,params){ //获取收货地址列表（不带分页）
			return request.post(api.userConsigneeList,params)
		},
		/** wyb 这个接口修改了 不需要传id */
		getUser(context){ //获取个人信息详情
			return request.get(api.getUser)
		},
		editEnterprise(context,params){ //修改企业
			return request.put(api.addEnterprise,params)
		},
		listSkuCategoryForFirst(context,params){ //获取品牌列表
			return request.get(api.listSkuCategoryForFirst,params)
		},
		userCreateEmption(context,params){ //我的买卖新增
			return request.post(api.userCreateEmption,params)
		},
		userPageEmption(context,params){ //获取我的买卖
			return request.post(api.userPageEmption,params)
		},
		userDelEmption(context,id){ //删除我的买卖商品品牌
			return request.delete(`${api.userDelEmption}?id=${id}`)
		},
		userGetUserInvoice(context,params){ //开票信息详情
			return request.get(api.userGetUserInvoice,{params})
		},
		userInvoiceEdit(context,params){ //开票信息修改
			return request.post(api.userInvoiceEdit,params)
		},
		enterpriseTreeList(context,params){ //获取企业类型列表
			return request.post(api.enterpriseTreeList,params)
		},
		getEnterprise(context,id){ //获取企业详情
			return request.get(`${api.addEnterprise}/${id}`)
		},
		userEditUser(context,params){ //修改用户特别信息
			return request.post(api.userEditUser,params)
		},
		platformUserListByEnterpriseId(context,id){ //查询企业下的员工
			return request.post(`${api.platformUserListByEnterpriseId}/${id}`)
		},
		updateCustomerIdForEnterprise(context,params){ //企业-主账号转移(所属人)
			return request.put(api.updateCustomerIdForEnterprise,params)
		},
	}
}
