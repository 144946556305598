import homePage from '@/components/homePage/index.vue';
export default {
    path: '/supplier',
    component: homePage,
    redirect: '/supplier/index',
	meta:{title:"我的供应商"},
    children: [
        {
			path: "index",
			component:() => import('@/module/supplierModule/viewPage/index.vue'),
			meta:{title:"我的供应商"},
		},
		{
			path: "enterpriseDetails",
			component:() => import('@/module/supplierModule/viewPage/enterpriseDetails.vue'),
			meta:{title:"企业详情"},
		},
    ]
}