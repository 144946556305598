import homePage from '@/components/homePage/index.vue';
export default {
    path: '/system',
    component: homePage,
    redirect: '/system/index',
	meta:{title:"系统设置"},
    children: [
        {
			path: "index",
			component:() => import('@/module/systemModule/viewPage/index.vue'),
			meta:{title:"基础设置"},
		},{
			path:'accountNumber',
			component:() => import('@/module/systemModule/viewPage/accountNumber.vue'),
			meta:{title:"修改密码"}
		},{
			path:'instructionsForUse',
			component:() => import('@/module/systemModule/viewPage/InstructionsForUse.vue'),
			meta:{title:"使用说明"}
		}
    ]
}