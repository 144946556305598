import store from '../../store.js'
import router from '../../router'
import JSZip from 'jszip'
import {ElMessage} from 'element-plus'

export function logOut() { //退出登录
    //清空token
    store.commit('renewToken', '');
    //清空用户基本信息
    store.commit('renewUserInfo', {});
    //修改本地登录状态
    var sign = JSON.parse(localStorage.getItem('loginSign')) || null;
    if (sign) {
        sign.isLogin = '0';
    }
    //清空用户信息
    localStorage.setItem('userInfo', JSON.stringify({}));
    //更新本地登录状态
    localStorage.setItem(
        'loginSign',
        JSON.stringify(sign),
    )
    sessionStorage.clear()
    router.push('/');
}

//根据表单标题设置宽度（默认宽度100）
export function getLabelWidth(text) {
    return [
        '序号', '操作',
    ].indexOf(text) != -1 ? 60 : [].indexOf(text) != -1 ? 100 : [].indexOf(text) != -1 ? 140 : [
        "面价", "报价价格", "是否过保", "报价单号", "询价单号", "采购单号",
        "生产日期", "渠道", "采购单价", "利润点数 ( % )", "面价点数 ( % )", "发货地址", "地区", "发货地区",
        "采购折扣 ( % )", "销售折扣 ( % )", "销售价格",
        "采购折扣(%)", "销售折扣(%)",
    ].indexOf(text) != -1 ? 150 : [
        "数量", "入库数量", "出库数量",
    ].indexOf(text) != -1 ? 180 : [
        "利润", "企业注册时间", "经营范围", "传真",
    ].indexOf(text) != -1 ? 200 : [
        "产品信息", "企业名称", "企业注册资本法定代表人", "企业统一社会信用编码", "营业期限",
        "企业注册地址", "网址", "办公区域/详细地址", "是否加入企业微信", "询价品牌", "询价人", "询价企业",
    ].indexOf(text) != -1 ? 260 : [].indexOf(text) != -1 ? 400 :
        120;
}

//创建节点
export function createLidaButton(text, fn, vm, style) {
    //创建一个button节点
    const app = vm.createApp({
        render() {
            return vm.h(
                'div', {
                    style: {
                        'font-size': '12px',
                        color: style?.color || '#FFFFFF',
                        background: style?.backgroundColor || '#D84146',
                        'border-radius': '6px',
                        'line-height': '32px',
                        padding: '0 12px',
                        width: 'auto',
                        display: 'inline-block',
                        cursor: 'pointer',
                    },
                    onClick: fn,
                },
                text,
            )
        }
    })
    const parent = document.createElement('div')
    return app.mount(parent);
}

/* 处理压缩文件流数据 */
export function handleZIPflow(info, type) {
    return new Promise((resolve, reject) => {
        var blob = new Blob([info], {
            type: 'application/zip'
        });
        JSZip.loadAsync(blob).then(function (zip) {
            return zip.file(type).async("string");
        }).then(json => {
            var data = JSON.parse(json);
            resolve(data);
        })
    })
}

//根据字母排序
export const getLetterSortData = (data, key) => {
    let arr = []
    let sortArr = data.sort((a, b) => {
        if (a[key]) {
            return a[key].localeCompare(b[key])
        } else {
            return false
        }
    })
    // 获取字母集
    let letterArr = [...new Set(sortArr.map(i => i[key]))]
    // 组装按字母排序的数据
    letterArr.forEach((item, index) => {
        arr[index] = {
            letter: item,
            children: sortArr.filter(i => i[key] === item)
        }
    })
    return arr
}

export function handleTreeData(data, key, val, ceng, idx) { //处理树形数据
    data = JSON.parse(JSON.stringify(data));
    idx = idx || 1; //当前层级
    data.map(item => {
        item.label = item[key];
        if (val) {
            item.value = item[val];
        }
        if (item.children && item.children.length) {
            if (ceng && ceng === idx) {
                delete item.children;
            } else {
                var i = idx + 1;
                item.children = handleTreeData(item.children, key, val, ceng, i);
            }
        } else {
            delete item.children;
        }
    })
    return data;
}

export function handleTreeData2(data, key, ) { //处理树形数据
    data = JSON.parse(JSON.stringify(data));
    // idx = idx || 1; //当前层级
    data.map(item => {
        // console.log(key);
        if (item[key] != null) {
            item.label = item[key];
        } else {
            item.label = item.belongName;

        }
        // console.log(item.label);
   
            item.value = item.id;

        if (item.quoteSetVOList && item.quoteSetVOList.length) {

                // var i = idx + 1;
                item.children = handleTreeData2(item.quoteSetVOList, key, );
            
        }
        return item
    })
    return data;
}

//判断数据类型
export const checkType = val => Object.prototype.toString.call(val).split(' ')[1].slice(0, -1)


export function imgUrl(row) { //图片的改变事件
    let fileList = []
    if (row.files && checkType(row.files) === 'String') {
        fileList = JSON.parse(row.files)
    } else if (row.files && checkType(row.files) === 'Array') {
        fileList = row.files
    }
    fileList = fileList.filter((obj) => {
        return obj.fileType === 'PICTURE'
    })
    return (fileList[0] || {}).url || ''
}

export function throttle(fn, delay) {
    // 记录上一次函数触发的时间以及定义一个定时器
    var lastTime, timer;
    // 延迟多长时间
    var delay = delay || 200;
    return function () {
        var args = arguments;
        // 记录当前函数触发的时间
        var nowTime = Date.now();
        if (lastTime && nowTime - lastTime < delay) {
            clearTimeout(timer);
            timer = setTimeout(function () {
                // 记录上一次函数触发的时间
                lastTime = nowTime;
                // 修正this指向问题
                //fn.apply(this, args);
            }, delay);
        } else {
            lastTime = nowTime;
            fn.apply(this, args);
        }
    }
}

export function getBase64(file) { //图片转base64
    return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
            imgResult = reader.result;
        };
        reader.onerror = function (error) {
            reject(error);
        };
        reader.onloadend = function () {
            resolve(imgResult);
        };
    });
}

export function runNumber(val) {  //返回一个数字
    return typeof val === 'string' ? isNaN(parseFloat(val || '0')) ? 0 : parseFloat(val || '0') :
        typeof val === 'number' ? isNaN(val) ? 0 : val !== Infinity ? val : 0 : 0;
}

export function downLoadFile(downUrl, fileName) {
    let a = document.createElement("a");// 创建a标签
    if ('download' in a) {
        a.download = fileName;// 设置下载文件的文件名
    }
    (document.body || document.documentElement).appendChild(a);
    a.href = downUrl;// downUrl为后台返回的下载地址
    a.target = '_parent';
    a.click();// 设置点击事件
    a.remove(); // 移除a标签
}

export function copyText(text) { //复制
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    if (document.execCommand('copy')) {
        document.execCommand('copy');
        ElMessage({
            type: 'success',
            message: "复制成功！",
            onClose: function () {
                textarea.remove();
            }
        })
    }
}

export function fileIsImage(url) {
    if (url.indexOf(".") > -1) {
        var index = url.lastIndexOf("."),
            type = url.slice(index + 1);
        let arr = [
            'bmp', 'jpg', 'png', 'tif', 'gif', 'pcx', 'tga',
            'exif', 'fpx', 'svg', 'psd', 'cdr', 'pcd', 'dxf', 'ufo', 'eps', 'ai', 'raw', 'wmf', 'webp', 'avif', 'apng',
        ];
        var b = []
        arr.map(item => {
            b.push(item.toUpperCase())
        });
        arr = [
            ...arr,
            ...b,
        ];
        return arr.some(v => v === type);
    } else {
        return false;
    }
}
