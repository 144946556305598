import homePage from '@/components/homePage/index.vue';
export default {
    path: "/stock",
	component:homePage,
	redirect:"/stock/index",
	meta:{title:"我的客户"},
	children:[
		{
			path: "index",
			component:() => import('@/module/stockModule/viewPage/index.vue'),
			meta:{title:"库存信息"},
		},{
			path:'enterStock',
			component:() => import('@/module/stockModule/viewPage/enterStock.vue'),
			meta:{title:'入库明细'},
		},{
			path:'outStock',
			component:() => import('@/module/stockModule/viewPage/outStock.vue'),
			meta:{title:'出库明细'},
		},{
			path:'enterStockDetails',
			component:() => import('@/module/stockModule/viewPage/enterStockDetails.vue'),
			meta:{title:'入库明细详情'},
		},{
			path:'outStockDetails',
			component:() => import('@/module/stockModule/viewPage/outStockDetails.vue'),
			meta:{title:'出库明细详情'},
		},{
			path: "uploadStock",
			component:() => import('@/module/stockModule/viewPage/uploadStock.vue'),
			meta:{title:"导入库存"},
		},
		{
			path: "secondUploadStock",
			component:() => import('@/module/stockModule/viewPage/secondUploadStock.vue'),
			meta:{title:"导入库存2"},
		},
		{
			path: "lastUploadStock",
			component:() => import('@/module/stockModule/viewPage/lastUploadStock.vue'),
			meta:{title:"导入库存3"},
		},
	]
}