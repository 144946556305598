import request from '@/common/js/request.js';
import api from '@/common/js/api.js';
export default {
	namespaced: true, //区分模块
	state: {
		offerSuccessType:false,  //报价成功状态
		inquiryType:{  //客户询价状态
			'WAIT_QUOTE':'待报价',
			'COMPLETE_QUOTE':'已报价',
			'REVOKE':'已撤销',
			'OVERDUE':'已过期',
			'STOP':'已终止',
		},
		offerType:{  //报价状态
			'COMPLETE_QUOTE':'已报价',
			'WAIT_CONTRACT':'待发合同',
			'WAIT_BACK_CONTRACT':'待回传合同',
			'FINANCE_PENDING':'待收款',
			'SHIP_PENDING':'待发货',
			'RECEIVE_PENDING':'已发货',
			'COMPLETED':'已完成',
		},
		paymentTypes: [  //付款方式
            {title: '合同全款到帐后订货发货', type: 'PAY_ON_001'},
            {title: '预付30%定金订货。合同余款到帐后发货', type: 'PAY_ON_002'},
            {title: '预付0%定金订货。货到收货地付清合同余款交货', type: 'PAY_ON_003'},
            {title: '货到付合同全款,收取现金如协商同意付支票或承兑，需货到当场给付', type: 'PAY_ON_004'},
            {title: '预付30%定金。合同余款交货日起15沃内结清!', type: 'PAY_ON_005'},
            {title: '预付30%定金。合同余款交货日起30天内结清!', type: 'PAY_ON_006'},
            {title: '预付30%定金。合同余款交货日起45天内结清!', type: 'PAY_ON_007'},
            {title: '预付30%定金。合同余款交货日起60天内结清!', type: 'PAY_ON_008'},
            {title: '预付50%定金。合同余款交货日起15天内结清! 逾期每天按合同总金额1%罚款', type: 'PAY_ON_009'},
            {title: '预付50%定金。合同余款交货日起30天内结清! 逾期每天按合同总金额1%罚款', type: 'PAY_ON_010'},
            {title: '预付50%定金。合同余款交货日起45天内结清! 逾期每天按合同总金额1%罚款', type: 'PAY_ON_011'},
            {title: '预付50%定金。合同余款交货日起60天内结清! 逾期每天按合同总金额1%罚款', type: 'PAY_ON_012'},
            {title: '预付10%定金订货,付0%发货，40%余款15沃内结清', type: 'PAY_ON_013'},
            {title: '预付10%定金订货，付50%发货，40%余款3天内结清', type: 'PAY_ON_014'},
            {title: '全款送货日起15天内结清!逾期每天按合同总金额1%罚款', type: 'PAY_ON_015'},
            {title: '全款送货日起30天内结清!逾期每天按合同总金额1%罚款', type: 'PAY_ON_016'},
            {title: '全款送货日起45天内结清!逾期每天按合同总金额1罚款', type: 'PAY_ON_017'},
            {title: '全款送货日起60天内结清!逾期每天按合同总金额1罚款', type: 'PAY_ON_018'},
            {title: '合同总金额下月25号前结清全款!逾期每天按合同总金额1%罚款', type: 'PAY_ON_019'},
            {title: '货到付款', type: 'PAY_ON_020'},
            {title: '交货日起30天内结清。30天月结', type: 'PAY_ON_021'},
        ],
		channel:[ //渠道
			{title:'现货'},
			{title:'期货'},
			{title:'1-3天'},
			{title:'3-5天'},
			{title:'7天内'},
			{title:'1-2周'},
			{title:'2-3周'},
			{title:'3-4周'},
			{title:'4-5周'},
			{title:'5-6周'},
			{title:'6-7周'},
			{title:'7-8周'},
			{title:'8-9周'},
			{title:'9-10周'},
			{title:'10周以上'},
		]
	},
	getters: {

	},
	mutations: {
		setOfferSuccessType(state,data){
			state.offerSuccessType = data;
		}
	},
	actions: {
		quoteReq(context, params){
			return request.post(api.quote, params)
		},
		listTreeReq(context, params){
			return request.get(api.listTree,{params})
		},
		quoteSetCreate(context,params){ //新增代理设置
			return request.post(api.quoteSetCreate,params)
		},
		quoteSetSkuCreate(context,params){ //新增自动报价商品
			return request.post(api.quoteSetSkuCreate,params)
		},
		quoteSetSkuPage(context,params){ //获取自动报价商品列表
			return request.post(api.quoteSetSkuPage,params)
		},
		
        skuPage(context, params) { //获取商品列表ES型
            return request.post(api.skuPage, params);
        },
		quoteSetSkuSkuIds(context,params){ //获取已设置的商品ids
			return request.post(api.quoteSetSkuSkuIds,params)
		},
		quoteSetSkuRemove(context,id){ //删除自动报价商品
			return request.delete(`${api.quoteSetSkuRemove}?id=${id}`)
		},
		quoteSetSkuUpdate(context,params){ //修改报价设置商品
			return request.post(api.quoteSetSkuUpdate,params)
		},
		quoteSetSkuUpdateBatch(context,params){ //修改报价设置商品
			return request.post(api.quoteSetSkuUpdateBatch,params)
		},
		quoteSetList(context,params){ //查找列表代理(非代理)列表
			return request.post(api.quoteSetList,params)
		},
		quoteSetUpdate(context,params){  //修该代理(非代理)设置
			return request.post(api.quoteSetUpdate,params)
		},
		inquiryPageSeller(context,params){ //客户询价列表
			return request.post(api.inquiryPageSeller,params)
		},
		inquirySellerInfo(context,params){ //询价详情(我要销售)
			return request.get(api.inquirySellerInfo,{params})
		},
		purchasePage(context,params){ //我的订单列表
			return request.post(api.purchasePage,params)
		},
		purchaseShipUndo(context, params) { //采购单发货作废
			return request.post(api.purchaseShipUndo,params)
		},
		purchaseUndoAudit(context, params) { //采购单发货作废
			return request.post(api.purchaseUndoAudit,params)
		},
		quotePage(context,params){ //我的报价单列表
			return request.post(api.quotePage,params)
		},
		purchaseDetails(context,id){ //订单详情
			return request.get(`${api.purchaseDetails}/${id}`)
		},
		quoteInfo(context,params){ //报价信息查看
			return request.get(api.quoteInfo,{params});
		},
		purchaseUpdateContractFile(context,params){ //上传合同
			return request.post(api.purchaseUpdateContractFile,params)
		},
		purchaseList(context,id){ //采购单发货商品列表
			return request.post(`${api.purchaseList}?documentId=${id}`);
		},
		purchasePurchaseShip(context,params){ //订单商品发货
			return request.post(api.purchasePurchaseShip,params)
		},
		purchaseCollection(context,params){ //采购单收款
			return request.post(api.purchaseCollection,params)
		},
		purchaseGetExpress(context,params){ //查看物流
			return request.get(api.purchaseGetExpress,{params})
		},
		purchaseReceipt(context,params){ //确认收货
			return request.post(api.purchaseReceipt,params)
		},
		purchaseReceiptPage(context,params){ //获取用户资金列表
			return request.post(api.purchaseReceiptPage,params)
		},
		userGetUserSet(context,params){ //获取收款二维码
			return request.get(api.userGetUserSet,{params});
		},
		quoteCreateByMyself(context,params){ //新增报价
			return request.post(api.quoteCreateByMyself,params);
		},
		invoicePage(context,params){ //发票列表
			return request.post(api.invoicePage,params);
		},
		purchaseCollectionList(context,params){ //采购单收款明细列表
			return request.post(api.purchaseCollectionList,params);
		},
		purchaseCollectionUndo(context,params){  //支付记录作废
			return request.post(api.purchaseCollectionUndo,params);
		},
		// purchaseShipUndo(context,params){  //发货记录作废
		// 	return request.post(api.purchaseShipUndo,params);
		// },
		
        userListByShareEnterpriseId(context, params) { //获取询价客户
            return request.get(api.userListByShareEnterpriseId, {params})
        },
		userConsigneeDefault(context,id){ //获取默认收货地址
			return request.get(`${api.userConsigneeDefault}/${id}`,{});
		},
		purchaseUpdateAddress(context,params){ //修改采购单默认地址
			return request.post(api.purchaseUpdateAddress,params);
		},
		invoiceUpdateAddress(context,params){ //修改采购单发票地址
			return request.put(api.invoiceUpdateAddress,params);
		},
		invoiceInfo(context,id){ //获取订单发票详情
			return request.get(`${api.invoiceInfo}/${id}`);
		},
		invoiceCreate(context,params){ //申请开票
			return request.post(api.invoiceCreate,params);
		},
		invoicePass(context,params){ //通过开票
			return request.put(api.invoicePass,params);
		},
		invoiceUnPass(context,params){ //开票不通过
			return request.put(api.invoiceUnPass,params);
		},
		pageInitiative(context,params){ //我收到的报价
			return request.post(api.pageInitiative,params);
		},
		quoteAgentList(context,params){ //品牌代理列表
			return request.post(api.quoteAgentList,params);
		},
		quoteSetListBelongIds(context,params){ //查找列表代理(非代理)列表
			return request.post(api.quoteSetListBelongIds,params)
		},
		quoteAgentCreate(context,params){ //新增代理设置
			return request.post(api.quoteAgentCreate,params)
		},
		listBrandInfo(context,params){ //品牌代理列表
			return request.post(api.listBrandInfo,params)
		},

		quoteAgentDelete(context,id){ //订单详情
			return request.delete(`${api.quoteAgentDelete}/${id}`)
		},
		getUserInvoiceUserId(context,params){ //查询用户税号信息
			console.log(context,params);
			return request.get(api.getUserInvoiceUserId,{params})
		},
		batchUpperOrLower(context,params) { //批量上架/下架
			return request.post(api.batchUpperOrLower,params)
		},
		removeBatch(context,params){ //批量删除
			return request.post(api.removeBatch,params)
		},
		enterpriseMyInfo(context,params){ //查询挂靠公司
			return request.get(api.enterpriseMyInfo,{params})
		},
		userGetHeadCount(context,params){ //接单设置-获取顶部数据统计
			return request.get(api.userGetHeadCount,{params})
		},
		countDataAndQuoteSwitch(context,params){ //接单设置数据列表
			return request.get(api.countDataAndQuoteSwitch,{params})
		},
		adminSetUserSet(context,params){ //接单设置数据列表
			return request.post(api.adminSetUserSet,params)
		},
		quoteBrandList(context,params){ //接单设置数据列表
			return request.get(api.quoteBrandList,params)
		},
		quoteListByMyself(context,params){ //接单设置数据列表
			return request.post(api.quoteListByMyself,params)
		},
		quoteCreate(context,params){ 
			return request.post(api.quoteCreate,params)
		},
		quoteModifyQuoteItem(context,params){ 
			return request.post(api.quoteModifyQuoteItem,params)
		},
		
		quoteWaiveQuote(context,params){ 
			return request.post(api.quoteWaiveQuote,params)
		},
		quoteHistoryQuote(context,params){ 
			return request.get(api.quoteHistoryQuote,{params})
		},

		quoteAgentDeleteAll(context,id){ //删除自动报价商品
			return request.delete(`${api.quoteAgentDeleteAll}/${id}`)
		},
		pageSellerByQuote(context,params){ 
			return request.post(api.pageSellerByQuote,params)
		},
	}
}
