/**
 * 下载Excel表格
 * @param data 处理返回的文件流
 * @param excelName 表格名称
 */

import moment from 'moment'
 export const downloadExcel = (data, name) => {
    let date = moment(new Date()).format('YYYYMMDDHHmmss')
    let blob = new Blob([data], { type: 'applicationnd.ms-excel' }) // 将服务端返回的文件流（二进制）excel文件转化为
    let fileName = name + '_' + date + '.xlsx' // 文件名
    if (!data) {
      return
    }
    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE
      window.navigator.msSaveOrOpenBlob(blob, fileName)
    } else {
      let url = window.URL.createObjectURL(blob)
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(link.href) // 释放URL 对象
      document.body.removeChild(link)
    }
  }