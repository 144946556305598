<template>
  <el-container class="lida-content" style="height: 100%;">
    <!-- 左边部分 -->
    <el-aside class="lida-left">
      <div class="logo-warp">
        <el-image
          style="width: 80px; height: 126px"
          :src="src"
          fit="contain"
        ></el-image>
      </div>

      <!--			<img src="../../assets/logo1.png" :width="80" height="126" object-fit="contain" />-->
      <lida-menu></lida-menu>
      <div class="operation-box">
    
        <div class="o-w-bos">
          <span class="lable">自动接单：</span>
          <el-switch
            v-model="AUTO_QUOTE"
            class="ml-2"
            size="mini"
            active-color="#13ce66"
            @change="setAutoQuote"
          />
        </div>
        <div class="o-btn" @click="clickOrderSetup">
          销售中心
        </div>
        <div class="o-btn" @click="clickAddOfferOrder">
          新增报价单
        </div>
        <div class="o-btn" @click="clickAddInquiryPage">
          新增询价单
        </div>
      </div>
    </el-aside>

    <el-container>
      <!-- 头部 -->
      <el-header class="lida-header">
        <!-- <div class="lida-title">立大总控</div>
				<div class="lida-nav" v-if="navs.length">
					<div
						:class="['lida-nav-i',index === navs.length - 1 ? '' : 'nav-disable' ]"
						v-for="(item,index) in navs"
						:key="index"
						@click="linkPage(item.path,index === navs.length - 1)"
					>
						<span v-if="index">/</span>{{item.title}}
					</div>
				</div>
				<el-dropdown>
					<span class="user-info">
						<i class="iconfont icon-yonghu" style="font-size: 20px;"></i>
					</span>
					<template #dropdown>
					  <el-dropdown-menu>
						<el-dropdown-item @click="dropdownClick(item)" v-for="(item,index) in dropdown" :key="index">
							<div class="dropdown-row">{{item.text}}</div>
						</el-dropdown-item>
					  </el-dropdown-menu>
					</template>
				</el-dropdown> -->

        <el-tabs
          :model-value="activeNavs"
          type="card"
          @tab-remove="removeNavs"
          @tab-click="changeNavs"
          style="width: 70%;"
        >
          <el-tab-pane
            v-for="(item, index) in navs"
            :key="index"
            :label="item.meta.title"
            :closable="!item.closable"
            :name="item.path"
          ></el-tab-pane>
        </el-tabs>

        <div class="top-icon-btn-box">
          <div>
            <span style="marginRight:20px">{{ user.enterpriseName }}</span>
            <span style="marginRight:20px">{{ user.realName }}</span>
            <span style="marginRight:20px">{{ user.mobilePhone }}</span>
          </div>
          <i class="iconfont iconshiyongshuoming" @click="clickExplain"></i>
          <el-popover
            placement="bottom"
            title="联系客服"
            width="200"
            trigger="click"
          >
            <div class="kefu-box">
              <div class="img-left">
                <img src="../../assets/weixinerweima.jpg" alt="" />
                <p class="infos">盘雷热线</p>
                <p class="phone">18982112314</p>
              </div>
              <div class="img-right">
                <p class="infos">
                  <i class="iconfont iconweixin"></i> 微信扫码加群咨询
                </p>
                <img src="../../assets/qiyeweixin.png" alt="" />
                <p class="infos">
                  <i class="iconfont iconweixin"></i> 微信扫码咨询
                </p>
                <img src="../../assets/weixin.png" alt="" />
              </div>
            </div>
            <template #reference>
              <i class="iconfont iconkefu"></i>
            </template>
          </el-popover>

          <el-dropdown>
            <span class="user-info">
              <i class="iconfont icon-yonghu" style="font-size: 20px;"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  @click="dropdownClick(item)"
                  v-for="(item, index) in dropdown"
                  :key="index"
                >
                  <div class="dropdown-row">{{ item.text }}</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-header>
      <!-- 中心部分 -->
      <el-main style="padding: 10px;">
        <div class="lida-main" :style="{ background: background }">
          <router-view v-if="forceUpdate"/>
        </div>
      </el-main>
    </el-container>
  </el-container>
  <!-- 微信的绑定和解绑   -->
  <WeChatBind
    :isBind="userInfo.wechatFlag === '1'"
    @refresh="handlerRefresh"
    callDialog
    ref="weChatBind"
    :isShow="showWeChat"
  ></WeChatBind>
</template>

<script>
import lidaMenu from "@/components/publicComponent/lidaMenu.vue";
import { logOut } from "@/common/js/common.js";
import { mapMutations, mapActions } from "vuex";
import WeChatBind from "@/module/systemModule/components/weChatBind.vue";

export default {
  data() {
    return {
      dropdown: [
        {
          text: "退出",
        },
        {
          text: "修改密码",
        },
      ],
      src: require("../../assets/lida.png"),
      userInfo: {},
      tabListShowValue: {},
      showWeChat: false,
      tempList: [
        {
          setTpCd: "PROFIT_POINTS", //利润点数
          value: "",
          files: [],
          list: [],
        },
        {
          setTpCd: "COLLECTION_QR_CODE", //收款码
          value: "",
          files: [],
          list: [],
        },
      ],
      AUTO_QUOTE: false,
      user: {},
      forceUpdate:true
    };
  },
  components: {
    lidaMenu,
    WeChatBind,
  },

  methods: {
    ...mapMutations([
      "renewLDrouters", //更新导航集合
    ]),
    ...mapActions([
      "uWechatBind", //绑定微信号
    ]),
    ...mapActions("systemModule", [
      "getUserInfo", //请求用户信息的接口
      "userListUserSetByUserId", //获取批量修改列表
      "userSetUserSetBatch", //批量修改
      "getDisplayTabListOfHome",
    ]),
    ...mapMutations("mysaleMudule", ["setOfferSuccessType"]),
    ...mapMutations("homeModule", ["setUserInfos", "searchUserInfo"]),
    ...mapActions("homeModule", ["searchUserInfo"]),
    linkPage(path, state) {
      //跳转页面
      if (!state) {
        this.$router.push(path);
      }
    },
    dropdownClick(row) {
      switch (row.text) {
        case "退出":
          logOut();
          break;
        case "修改密码":
          this.$router.push("/system/accountNumber");
          break;
        default:
      }
    },
    changeNavs(e) {
      //选择的点击事件
      
      if(this.$route.path == e.props.name){
        this.forceUpdate = false
        setTimeout(()=>{
          this.forceUpdate = true
        },10)
        return
      }
      this.$router.push(
        this.navs.filter((v) => v.path === e.props.name).length
          ? this.navs.filter((v) => v.path === e.props.name)[0]
          : {
              meta: {
                title: "首页",
              },
              path: "/home/index",
              closable: true,
              active: true,
            }
      );
    },
    removeNavs(name) {
      //删除的点击事件
      if (name === this.activeNavs) {
        this.navs.splice(
          this.navs.findIndex((v) => v.path === name),
          1
        );
        this.$router.go(-1);
      } else {
        this.navs.splice(
          this.navs.findIndex((v) => v.path === name),
          1
        );
      }
      this.renewLDrouters([...this.navs]);
    },
    authRefresh(callback) {
      //刷新用户是否绑定了微信
      this.getUserInfo().then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          this.userInfo = data;
          this.setUserInfos(data);
          callback && callback(data.wechatFlag);
        }
      });
    },
    customerOfferSuccess(v) {
      if (v) {
        this.setOfferSuccessType(false);
        this.authRefresh((auth) => {
          this.$refs.weChatBind.dialogVisible = auth !== "1";
        });
      }
    },
    handlerRefresh() {
      this.authRefresh();
    },
    getInfo() {
      this.userListUserSetByUserId().then((res) => {
        let { code, data } = res.data;
        if (code === "0" && data.length) {
          data.map((item) => {
            item.list = JSON.parse(item.files || "[]");
            item.files = [];
            if (item.setTpCd === "AUTO_QUOTE") {
              this.AUTO_QUOTE = item.value === "1";
            }
          });
          this.tempList = data;
        }
      });
    },
    setAutoQuote(v) {
      var data = JSON.parse(JSON.stringify(this.tempList));
      data.map((item) => {
        item.files = item.files.length ? item.files[0] : "";
        if (item.setTpCd === "AUTO_QUOTE") {
          item.value = v?'1':'0';
            }
        delete item.list;
      });


      // data.push({
      //   files: "",
      //   setTpCd: "AUTO_QUOTE",
      //   value: this.AUTO_QUOTE ? "1" : "0",
      // });
      this.userSetUserSetBatch(data).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          ElMessage({
            type: "success",
            message: "修改成功",
          });
          //更新页面数据
          this.getInfo();
        }
      });
    },
    clickOrderSetup() {
      this.$router.push("/mysale/orderSetup");
    },

    clickOrderSetupOnMyProduct() {
      this.$router.push(`/mysale/orderSetup?tab=t2_${new Date()}`);
    },

    clickAddInquiryPage() {
      this.$router.push("/purchase/addInquiryPage");
    },
    clickAddInquiryPage() {
      this.$router.push("/purchase/addInquiryPage");
    },
    clickAddOfferOrder() {
      this.$router.push({
        path: "/mysale/addOfferOrder",
      });
    },
    searchUser() {
      this.searchUserInfo({}).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          this.user = data;
        }
      });
    },
  },
  computed: {
    navs() {
      if (
        this.$store.state?.LDrouters &&
        Object.keys(this.tabListShowValue).length
      ) {
        return this.$store.state.LDrouters?.filter((e) => {
          if (
            e.meta.title === "销售中心" &&
            this.tabListShowValue.SELLING_CENTRE_DISPLAY_ADMIN === "0"
          ) {
            return false;
          } else if (
            e.meta.title === "采购中心" &&
            this.tabListShowValue.PURCHASING_CENTER_DISPLAY_ADMIN === "0"
          ) {
            return false;
          }
          return true;
        });
      }
      return [];
    },
    activeNavs() {
      return this.navs.filter((v) => v.active).length
        ? this.navs.filter((v) => v.active)[0].path
        : "";
    },
    background() {
      //根据路由匹配主体背景颜色
      return this.$route.path === "/purchase/product" ? "#F2F5FA" : "#ffffff";
    },
  },
  async created() {
    //报价成功的回调
    this.customerOfferSuccess(this.$store.state.mysaleMudule.offerSuccessType);
    // 拿是否自动报价状态
    this.getInfo();
    this.searchUser();
    //后端要求这样写
    try {
      const { userId } = JSON.parse(localStorage.userInfo);
      const PURCHASING_DATA = await this.getDisplayTabListOfHome({
        setTpCd: "PURCHASING_CENTER_DISPLAY_ADMIN",
        sellerId: userId,
      });
      const SELLING_DATA = await this.getDisplayTabListOfHome({
        setTpCd: "SELLING_CENTRE_DISPLAY_ADMIN",
        sellerId: userId,
      });
      if (PURCHASING_DATA?.data?.data && SELLING_DATA?.data?.data) {
        this.tabListShowValue = {
          PURCHASING_CENTER_DISPLAY_ADMIN: PURCHASING_DATA.data.data.value,
          SELLING_CENTRE_DISPLAY_ADMIN: SELLING_DATA.data.data.value,
        };
      }
    } catch (error) {}
  },
  watch: {
    "$store.state.LDrouters"(v) {
      this.navs = [...v];
    },
    "$store.state.mysaleMudule.offerSuccessType"(v) {
      this.customerOfferSuccess(v);
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .lida-header .el-tabs {
  .el-tabs__item:first-child {
    //&.is-active {
    //  color: #FFF !important;
    //}
    //
    //background: #D84146 !important;
    //color: #FFF;
  }
}

.lida-content {
  background: @home-content-background;

  .lida-left {
    background: @home-left-background;
    width: 120px;
    //padding-top: 40px;
    overflow: inherit;
    position: relative;

    .logo-warp {
      padding-top: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    img {
      margin: 0 auto;
      width: 30px;
    }

    .operation-box {
      position: absolute;
      bottom: 8%;
      left: 10px;
      color: #fff;

      .o-w-bos {
        .lable {
          font-size: 12px;
        }
      }

      .o-btn {
        cursor: pointer;
        display: inline-block;
        margin: 5px auto;
        border-radius: 5px;
        border: 1px solid #d84146;
        padding: 1px 10px 3px 10px;
        background: #d84146;
      }
    }
  }

  .lida-header {
    background: #ffffff;
    box-sizing: border-box;
    padding: 0 20px;
    height: initial;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 20px);
    margin: 0 auto;
    border-radius: 0 0 5px 5px;

    .top-icon-btn-box {
      display: flex;
      align-items: center;

      .iconkefu,
      .iconshiyongshuoming {
        font-size: 16px;
        margin-right: 20px;
        cursor: pointer;
      }
    }

    /deep/ .el-tabs__header {
      border: none;
      margin-bottom: 0;

      .el-tabs__nav {
        border: none !important;

        .el-tabs__item {
          height: 32px;
          margin-right: 5px;
          border: none !important;
          background: #dfe2e7;
          border-radius: 5px 5px 0 0;
        }

        .is-active {
          background: @dh-color;
          color: #fff;
          //background: #f2f5fa;
        }
      }

      .el-tabs__nav-prev,
      .el-tabs__nav-next {
        line-height: 30px;
      }
    }

    /deep/ .el-dropdown {
    }
  }

  .lida-main {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 10px;
    position: relative;
    overflow-y: auto;
    &::-webkit-scrollbar {
  display: none;
}
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  }

//   .lida-main ::-webkit-scrollbar {
//   display: none;
// }
}

.kefu-box {
  display: flex;
  width: 500px;
  padding: 20px;
  justify-content: space-between;

  img {
    width: 240px;
  }

  .img-right,
  .img-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .infos {
      color: #808080;
      padding: 10px 0;

      .iconweixin {
        color: green;
        margin-right: 10px;
        font-size: 16px;
      }
    }
  }

  .img-left {
    font-size: 16px;
    justify-content: space-around;

    .infos {
      width: 100%;
      color: #292929;
      padding: 15px 0;
      margin: 15px 0;
      font-size: 18px;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
    }

    .phone {
      color: #00a39e;
      font-size: 30px;
      font-weight: 600;
    }
  }
}
</style>
