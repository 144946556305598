import loginModule from './loginModule'
import homeModule from './homeModule'
import purchaseMudule from './purchaseMudule'
import mysaleMudule from './mysaleMudule'
import clientModule from './clientModule'
import repertoryModule from './repertoryModule'
import myMessageModule from "./myMessageModule"
import systemModule from './systemModule'
import supplierModule from './supplierModule'
import stockModule from './stockModule'
import contractModule from './contractModule'
import supplierMessageModule from './supplierMessageModule'
import saleMessageModule from './saleMessageModule'
import distributionMudule from './distributionMudule'
import myDistributionMudule from './myDistributionMudule'
export default{
	install(Vue,option){ //注册模块
		Vue.use(loginModule,option);
		Vue.use(homeModule,option);
		Vue.use(purchaseMudule,option);
		Vue.use(mysaleMudule,option);
		Vue.use(clientModule,option);
		Vue.use(repertoryModule,option);
		Vue.use(myMessageModule,option);
		Vue.use(systemModule,option);
		Vue.use(supplierModule,option);
		Vue.use(stockModule,option);
		Vue.use(contractModule,option);
		Vue.use(supplierMessageModule,option);
		Vue.use(saleMessageModule,option);
		Vue.use(distributionMudule,option);
		Vue.use(myDistributionMudule,option);
		

	}
}