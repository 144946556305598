import { defineAsyncComponent } from 'vue'
export default{
	install(Vue){   //注册全局的公共组件
		Vue.component('lidaTable', 
			defineAsyncComponent({
				loader: () => import('./index.vue'),
			})
		);
		Vue.component('lidaTableColumn',
			defineAsyncComponent({
				loader: () => import('./lida-table-column.vue')
			})
		)
	}
}