import request from '@/common/js/request.js';
import api from '@/common/js/api.js';
export default {
	namespaced: true, //区分模块
	state: {
		identifyLabel: [ //客户标签
			{ title: '合作客户', type: '0' },
			{ title: '报价客户', type: '1' },
			{ title: '注册客户', type: '2' },
		],
	},
	getters: {

	},
	mutations: {

	},
	actions: {
		enterpriseMyEnterprisePage(context, params) { //我的供应商列表
			return request.post(api.enterpriseMyEnterprisePage, params)
		},
		allEnterprisePage(context, params) { //所有供应商列表
			return request.post('enterprise/allSupplierEnterprisePage', params)
		},
		myEnterpriseInfo(context, id) { //获取企业详情
			return request.get(`${api.myEnterpriseInfo}?enterpriseId=${id}`)
		},
	}
}
