import request from '@/common/js/request.js';
import api from '@/common/js/api.js';
export default {
	namespaced: true, //区分模块
	state: {
		
	},
	getters: {
		
	},
	mutations: {
		
	},
	actions: {
		userMyUserPage(context,params){ //我的客户
			return request.post(api.userMyUserPage,params)
		},
		userMyUserEnterpriseInfo(context,id){ //获取企业详情
			return request.get(`${api.userMyUserEnterpriseInfo}?enterpriseId=${id}`)
		},
	}
}
