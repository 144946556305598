<template>
  <div>
    <div class="e">
      <div class="e-left">
        <el-form
          label-width="120px"
          :model="registerInfo"
          class="info-form"
          :rules="registerRules"
          ref="loginFrom"
        >
          <div class="e">
            <div class="e-left">
              <div>{{ thatEnterpriseInfo }}</div>
              <el-form-item label="邀请人企业">
                <el-input
                  v-model="registerInfo.copyEnterpriseName"
                  placeholder="请输入"
                />
              </el-form-item>
              <!-- <el-form-item label="企业名称" prop="enterpriseId">
                <el-select
                    clearable
                    v-model="registerInfo.enterpriseId"
                    filterable
                    @change="selectChange"
                    placeholder="请选择">
                  <el-option
                      v-for="item in list"
                      :label="item.enterpriseName"
                      :value="item.id"
                      :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item label="营业执照:" prop="fileIds">
                <upload-file
                  isType="add"
                  list-type="picture"
                  v-model="registerInfo.fileIds"
                  :limit="1"
                  @usuccess="uploadSuccess"
                />
              </el-form-item>
              <div
                class="r-tips"
                style="position: relative; top: -20px; padding-bottom: 0"
              >
                *请务必以营业执照为准，否则无法接下来的操作
              </div>
              <template v-if="registerInfo.fileIds.length">
                <div class="r-row">
                  <span style="width: 100px">企业名称</span>
                  <el-input
                    style="flex: 1"
                    placeholder="请输入"
                    @blur="inputBlur"
                    disabled
                    v-model="registerInfo.enterpriseName"
                  />
                  <!-- <el-button
				      style="margin-top: 6px;margin-left: 10px;"
				      @click="add"
				      type="primary"
				      size="mini">确认添加</el-button> -->
                </div>
                <div class="r-tips">
                  *如果未找到企业，请在下面输入添加企业名称
                </div>
              </template>
              <el-form-item label="姓名" prop="realName">
                <el-input
                  v-model="registerInfo.realName"
                  placeholder="请输入"
                />
              </el-form-item>
              <el-form-item label="手机号" prop="mobilePhone">
                <el-input
                  v-model="registerInfo.mobilePhone"
                  placeholder="请输入"
                />
              </el-form-item>
              <div class="r-row">
                <el-form-item
                  label="短信验证码"
                  prop="verificationCode"
                  style="flex: 1"
                >
                  <el-input
                    v-model="registerInfo.verificationCode"
                    placeholder="请输入"
                  />
                </el-form-item>
                <el-button
                  style="margin-top: 6px; margin-left: 10px"
                  type="primary"
                  @click="getCode"
                  :disabled="second !== 0"
                  size="mini"
                  >{{ getCodeText }}</el-button
                >
              </div>
              <el-form-item label="密码" prop="passWord">
                <el-input
                  type="password"
                  v-model="registerInfo.passWord"
                  placeholder="请输入"
                />
              </el-form-item>
              <el-form-item prop="fChecked">
                <div class="checkbox">
                  <el-checkbox v-model="registerInfo.fChecked"></el-checkbox>
                  <span style="margin-left: 10px"
                    >我已阅读<el-button
                      type="text"
                      @click="dialogVisible = true"
                      >《盘雷服务使用协议》</el-button
                    >并接受条款</span
                  >
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <el-dialog
          class="dialog"
          v-model="dialogVisible"
          title="盘雷服务使用协议"
          width="30%"
          :before-close="handleClose"
        >
          <span class="wen"
            >欢迎你使用 盘雷（www.panlei.cn） 平台软件及服务！</span
          ><br />
          <span class="wen"
            >为使用 盘雷（www.panlei.cn）
            平台软件（以下简称“本平台”）及服务，你应当阅读并遵守《
            盘雷（www.panlei.cn）
            平台软件许可及服务协议》（以下简称“本协议”），以及《
            盘雷（www.panlei.cn）服务协议》 。请你务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款，以及开通或使用某项服务的单独协议，并选择接受或不接受。限制、免责条款可能以加粗等形式提示你注意。</span
          ><br />
          <span class="wen"
            >除非你已阅读并接受本协议所有条款，否则请您停止注册。如您按照注册流程提示填写信息、阅读并点击同意上述协议且完成全部注册流程后，即表示您已充分阅读、理解并接受协议的全部内容。你的下载、安装、使用、获取
            平台帐号、登录等行为即视为你已阅读并同意上述协议的约束。</span
          ><br />
          <span class="wen">生效日期：2020年1月1日</span><br />
          <span class="wen">一、协议的范围</span><br />
          <span>1.1 协议适用主体范围</span><br />
          <span class="wen"
            >本协议是你与
            盘雷（www.panlei.cn）之间关于你下载、安装、使用、复制本平台，以及使用
            盘雷（www.panlei.cn）相关服务所订立的协议。</span
          ><br />
          <span>1.2 协议关系及冲突条款</span><br />
          <span
            >本协议被视为《
            盘雷（www.panlei.cn）服务协议》的补充协议，是其不可分割的组成部分，与其构成统一整体。本协议与上述内容存在冲突的，以本协议为准。</span
          ><br />
          <span
            >本协议内容同时包括
            盘雷（www.panlei.cn）可能不断发布的关于本服务的相关协议、业务规则等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，你同样应当遵守。</span
          ><br />
          <span class="wen">二、关于本服务</span><br />
          <span>2.1 本服务的内容</span><br />
          <span
            >2.1.1本服务内容是指
            盘雷（www.panlei.cn）向用户提供的跨平台的工业供应链工具（以下简称“
            平台”），支持，与客户，与供应商，单向，多向，买卖报价，合同，订单跟踪，同时为用户提供包括但不限于关系链拓展、便捷工具、
            平台公众帐号、开放平台、与其他软件或硬件信息互通等功能或内容的软件许可及服务（以下简称“本服务”）。</span
          ><br />

          <span
            >2.1.2用户在完全同意本协议及本网站规定的情况下，方有权使用本网站的相关服务。本网站中所记载的产品(以下称“产品”)及相关销售服务及售后服务(以下合称“服务”)应当按照本协议执行。</span
          ><br />
          <span
            >2.1.3用户可以通过本网站，按照本协议的规定，根据相关条件(包括且不限于本网站所记载的型号、规格、价格、交货期、订购条件、使用上的注意事项等等)发现产品。但是，由于包括且不限于产品的性质、盘雷（www.panlei.cn）的情况、外加工方、产品的生产方、相关供应商或用户自身延迟确认、延迟付款等等因素，产品或/和本网站以及相关服务中的一部分会在没有事先通知的情况下发生调整或者从本网站中删除。本网站所提供的所有信息仅供参考；尤其提醒用户注意的是，本网站中的价格一般不包含现场服务费，具体请咨询本网站客户服务部予以核实；本网站中的价格均不包含运费及与运输相关的费用，且仅为参考价格，价格不仅会因为受到前述各项因素的影响而有所变动，亦会因为受到包括且不限于用户的所在地、采购数量、付款方式以及运输方式、原材料变动、市场因素、供需关系变化等因素的影响还有所变动，产品或/和服务的价格应以盘雷（www.panlei.cn）的最终确认为准。</span
          ><br />
          <span
            >2.1.4本网站相关服务的可提供范围以盘雷（www.panlei.cn）在本网站中公布的范围以及盘雷（www.panlei.cn）的其他说明为准，盘雷（www.panlei.cn）将不提供超出范围的服务，由于包括且不限于产品的性质、盘雷（www.panlei.cn）的情况、外加工方、产品的生产方、相关供应商或用户自身延迟确认、延迟付款等等因素，相关服务的范围会在没有事先通知的情况下进行调整。</span
          ><br />
          <span class="wen">三、软件的更新</span><br />
          <span
            >3.1 为了改善用户体验、完善服务内容，
            盘雷（www.panlei.cn）将不断努力开发新的服务，并为你不时提供软件更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）。</span
          ><br />
          <span
            >3.2 为了保证本平台及服务的安全性和功能的一致性，
            盘雷（www.panlei.cn）有权不经向你特别通知而对软件进行更新，或者对软件的部分功能效果进行改变或限制。</span
          ><br />
          <span
            >3.3 本平台新版本发布后，旧版本的软件可能无法使用。
            盘雷（www.panlei.cn）不保证旧版本平台继续可用及相应的客户服务，请你随时核对并下载最新版本。</span
          ><br />
          <span class="wen">四、用户个人信息保护</span><br />
          <span
            >4.1 保护用户个人信息是 盘雷（www.panlei.cn）的一项基本原则，
            盘雷（www.panlei.cn）将会采取合理的措施保护用户的个人信息。除法律法规规定的情形外，在用户许可操作下
            盘雷（www.panlei.cn）才会向第三方公开、透露用户个人信息。如包括但不限用户询价，报价，采购，合同，交易
            等所有有关平台操作。
            用户可以通过删除信息、关闭设备功能、在本网站或软件中进行设置等方式改变用户授权我们继续收集用户信息的范围或撤回授权；可以通过注销账户的方式，终止我们继续收集您用户信息的全部授权。用户理解，每个业务功能需要一些基本的用户信息才能得以实现，用户撤回同意或授权后，可能无法继续获得所对应的服务，也不再处理您相应的用户信息。</span
          ><br />
          <span
            >4.2
            你在注册帐号或使用本服务的过程中，需要提供一些必要的信息，例如：为向你提供帐号注册服务或进行用户身份识别，需要你填写手机号码；附近的人功能需要你同意使用你所在的地理位置信息；手机通讯录匹配功能需要你授权访问手机通讯录等。若国家法律法规或政策有特殊规定的，你需要提供真实的身份信息。若你提供的信息不完整，则无法使用本服务或在使用过程中受到限制。</span
          ><br />
          <span
            >4.3
            一般情况下，你可随时浏览、修改自己提交的信息，但出于安全性和身份识别（如号码申诉服务）的考虑，你可能无法修改注册时提供的初始注册信息及其他验证信息。若你通过已有
            平台帐号辅助注册新 平台帐号， 盘雷（www.panlei.cn）将视为新
            平台帐号与前述“已有 平台帐号”的初始注册手机号码相同。</span
          ><br />
          <span
            >4.4
            盘雷（www.panlei.cn）将运用各种安全技术和程序建立完善的管理制度来保护你的个人信息，以免遭受未经授权的访问、使用或披露</span
          ><br />
          <span
            >4.5
            用户可以通过联系本网站客服申请注销账户，注销账户应提交书面申请及必要的身份证明文件。账户注销后，本网站将停止为用户提供产品或服务，且除法律法规另有规定外，我们将删除用户的信息。</span
          ><br />
          <span
            >4.6
            你注册成功后，盘雷（www.panlei.cn）将给予每个用户一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。</span
          ><br />
          <span class="wen">五、主权利义务条款</span><br />
          <span>5.1 帐号使用规范</span><br />
          <span
            >5.1.1 你在使用本服务前需要注册一个 平台帐号。
            平台帐号可通过手机号码进行注册或者通过已有的
            平台帐号辅助注册,并可在注册成功后绑定微信帐号。如你使用微信帐号进行绑定，请你使用未与
            平台公众平台帐号绑定的微信帐号。
            盘雷（www.panlei.cn）有权约定通过已有的 平台帐号辅助注册新
            平台帐号需满足的前提条件、可注册的数量等相关事项，具体以产品页面显示为准。</span
          ><br />
          <span
            >盘雷（www.panlei.cn）有权根据用户需求或产品需要对帐号注册和绑定的方式进行变更。无论你通过本协议约定的何种方式注册
            平台帐号，关于你使用帐号的具体规则，请遵守使用协议以及
            盘雷（www.panlei.cn）为此发布的专项规则。</span
          ><br />
          <span
            >5.1.2 平台帐号的所有权归
            盘雷（www.panlei.cn）公司所有，用户完成申请注册手续后，仅获得
            平台帐号的使用权，且该使用权仅属于初始申请注册人。同时，初始申请注册人不得赠与、借用、租用、转让或售卖
            平台帐号或者以其他方式许可非初始申请注册人使用
            平台帐号。非初始申请注册人不得通过受赠、继承、承租、受让或者其他任何方式使用
            平台帐号。</span
          ><br />
          <span
            >5.1.3
            用户有责任妥善保管注册帐户信息及帐户密码的安全，用户需要对注册帐户以及密码下的行为承担法律责任。用户同意在任何情况下不向他人透露帐户及密码信息。当在你怀疑他人在使用你的帐号时，你应立即通知
            盘雷（www.panlei.cn）公司。若你通过已有 平台帐号辅助注册新
            平台帐号，
            盘雷（www.panlei.cn）出于保障你的帐号安全的目的，可能会要求你用前述“已有
            平台帐号”进行相关辅助操作，包括但不限于辅助登录、找回密码、冻结帐号、提交申诉等。若你已将新
            平台帐号与前述“已有 平台帐号”绑定不同的手机号码，前述“已有
            平台帐号”将失去为新
            平台帐号实施相关辅助操作的能力，且该行为不可逆转，你应审慎操作。</span
          ><br />
          <span
            >5.1.4 用户在使用手机号码注册 平台帐号时，系统将为你自动匹配
            平台号，你可以对
            平台号进行设置，也可根据相关功能页面的指引、在满足相应条件的前提下对
            平台号进行修改。</span
          ><br />
          <span
            >5.1.5
            你在使用本平台客户端某一特定服务或功能时，该服务或功能可能会要求你基于
            平台帐号创建或注册新的帐号（以下简称“功能帐号”），你需依据具体要求进行操作。如无
            盘雷（www.panlei.cn）书面说明或者许可，前述帐号的所有权亦归
            盘雷（www.panlei.cn）公司所有，用户完成创建或注册操作后，仅获得该帐号的使用权，且该使用权同样仅属于初始申请注册人，初始申请注册人不得赠与、借用、租用、转让或售卖该帐号或者以其他方式许可非初始申请注册人使用该帐号，非初始申请注册人不得通过受赠、继承、承租、受让或者其他任何方式使用该帐号。</span
          ><br />
          <span
            >5.1.6 用户注册或创建 平台帐号或功能帐号后如果长期不登录该帐号，
            盘雷（www.panlei.cn）有权回收该帐号，以免造成资源浪费，由此带来的任何损失均由用户自行承担。</span
          ><br />
          <span>7.2 用户注意事项</span><br />
          <span
            >5.2.1
            你理解并同意：为了向你提供有效的服务，本平台会利用你终端设备的处理器和带宽等资源。本平台使用过程中可能产生数据流量的费用，用户需自行向运营商了解相关资费信息，并自行承担相关费用。</span
          ><br />
          <span
            >5.2.2
            你理解并同意：本平台的某些功能可能会让第三方知晓用户的信息，例如：用户的
            公司名称、用户自己名字、
            平台号或手机号等可公开的个人资料；询价以及报价的内容和互动信息等已公开的信息；使用手机号码注册
            平台帐号或手机号码与 平台帐号关联的用户，
            平台帐号可以被通讯录存有该号码的好友查询；用户关注部分类型
            平台公众帐号后将成为公众帐号关注用户，相关公众帐号可以根据其功能权限获取公司名称、用户自己名字、
            平台号或手机号等可公开的个人资料，并可通过
            平台公众平台发送消息与关注用户进行互动。供第三方查阅。用户选择
            平台与其他软件或硬件信息互通后，其他软件或硬件的提供方可以获取用户在
            平台主动公开或传输的相关信息，用户在选择前应充分了解其他软件或硬件的产品功能及信息保护策略。</span
          ><br />
          <span
            >5.2.3
            你在使用本平台某一特定服务时，该服务可能会另有单独的协议、相关业务规则等（以下统称为“单独协议”），你在使用该项服务前请阅读并同意相关的单独协议；你使用前述特定服务，即视为你接受相关单独协议。例如：如果你需要使用
            平台支付相关服务（包括但不限于公司转帐，零钱、
            平台红包、转账、刷卡、付款、收款等支付服务）时，需阅读并遵守《
            平台支付用户服务协议》 。</span
          ><br />
          <span
            >5.2.4 你理解并同意
            盘雷（www.panlei.cn）将会尽其商业上的合理努力保障你在本平台及服务中的数据存储安全，但是，
            盘雷（www.panlei.cn）并不能就此提供完全保证，包括但不限于以下情形：</span
          ><br />
          <span
            >5.2.4.1
            盘雷（www.panlei.cn）不对你在本平台及服务中相关数据的删除或储存失败负责；</span
          ><br />
          <span
            >5.2.4.2
            盘雷（www.panlei.cn）有权根据实际情况自行决定单个用户在本平台及服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。你可根据自己的需要自行备份本平台及服务中的相关数据；</span
          ><br />
          <span
            >5.2.4.3 如果你停止使用本平台及服务或服务被终止或取消，
            盘雷（www.panlei.cn）可以从服务器上永久地删除你的数据。服务停止、终止或取消后，
            盘雷（www.panlei.cn）没有义务向你返还任何数据。</span
          ><br />
          <span
            >5.2.5 用户在使用本平台及服务时，须自行承担如下来自
            盘雷（www.panlei.cn）不可掌控的风险内容，包括但不限于：</span
          ><br />
          <span
            >5.2.5.1 由于不可抗拒因素可能引起的个人信息丢失、泄漏等风险；</span
          ><br />
          <span
            >5.2.5.2
            用户必须选择与所安装终端设备相匹配的软件版本，否则，由于软件与终端设备型号不相匹配所导致的任何问题或损害，均由用户自行承担；</span
          ><br />
          <span
            >5.2.5.3
            用户在使用本平台访问第三方网站时，因第三方网站及相关内容所可能导致的风险，由用户自行承担；</span
          ><br />
          <span
            >5.2.5.4
            用户发布的内容被他人转发、分享，因此等传播可能带来的风险和责任；</span
          ><br />
          <span
            >5.2.5.5 由于无线网络信号不稳定、无线网络带宽小等原因，所引起的
            盘雷（www.panlei.cn）
            平台登录失败、资料同步不完整、页面打开速度慢等风险。</span
          ><br />
          <span
            >5.2.5.6
            你理解并同意：在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、风暴、瘟疫等以及社会事件如战争、动乱、政府行为等。</span
          ><br />
          <span>5.3 第三方产品和服务</span><br />
          <span
            >5.3.1
            你在使用本平台第三方提供的产品或服务时，除遵守本协议约定外，还应遵守第三方的用户协议。
            盘雷（www.panlei.cn）和第三方对可能出现的纠纷在法律规定和约定的范围内各自承担责任。</span
          ><br />
          <span
            >5.3.2 因用户使用本平台或要求
            盘雷（www.panlei.cn）提供特定服务时，本平台可能会调用第三方系统或者通过第三方支持用户的使用或访问，使用或访问的结果由该第三方提供（包括但不限于第三方通过
            平台公众帐号提供的服务，或通过开放平台接入的内容等），
            盘雷（www.panlei.cn）不保证通过第三方提供服务及内容的安全性、准确性、有效性及其他不确定的风险，由此若引发的任何争议及损害，与
            盘雷（www.panlei.cn）无关，
            盘雷（www.panlei.cn）不承担任何责任。</span
          ><br />
          <span
            >5.3.3
            第三方服务提供者可以通过本平台向你提供产品或者服务，比如你可以关注第三方注册的公众帐号、使用第三方开发的小程序、接受第三方通过公众帐号（包括小程序）提供的服务及产品，或者通过
            平台授权登录能力登录并使用其他互联网服务。在此过程中，第三方服务提供者可能会收集、使用和存储你的相关数据或信息，
            盘雷（www.panlei.cn）通过与第三方服务提供者签署的有关协议、
            平台相关规范以及合理的产品流程设计，严格要求第三方服务提供者获取你的任何数据均应遵守相关法律法规的规定，必须事先获得你的明确同意，采取必要的数据保护措施，且仅为产品或服务之目的合理使用你的相关数据，向你提供修改或删除自己数据的方式，并在停止服务时必须删除全部数据等，尽最大可能保障你的数据和隐私不受侵害。你在接受或使用第三方产品或服务前应充分了解第三方产品或服务的条款及政策。如果你发现有第三方服务提供者存有相关违法违规行为，可以向
            盘雷（www.panlei.cn）投诉，
            盘雷（www.panlei.cn）将查实后予以处理。</span
          ><br />
          <span
            >5.3.4 你理解并同意，
            盘雷（www.panlei.cn）有权决定将本平台作商业用途，包括但不限于开发、使用本平台的部分服务为第三方作推广等，
            盘雷（www.panlei.cn）承诺在推广过程中严格按照本协议约定保护你的个人信息，同时你亦可以根据系统设置选择屏蔽、拒绝接收相关推广信息。</span
          ><br />
          <span class="wen">六、用户行为规范</span><br />
          <span>6.1 信息内容规范</span><br />
          <span
            >6.1.1
            本条所述信息内容是指用户使用本平台及服务过程中所制作、复制、发布、传播的任何内容，包括但不限于
            平台帐号或功能帐号的头像、名字、用户说明、简介等注册信息，或发送的文字、语音、图片、视频、表情等信息、朋友圈图文、视频动态、视频号内容和相关链接页面，以及其他使用
            平台帐号或功能帐号或本平台及服务所产生的内容。</span
          ><br />
          <span
            >6.1.2 你理解并同意，
            平台一直致力于为用户提供文明健康、规范有序的网络环境，你不得利用
            平台帐号或功能帐号或本平台及服务制作、复制、发布、传播如下干扰
            平台正常运营，以及侵犯其他用户或第三方合法权益的内容，包括但不限于：</span
          ><br />
          <span>6.1.2.1 发布、传送、传播、储存违反国家法律法规规定的内容：</span
          ><br />
          <span>（1）违反宪法确定的基本原则的；</span><br />
          <span
            >（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</span
          ><br />
          <span>（3）损害国家荣誉和利益的；</span><br />
          <span>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</span><br />
          <span>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</span><br />
          <span>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</span><br />
          <span>（7）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</span
          ><br />
          <span>（8）侮辱或者诽谤他人，侵害他人合法权益的；</span><br />
          <span>（9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；</span
          ><br />
          <span>（10）以非法民间组织名义活动的；</span><br />
          <span
            >（11）不符合《即时通信工具公众信息服务发展管理暂行规定》及遵守法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等“七条底线”要求的；</span
          ><br />
          <span>（12）含有法律、行政法规禁止的其他内容的。</span><br />
          <span
            >6.1.2.2
            发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；</span
          ><br />
          <span>6.1.2.3 涉及他人隐私、个人信息或资料的；</span><br />
          <span
            >8.1.2.4
            发表、传送、传播骚扰、广告信息、过度营销信息及垃圾信息或含有任何性或性暗示的；</span
          ><br />
          <span
            >6.1.2.5 其他违反法律法规、政策及公序良俗、社会公德或干扰
            平台正常运营和侵犯其他用户或第三方合法权益内容的信息。</span
          ><br />
          <span
            >6.1.2.6用户不得将在本网站注册获得的账户借给他人使用，否则用户应承担由此产生的全部责任，并与实际使用人承担连带责任。</span
          ><br />
          <span
            >6.1.2.7用户享有检举权，用户在使用本网站服务过程中若发现违法、违规、侵权或其他任何违反本协议的行为，有权通过专门的客服途径向盘雷（www.panlei.cn）或本网站进行检举，盘雷（www.panlei.cn）或本网站将通过对检举内容的核实采取相应的措施。用户保证其检举事项的真实性、公正性，同时尊重盘雷（www.panlei.cn）及本网站对被检举事项的处置措施；被检举用户有权通过专门的客服途径进行辩解，但不影响盘雷（www.panlei.cn）或本网站先行采取相应措施，盘雷（www.panlei.cn）或本网站在接纳被检举用户的辩解后，将恢复对其的服务但盘雷（www.panlei.cn）或本网站不对先行采取相应措施承担任何责任。本网站将对用户检举进行保密。</span
          ><br />
          <span
            >6.1.2.8用户违反本条本协议，本网站有权视情节的严重采取警告、限期改正、暂停服务、解除合同、禁止交易、封闭账户、永久禁止注册等措施，给第三方、盘雷（www.panlei.cn）或本网站造成损失的，盘雷（www.panlei.cn）或本网站有权采取一切合法的诉讼与非诉手段进行索赔；用户行为构成犯罪的，盘雷（www.panlei.cn）或本网站有权通知和协助国家主管部门进行查处。</span
          ><br />
          <span
            >6.1.2.9本网站及盘雷（www.panlei.cn）保有删除站内各类不符合法律政策或不真实的信息内容而无须通知用户的权利。若用户未遵守以上规定的，本网站或盘雷（www.panlei.cn）有权作出独立判断并采取暂停或关闭用户帐号等措施。用户须对自己在网上的言论和行为承担法律责任。</span
          ><br />
          <span>6.2 软件使用规范</span><br />
          <span
            >6.2.1 除非法律允许或
            盘雷（www.panlei.cn）书面许可，你使用本平台过程中不得从事下列行为：</span
          ><br />
          <span>6.2.1.1 删除本平台及其副本上关于著作权的信息；</span><br />
          <span
            >6.2.1.2
            对本平台进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本平台的源代码；</span
          ><br />
          <span
            >6.2.1.3 对
            盘雷（www.panlei.cn）拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；</span
          ><br />
          <span
            >6.2.1.4
            对本平台或者本平台运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本平台运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非
            盘雷（www.panlei.cn）经授权的第三方工具/服务接入本平台和相关系统；</span
          ><br />
          <span
            >6.2.1.5
            通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；</span
          ><br />
          <span
            >6.2.1.6 通过非
            盘雷（www.panlei.cn）开发、授权的第三方软件、插件、外挂、系统，登录或使用
            盘雷（www.panlei.cn）软件及服务，或制作、发布、传播上述工具；</span
          ><br />
          <span
            >6.2.1.7
            自行或者授权他人、第三方软件对本平台及其组件、模块、数据进行干扰；</span
          ><br />
          <span>6.2.1.8 其他未经 盘雷（www.panlei.cn）明示授权的行为。</span
          ><br />
          <span
            >6.2.2 你理解并同意，基于用户体验、
            平台或相关服务平台运营安全、平台规则要求及健康发展等综合因素，
            盘雷（www.panlei.cn）有权选择提供服务的对象，有权决定功能设置，有权决定功能开放、数据接口和相关数据披露的对象和范围。针对以下情形，有权视具体情况中止或终止提供本服务，包括但不限于：</span
          ><br />
          <span>6.2.2.1 违反法律法规或本协议规定的；</span><br />
          <span>6.2.2.2 影响服务体验的；</span><br />
          <span>6.2.2.3 存在安全隐患的；</span><br />
          <span
            >6.2.2.4 与
            平台或其服务平台已有主要功能或功能组件相似、相同，或可实现上述功能或功能组件的主要效用的；</span
          ><br />
          <span
            >6.2.2.5 界面、风格、功能、描述或使用者体验与
            平台或其服务平台类似，可能造成
            平台用户认为其所使用的功能或服务来源于 盘雷（www.panlei.cn）或经
            盘雷（www.panlei.cn）授权的；</span
          ><br />
          <span
            >6.2.2.6 违背 平台或其服务平台运营原则，或不符合
            盘雷（www.panlei.cn）其他管理要求的。</span
          ><br />
          <span>6.3 服务运营规范</span><br />
          <span
            >除非法律允许或
            盘雷（www.panlei.cn）书面许可，你使用本服务过程中不得从事下列行为：</span
          ><br />
          <span>6.3.1 提交、发布虚假信息，或冒充、利用他人名义的；</span><br />
          <span>6.3.2 诱导其他用户点击链接页面或分享信息的；</span><br />
          <span>6.3.3 虚构事实、隐瞒真相以误导、欺骗他人的；</span><br />
          <span
            >6.3.4
            侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；</span
          ><br />
          <span
            >6.3.5 未经 盘雷（www.panlei.cn）书面许可利用
            平台帐号、功能帐号和任何功能，以及第三方运营平台进行推广或互相推广的；</span
          ><br />
          <span
            >6.3.6 利用
            平台帐号或功能帐号或本平台及服务从事任何违法犯罪活动的；</span
          ><br />
          <span
            >6.3.7
            制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；</span
          ><br />
          <span
            >6.3.8
            其他违反法律法规规定、侵犯其他用户合法权益、干扰产品正常运营或
            盘雷（www.panlei.cn）未明示授权的行为。</span
          ><br />
          <span
            >为了更好地保障用户合法权益及良好的用户体验，
            盘雷（www.panlei.cn）针对 平台帐号制定了《
            平台个人帐号使用规范》，该使用规范与本协议一并生效，请你认真阅读并予以遵守。</span
          ><br />
          <span>6.4 对自己行为负责</span><br />
          <span
            >你充分了解并同意，你必须为自己注册帐号下的一切行为负责，包括你所发表的任何内容、评论及点赞、推荐等行为，以及由此产生的任何后果。你应对本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。
            盘雷（www.panlei.cn）无法且不会对因前述风险而导致的任何损失或损害承担责任。</span
          ><br />
          <span>6.5 违约处理</span><br />
          <span
            >6.5.1 如果
            盘雷（www.panlei.cn）发现或收到他人举报或投诉用户违反本协议约定的，
            盘雷（www.panlei.cn）有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规主体相关帐号处以包括但不限于警告、限制或禁止使用部分或全部功能、帐号封禁直至注销的处罚，并公告处理结果。</span
          ><br />
          <span
            >6.5.2 你理解并同意，
            盘雷（www.panlei.cn）有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。</span
          ><br />
          <span
            >6.5.3
            你理解并同意，因你违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，你应当独立承担责任；
            盘雷（www.panlei.cn）因此遭受损失的，你也应当一并赔偿。</span
          ><br />
          <span class="wen">七、知识产权声明</span><br />
          <span
            >7.1
            盘雷（www.panlei.cn）是本平台的知识产权权利人。本平台的一切著作权、商标权、专利权、商业秘密等知识产权，以及与本平台相关的所有信息内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国法律法规和相应的国际条约保护，
            盘雷（www.panlei.cn）享有上述知识产权，但相关权利人依照法律规定应享有的权利除外。</span
          ><br />
          <span
            >7.2 未经
            盘雷（www.panlei.cn）或相关权利人书面同意，你不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。</span
          ><br />
          <span class="wen">八、终端安全责任</span><br />
          <span
            >8.1
            你理解并同意，本平台同大多数互联网软件一样，可能会受多种因素影响，包括但不限于用户原因、网络服务质量、社会环境等；也可能会受各种安全问题的侵扰，包括但不限于他人非法利用用户资料，进行现实中的骚扰；用户下载安装的其他软件或访问的其他网站中可能含有病毒、木马程序或其他恶意程序，威胁你的终端设备信息和数据安全，继而影响本平台的正常使用等。因此，你应加强信息安全及个人信息的保护意识，注意密码保护，以免遭受损失。</span
          ><br />
          <span
            >8.2 你不得制作、发布、使用、传播用于窃取
            平台帐号、功能帐号及他人个人信息、财产的恶意程序。</span
          ><br />
          <span
            >8.3 维护软件安全与正常使用是 盘雷（www.panlei.cn）和你的共同责任，
            盘雷（www.panlei.cn）将按照行业标准合理审慎地采取必要技术措施保护你的终端设备信息和数据安全，但是你承认和同意
            盘雷（www.panlei.cn）并不能就此提供完全保证。</span
          ><br />
          <span
            >8.4
            在任何情况下，你不应轻信借款、索要密码或其他涉及财产的网络信息。涉及财产操作的，请一定先核实对方身份，并请经常留意
            盘雷（www.panlei.cn）有关防范诈骗犯罪的提示。</span
          ><br />
          <span>九、第三方软件或技术</span><br />
          <span
            >9.1
            本平台可能会使用第三方软件或技术（包括本平台可能使用的开源代码和公共领域代码等，下同），这种使用已经获得合法授权。</span
          ><br />
          <span
            >9.2 本平台如果使用了第三方的软件或技术，
            盘雷（www.panlei.cn）将按照相关法规或约定，对相关的协议或其他文件，可能通过本协议附件、在本平台安装包特定文件夹中打包、或通过开源软件页面等形式进行展示，它们可能会以“软件使用许可协议”、“授权协议”、“开源代码许可证”或其他形式来表达。前述通过各种形式展现的相关协议、其他文件及网页，均是本协议不可分割的组成部分，与本协议具有同等的法律效力，你应当遵守这些要求。 如果你没有遵守这些要求，该第三方或者国家机关可能会对你提起诉讼、罚款或采取其他制裁措施，并要求
            盘雷（www.panlei.cn）给予协助，你应当自行承担法律责任。</span
          ><br />
          <span
            >9.3
            如因本平台使用的第三方软件或技术引发的任何纠纷，应由该第三方负责解决，
            盘雷（www.panlei.cn）不承担任何责任。
            盘雷（www.panlei.cn）不对第三方软件或技术提供客服支持，若你需要获取支持，请与第三方联系。</span
          ><br />
          <span class="wen">十、其他</span><br />
          <span
            >10.1 你使用本平台即视为你已阅读并同意受本协议的约束。
            盘雷（www.panlei.cn）有权在必要时修改本协议条款。你可以在本平台的最新版本中查阅相关协议条款。本协议条款变更后，如果你继续使用本平台，即视为你已接受修改后的协议。如果你不接受修改后的协议，应当停止使用本平台。</span
          ><br />
          <span>10.2 本协议签订地为中华人民共和国四川省武侯区。</span><br />
          <span
            >10.3
            本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。</span
          ><br />
          <span>
            10.4 若你和
            盘雷（www.panlei.cn）之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，你同意将纠纷或争议提交成都市武侯区人民法院管辖。</span
          ><br />
          <span
            >10.5
            本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。</span
          ><br />
          <span>
            10.6
            本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。</span
          ><br />
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="dialogVisible = false">取消</el-button>
              <el-button type="primary" @click="dialogVisible = false"
                >同意</el-button
              >
            </span>
          </template>
        </el-dialog>
      </div>
      <el-form
        label-width="160px"
        class="info-form"
        :model="eInfo"
        ref="eform"
        :rules="eRules"
        v-if="registerInfo.enterpriseName"
      >
        <div class="e">
          <div class="e-right">
            <el-form-item label="企业状态">
              <el-input
                v-model="eInfo.enterpriseOcrStatus"
                placeholder="请输入"
              />
            </el-form-item>
            <el-form-item label="企业注册号">
              <el-input
                v-model="eInfo.enterpriseRegisteredCode"
                placeholder="请输入"
              />
            </el-form-item>
            <el-form-item label="企业统一社会信用编码">
              <el-input v-model="eInfo.socialCreditCode" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="企业所在地国家行政编码">
              <el-input
                v-model="eInfo.enterpriseNationalCode"
                placeholder="请输入"
              />
            </el-form-item>
            <el-form-item label="企业法人">
              <el-input v-model="eInfo.legalPersonName" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="企业注册资本">
              <el-input
                v-model="eInfo.registeredCapital"
                placeholder="请输入"
              />
            </el-form-item>
            <el-form-item label="企业注册时间">
              <el-input v-model="eInfo.establishedTime" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="企业类型">
              <el-input
                v-model="eInfo.enterpriseOcrType"
                placeholder="请输入"
              />
            </el-form-item>
            <el-form-item label="企业营业期限开始时间">
              <el-input
                v-model="eInfo.startBusinessTime"
                placeholder="请输入"
              />
            </el-form-item>
          </div>
          <div class="e-right">
            <el-form-item label="企业营业期限结束时间">
              <el-input v-model="eInfo.endBusinessTime" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="企业注册地址">
              <el-input v-model="eInfo.enterpriseAddr" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="请选择企业归属地">
              <el-cascader
                v-model="shipArea"
                :options="areaInfo"
                size="small"
                :props="{ expandTrigger: 'hover' }"
                style="width: 100%"
              >
              </el-cascader>
              <!-- <el-input v-model="eInfo.enterpriseAddr" placeholder="请输入" /> -->
            </el-form-item>
            <el-form-item label="企业税务登记号">
              <el-input
                v-model="eInfo.taxRegisteredCertificate"
                placeholder="请输入"
              />
            </el-form-item>
            <el-form-item label="企业组织机构代号">
              <el-input v-model="eInfo.organizationCode" placeholder="请输入" />
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <div class="r-footer">
      <el-button type="primary" size="mini" @click="registerORoffer">{{
        $route.query.uid ? "注册并登录" : "注册并报价"
      }}</el-button>
      <el-button
        type="primary"
        style="margin-left: 40px"
        size="mini"
        @click="goLogin"
        v-if="$route.query.uid"
        >去登陆</el-button
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { ElMessage } from "element-plus";
import { throttle } from "@/common/js/common.js";
import { JSEncrypt } from "jsencrypt-rsa";
import login from "@/module/loginModule/viewPage/login";
import uploadFile from "@/components/publicComponent/uploadFile.vue";
import { handleTreeData } from "@/common/js/common.js";
import area from "@/common/json/area.json";

export default {
  data() {
    return {
      shipArea: [],
      areaInfo: handleTreeData(area, "name", "name"), //地区信息

      eInfo: {
        //识别的企业信息
        enterpriseOcrStatus: "",
        endBusinessTime: "",
        enterpriseAddr: "",
        enterpriseCity: "",
        enterpriseDistrict: "",
        enterpriseName: "",
        enterpriseProvince: "",
        enterpriseOcrType: "",
        establishedTime: "",
        legalPersonName: "",
        organizationCode: "",
        registeredCapital: "",
        socialCreditCode: "",
        startBusinessTime: "",
        taxRegisteredCertificate: "",
        enterpriseRegisteredCode: "",
        enterpriseNationalCode: "",
      },
      registerInfo: {
        realName: "", //姓名
        mobilePhone: "", //手机号
        enterpriseId: "", //企业id
        verificationCode: "", //验证码
        passWord: "", //密码
        enterpriseName: "", //企业名称
        copyEnterpriseName: "",
        fileIds: [], //添加企业的营业执照
        belongType: "",
      },
      list: [], //企业列表
      timer: null, //定时器
      second: 0, //倒计时秒
      dialogVisible: false,
    };
  },
  props: {
    info: Object,
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    enterpriseInfo: Object,
  },
  components: {
    uploadFile, //上传组件
  },
  computed: {
    ...mapState("loginModule", [
      "pubkey", //登录加密时的密钥
    ]),
    getCodeText() {
      return this.second === 0 ? "获取验证码" : `${this.second}s后重新获取`;
    },
    registerRules() {
      return {
        realName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobilePhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        verificationCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        passWord: [{ required: true, message: "请输入密码", trigger: "blur" }],
        fileIds: [
          {
            required: !!this.registerInfo.enterpriseName,
            message: "请上传营业执照",
            trigger: "change",
          },
        ],
        fChecked: [
          {
            required: true,
            message: "请接受条款",
            trigger: "blur",
          },
          {
            pattern: /true/,
            message: "请接受条款",
            trigger: ["blur"],
          },
        ],
      };
    },
  },
  methods: {
    ...mapActions("loginModule", [
      "uAddEnterprise", //添加企业
      "enterpriseList", //企业列表
      "smsCode", //获取验证码
      "uSearchEnterprise", //通过名称查询企业
      "register", //注册
      "searchEnterpriseById", // 根据分享人id查询企业信息
      "uAliYunOcr", //营业执照识别
    ]),
    ...mapActions("purchaseMudule", [
      "quoteCreate", //新增报价
    ]),
    ...mapMutations(["renewToken", "renewUserInfo"]),
    selectChange() {
      //选择时情况搜索值
      this.registerInfo.enterpriseName = "";
    },
    inputBlur() {
      //失去焦点检查企业名称是否已存在
      if (this.registerInfo.enterpriseName) {
        console.log(this.registerInfo.enterpriseName);
        this.registerInfo.enterpriseId = "";
        this.uSearchEnterprise(this.registerInfo.enterpriseName).then((res) => {
          let { code, data } = res.data;
          if (code === "0") {
            if (data && data.enterpriseId) {
              ElMessage({
                message: "该企业已被注册",
                type: "warning",
              });
            }
          }
        });
      }
    },
    goLogin() {
      //去登录
      this.$emit("changePage", "login");
    },
    getEnterpriseList() {
      //获取企业列表
      this.enterpriseList({ belongType: this.$route.query.belongType }).then(
        (res) => {
          let { code, data } = res.data;
          if (code === "0") {
            this.list = data || [];
          }
        }
      );
    },
    getCode() {
      //获取验证码
      if (this.registerInfo.mobilePhone) {
        this.smsCode(this.registerInfo.mobilePhone).then((res) => {
          let { code, data } = res.data;
          if (code === "0") {
            ElMessage({
              message: "发送成功",
              type: "success",
            });
            this.second = 60;
            this.timer =
              this.timer ||
              setInterval(() => {
                if (this.second > 0) {
                  this.second--;
                } else {
                  clearInterval(this.timer);
                  this.second = 0;
                  this.timer = null;
                }
              }, 1e3);
          }
        });
      } else {
        ElMessage({
          message: "请输入手机号",
          type: "warning",
        });
      }
    },
    registerORoffer() {
      //注册并报价
      let t = this;
      if (!t.registerInfo.enterpriseId && !t.registerInfo.enterpriseName) {
        return ElMessage({
          message: "请选择企业或者输入添加企业名称",
          type: "warning",
        });
      }
      t.$refs["loginFrom"].validate((valid) => {
        if (valid) {
          var data = JSON.parse(JSON.stringify(t.registerInfo));
          delete data.copyEnterpriseName;
          if (t.info) {
            data.shareEnterpriseId = t.info.buyerEnterpriseId;
            data.shareUserId = t.info.buyerId;
          } else {
            data.shareUserId = t.$route.query.uid;
          }
          data.passWord = this.encryption(data.passWord);
          data.shareType =
            this.info && this.info.buyerId
              ? "0"
              : this.$route.query.uid
              ? this.$route.query.belongType
              : undefined;
          if (data.enterpriseName) {
            data.enterpriseId = "";
            data.enterpriseRegisterVO = {
              ...t.eInfo,
              fileIds: data.fileIds,
              enterpriseName: data.enterpriseName,
            };
          } else {
            data.enterpriseRegisterVO = {
              enterpriseId: data.enterpriseId,
            };
          }
          delete data.fileIds;
          var open = data.enterpriseName ? false : true; //通行证
          if (!open) {
            t.$refs["eform"].validate((valid) => {
              open = valid;
            });
          }
          if (open) {
            data.enterpriseRegisterVO.enterpriseProvince = t.shipArea[0];
            data.enterpriseRegisterVO.enterpriseCity = t.shipArea[1];
            data.enterpriseRegisterVO.enterpriseDistrict = t.shipArea[2];
            t.register(data).then((res) => {
              let { code, data, msg } = res.data;
              if (code === "0" && data.token) {
                //登录成功
                t.renewToken(data.token); //更新token
                t.renewUserInfo(data);
                //保存操作信息（登录成功后）
                localStorage.setItem(
                  "loginSign",
                  JSON.stringify({
                    isLogin: t.isLogin ? "1" : "0",
                    isRememberPas: t.isRememberPas ? "1" : "0",
                  })
                );
                //保存账号密码
                localStorage.setItem(
                  "loginInfo",
                  JSON.stringify({
                    username: t.registerInfo.mobilePhone, //用户名
                    password: t.registerInfo.passWord, //密码
                  })
                );
                if (t.$route.query.uid) {
                  //注册登录成功 跳转页面
                  t.$router.push({
                    path: "/home/index",
                  });
                } else {
                  //报价
                  t.goOffer();
                }
              } else {
                ElMessage({
                  showClose: true,
                  message: msg || data.msg,
                  type: "error",
                });
              }
            });
          }
        }
      });
    },
    goOffer() {
      //报价
      this.quoteCreate({
        itemRequestVOList: this.data,
        quoteRequestVO: {
          inquiryId: this.info.id,
          buyerEnterpriseId: this.info.buyerEnterpriseId,
        },
      }).then((res) => {
        let { data, code } = res.data;
        if (code === "0") {
          ElMessage({
            type: "success",
            message: "报价成功",
          });
          this.$emit("bjSuccess");
          //跳转到我的报价页面
          this.$router.push({ path: "/mysale/customerOffer" });
        }
      });
    },
    encryption(val) {
      //加密密码
      // 实例化加密对象
      const encrypt = new JSEncrypt();
      // 设置加密公钥
      encrypt.setPublicKey(this.pubkey);
      // 加密结果
      const encrypted = encrypt.encrypt(val);
      // 返回加密结果
      return encrypted;
    },
    init() {
      var id = this.$route.query.uid
        ? this.$route.query.uid
        : this.info?.buyerId || "";
      if (id) {
        this.searchEnterpriseById(id)
          .then((res) => {
            let { data, code } = res.data;
            if (code === "0") {
              this.registerInfo.copyEnterpriseName = data.enterpriseName;
            }
          })
          .catch((e) => console.log(e));
      }
    },
    uploadSuccess(data) {
      //营业执照上传成功的回调
      this.uAliYunOcr({
        fileId: data.fileId,
      }).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          this.registerInfo.enterpriseName = data.enterpriseName;
          this.eInfo = data;

          this.shipArea = [
            data.enterpriseProvince,
            data.enterpriseCity,
            data.enterpriseDistrict,
          ];
        }
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
  created() {
    this.init();
    this.getEnterpriseList();
    //节流
    this.getCode = throttle(this.getCode);
    this.registerInfo.belongType = this.$route.query.belongType;
  },

  beforeUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
      this.second = 0;
      this.timer = null;
    }
  },
};
</script>

<style scoped lang="less">
.r-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  span {
    text-align: right;
    line-height: 40px;
    padding-right: 12px;
    box-sizing: border-box;
  }
}
.r-tips {
  color: #d84146;
  font-size: 12px;
  padding: 5px 0 20px 100px;
}
.r-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.e {
  display: flex;
  .e-right {
    //padding-left: 40px;
    //overflow: hidden;
    //text-overflow: ellipsis;
  }
}
/deep/ .el-form-item__label {
  white-space: nowrap;
}
/deep/ .el-input__inner,
/deep/ .el-input--suffix {
  width: 260px;
}
.dialog-footer button:first-child {
  margin-right: 10px;
}
// .dialog{
// 	padding: 20px;
// 	height: 500px;
// 	overflow-y: scroll;
// }
/deep/ .el-dialog {
  padding: 10px;
  .el-dialog__body {
    //border-top: 1px solid #dcdfe6;
    //border-bottom: 1px solid #dcdfe6;
    margin: 10px 0;
    max-height: 500px !important;
    min-height: 100px;
    overflow-y: scroll;
  }
  .wen {
    font-weight: bold;
  }
}
</style>
