
import homePage from '@/components/homePage/index.vue';
export default {
    path: '/saleMessage',
    component: homePage,
    redirect: '/saleMessage/index',
	meta:{title:"销售大厅"},
	children: [
        {
			path: "index",
			component:() => import('@/module/saleMessageModule/viewPage/index.vue'),
			meta:{title:"销售大厅"},
			closable: true,
			active: true,
		},
    ]
}
