import request from '@/common/js/request.js';
import api from '@/common/js/api.js';
import login from "@/module/loginModule/viewPage/login";
export default {
	namespaced: true, //区分模块
	state: {
		loginRules: { //登录验证
			username: [{
				required: true,
				message: "请输入账户/用户名",
				trigger: 'blur',
			}],
			password: [{
				required: true,
				message: "请输入密码",
				trigger: 'blur',
			}]
		},
		pubkey:`MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKB
		gQC/30MzjNPFAjXbcRDqvekLKYS9az9y6elBeNoiCztv
		U3Qi9918K9TqiHA4hXw59zSsbsVSGOFXJw15RVfWl2a0
		kiEQ9NVbfrcnWQEQ2ZHtmyL1Nrcv5rDLDHywpssxMY8k
		L0cSGKLteTK558sv/JwYdDcxQKZf42yvQruR7BLZ7QIDAQAB`, //登录加密时的公匙
	},
	getters: {

	},
	mutations: {

	},
	actions: {
		login(context,params) { //登录
			var data = JSON.parse(JSON.stringify(params));
			delete data.id;
			return request.post(api.login + (`?shareUserId=${params.id}&shareType=${params.shareType}`), params);
		},
		searchEnterprise(store, params) { // 根据名称查企业
			return request.post(api.searchEnterprise, params);
		},
		searchEnterpriseById(store, params) { // 根据分享人id查询企业信息
			return request.get(`${api.searchEnterpriseById}?shareUserId=${params}`,)
		},
		register(store, params){ // 注册
			return request.post(api.register, params);
		},
		smsCode(store, phone){ // 发送验证码
			return request.get(`${api.smsCode}/${phone}`)
		},
		smsCodeV(store, params){ // 校验验证码
			return request.post(api.smsCodeV + params);
		},
		uploadReq(store, params){ // 注册营业执照上传
			return request.post(api.uploadReq + `?fileModule=${params.type}`, params.form)
		},
		addEnterprise(store,params){ //新增企业
			return request.post(api.addEnterprise,params)
		},
		enterprisePage(store,params){ //企业列表
			return request.post(api.enterprisePage,params)
		},
		enterpriseList(store,params){ //企业列表
			console.log('params:',params)
			return request.post(`${api.enterpriseList}`,{}, {params})
		},
		shareInquiryShareInfo(store,id){ //分享查看询价单
			return request.get(`${api.shareInquiryShareInfo}/${id}`)
		},
		uAddEnterprise(store,params){ //新增企业
			return request.post(api.uAddEnterprise,params)
		},
		uSearchEnterprise(store,name){ //通过名称查询企业
			return request.post(`${api.uSearchEnterprise}?enterpriseName=${name}`)
		},
		uRetrievePassword(context,params){ //用户找回密码
			return request.post(api.uRetrievePassword + params)
		},
		uWechatLogin(context,code){ //用户扫码登录
			return request.get(`${api.uWechatLogin}?code=${code}`)
		},
		uWechatGetSessionKey(context,code){ //用户扫码登录
			return request.get(`/u/wechat/getSessionKey?code=${code}`)
		},
		/** 分享页面的用户扫码登录 */
		uShareWechatLogin(context,{code, id, belongType}){
			return request.get(`${api.uShareWechatLogin}?code=${code}&shareUserId=${id}&shareType=${belongType}`)
		},
		uAliYunOcr(context,params){ //营业执照识别
			return request.get(api.uAliYunOcr,{params})
		},
		uPhoneLogin(context,params){ //验证码登录
			return request.post(api.uPhoneLogin,params)
		}
	}
}
