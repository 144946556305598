<template>
  <div class="left-img">
    <img :src="leftImg" width="707" />
  </div>
  <div
    class="right"
    :style="{ height: pageType === 'register' ? '850px' : '670px' }"
  >
    <!--  二维码提示扫码   -->
    <img
      v-if="!isShowQr"
      :src="qrLogin"
      alt=""
      class="qrLogin"
      width="100"
      height="100"
      @click="isShowQr = true"
    />
    <img
      v-else
      :src="phoneImg"
      alt=""
      class="qrLogin"
      width="100"
      height="100"
      @click="isShowQr = false"
    />

    <!--  表单 登录  -->
    <div v-if="!isShowQr">
      <img
        :src="logo"
        class="logo"
        :style="{ marginBottom: pageType === 'register' ? '10px' : '26px' }"
      />
      <div
        class="title"
        :style="{ display: pageType === 'register' ? 'none' : '' }"
      >
        欢迎来到盘雷
      </div>
      <div style="position: relative; z-index: 10">
        <login-form
          v-show="pageType === 'login'"
          @changePage="changePage"
          :isSharePage="false"
        />
        <div style="padding: 0 100px" v-show="pageType === 'register'">
          <noAccountNumberForm
            @changePage="changePage"
          />
        </div>
        <forget-password
          @changePage="changePage"
          v-show="pageType === 'forgetPassword'"
        />
        <code-login
          @changePage="changePage"
          v-show="pageType === 'codeLogin'"
        />
      </div>
    </div>

    <!--  二维码扫码区域  -->
    <div v-else class="qrContainer">
      <wxlogin
        self_redirect="false"
        appid="wx75ee94f1e9d5bc07"
        scope="snsapi_login"
        :redirect_uri="loginSuccessUrl"
      ></wxlogin>
    </div>

    <img class="bottomImg" src="@/assets/login/style-bottom.png" />
<!-- <chooseEID @confirm="confirmEID" @close="closeEID" @open="openEID" :parmas=""> </chooseEID> -->
  </div>
</template>

<script>
import loginForm from "../components/loginForm.vue"; //登录
import noAccountNumberForm from "../components/noAccountNumberForm.vue"; //注册
import forgetPassword from "../components/forgetPassword.vue"; //忘记密码
import codeLogin from "../components/codeLogin.vue"; //验证码登录
import loginMixin from "../mixins/loginMixin.js";
import { mapActions, mapMutations } from "vuex";
import { ElMessage } from "element-plus";
import wxlogin from "vue-wxlogin";
import Vue from "vue";
export default {
  data() {
    return {
      leftImg: require("@/assets/login/left-image.svg"),
      logo: require("@/assets/logo.jpg"),
      pageType: "login",
      qrLogin: require("../../../assets/qrLogin.png"),
      phoneImg: require("../../../assets/shouji.png"),
      isShowQr: false,
      loginSuccessUrl: encodeURIComponent("https://www.panlei.cn/login/index"),
 
    };
  },
  mixins: [loginMixin],
  components: {
    loginForm,
    noAccountNumberForm,
    forgetPassword,
    wxlogin,
    codeLogin,
  },
  methods: {
    ...mapActions("loginModule", [
      "uWechatLogin",
      "uWechatGetSessionKey",
      //扫码登录
    ]),
    ...mapMutations(["renewToken", "renewUserInfo"]),
    changePage(type) {
      this.pageType = type;
    },
    jumpPage() {
      this.$router.push({
        path: "/home/index",
      });
    },

    wlogin() {
      this.uWechatLogin(this.$route.query.code).then((res) => {
        let { code, data, msg } = res.data;
        if (code === "0" && data.token) {
          //登录成功
          this.renewToken(res.data.data.token); //更新token
          this.renewUserInfo(res.data.data);
          //保存操作信息（登录成功后）
          localStorage.setItem(
            "loginSign",
            JSON.stringify({
              isLogin: "0",
              isRememberPas: "0",
            })
          );

          this.dialogFormVisible = false;
          this.jumpPage(); //跳转到登录首页
        } else {
          ElMessage({
            showClose: true,
            message: msg || data.msg,
            type: "error",
          });
        }
      });
    },
  },
  created() {
    if (this.$route.query.uid) {
      this.pageType = "register";
    }
    if (this.$route.query.code) {
      this.wlogin()
      
      // this.uWechatGetSessionKey('011Jhm0w3vjFq03oMd2w3zhtNI1Jhm0Y').then((res) => {
      //   if(res.data?.data&&res.data.data.openid){

      //   }
      // });
    }
    console.log("this.$route.query:", this.$route.query);
  },
  beforeMount() {
    console.log("this.$route.query:", this.$route.query);
  },
  mounted() {
    console.log("this.$route.query:", this.$route.query);
  },
  setup(props, context) {},
  expose: ["increment"],
  emits: ["check"],
  // watch: {
  //   '$route.path': function (val) {
  //     console.log(val)
  //     // this.wxLoginRedirect()
  //   }
  // }
  watch: {
    $route: {
      handler(val, oldval) {
        console.log(val); //新路由信息
        console.log(oldval); //老路由信息
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style scoped lang="less">
.right {
  min-width: 620px;
  height: 670px;
  background: #fff;
  box-shadow: 0 0 20px rgb(0 0 0 / 16%);
  position: absolute;
  right: 40px;
  .logo {
    margin: 30px 0 26px 30px;
    width: 100px;
  }

  .title {
    width: 370px;
    margin: 0 auto;
    font-size: 30px;
    font-weight: bold;
    padding-bottom: 40px;
  }

  .bottomImg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  .qrLogin {
    position: absolute;
    right: 0;
    top: 0;
  }

  .qrContainer {
    transform: translate(25%, 25%);
  }
}
</style>
