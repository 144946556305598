import request from "@/common/js/request.js";
import api from "@/common/js/api.js";
export default {
  namespaced: true, //区分模块
  state: {},
  getters: {},
  mutations: {},
  actions: {
    userPassword(context, params) {
      //修改用户密码
      return request.put(api.userPassword, params);
    },
    userSetUserSetBatch(context, params) {
      //系统设置批量修改
      return request.post(api.userSetUserSetBatch, params);
    },
    userListUserSetByUserId(context, params) {
      //获取系统设置列表
      return request.get(api.userListUserSetByUserId, { params });
    },
    /** wyb 获取登录用户信息 */
    getUserInfo(content) {
      return request.get(api.getUser);
    },
    /** 解绑微信 */
    uBindCancelBind(content) {
      return request.get(api.uBindCancelBind);
    },
    /** 系统设置详情 */
    getDisplayTabListOfHome(c,params) {

      return request.get(api.tabListOfHome, {params });
    },
  },
};
