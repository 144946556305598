<template>
	<el-dialog
	  :model-value="dialogFormVisible"
	  width="600px"
	  center
	>


	<div style="padding: 15px; font-size: large; text-align: center;">
		选择您所属的公司
	</div>
	

	<div style="padding: 20px;">
		<el-form :model="form" >
		<el-form-item label="选择您所属的公司">
		  <el-select v-model="form.enterpriseId" placeholder="您所属的公司">
			<el-option :label="item.label" :key="item.value" :value="item.value" v-for="item in list" />
		  </el-select>
		</el-form-item>
	  </el-form>
	</div>
	  <template #footer>
		<span class="dialog-footer">
		  <el-button @click="close">取消登录</el-button>
		  <el-button type="primary" @click="confirm">
			确定
		  </el-button>
		</span>
	  </template>
	</el-dialog>
  </template>
  
  <script>
  import request from '@/common/js/request.js';
  
  export default {
	data() {
	  return {
		form: {
		  enterpriseId: "",
		},
		list:[]
	  };
	},
	props: {
	  dialogFormVisible: {
		type: Boolean,
		default: false,
	  },
	  parmas:Object
	},
  

	methods: {
	  close() {
		this.$emit("close", this.form);
	  },
	  getSelectData(){

		  request.get(`/u/user/enterprise/list`,{params:this.parmas}).then(res=>{

			  if(res.data?.data&&Array.isArray(res.data?.data)){
				this.list=res.data?.data?.map(ex=>({label:ex.enterpriseName,value:ex.id}))
				if(this.list.length===1){
			this.$emit("confirm",{enterpriseId:this.list[0]?.value});

				}
			  }
		  })
	  },
	  confirm() {
		if(this.form.enterpriseId){
			this.$emit("confirm", this.form);

		}
		//确认的点击事件
	  },
	},

	watch:{
		dialogFormVisible:{
			handler(v){
				if(v){
					this.getSelectData()
					this.form={}
				}
			}
		}
	}
  };
  </script>
  
  <style scoped lang="less">
  .a {
	padding: 20px 40px;
	.a-title {
	  font-weight: bold;
	  padding-bottom: 20px;
	}
	.a-item {
	  display: flex;
	  align-items: center;
	  padding-bottom: 10px;
	  span {
		width: 88px;
		text-align: right;
		margin-right: 20px;
	  }
	}
	.a-footer {
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  padding-top: 20px;
	}
  }
  </style>
  