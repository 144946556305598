<template>
	<el-form :label-position="labelPosition" label-width="100px" :model="obj" class="info-form"
		:rules="rules" ref="loginFrom">
		<el-form-item label="手机号" prop="phone">
			<el-input v-model="obj.phone" placeholder="请输入手机号" prefix-icon="el-icon-user" />
		</el-form-item>
		<div class="r-row">
			<el-form-item label="短信验证码" prop="code" style="flex: 1;">
				<el-input v-model="obj.code" placeholder="请输入" />
			</el-form-item>
			<el-button
				style="margin-top: 6px;margin-left: 10px;"
				type="primary"
				@click="getCode"
				:disabled="second !== 0"
				size="mini">{{getCodeText}}</el-button>
		</div>
		<div class="r-footer">
			<el-button type="success" size="mini" @click="returnLogin">账号密码登录</el-button>
			<el-button
				type="primary"
				style="margin-left: 40px;"
				size="mini"
				@click="goLoginInUser">确认登录</el-button>
		</div>
		<chooseEID
      @confirm="confirmEID"
      @close="
        () => {
          dialogEIDVisible = false;
        }
      "
      :dialogFormVisible="dialogEIDVisible"
      :parmas="chooseEIDParmas"
    >
    </chooseEID>
	</el-form>
</template>

<script>
	import { ElMessage, ElMessageBox } from 'element-plus'
	import { mapActions, mapState, mapMutations } from 'vuex'
	export default{
		data(){
			return{
				obj:{
					enterpriseId:'',
					phone:'',  //账号
					code:'',  //验证码
				},
				timer:null,  //定时器
				second:0, //倒计时秒
				rules:{
					phone:[
						{required: true,message: "请输入手机号",trigger: 'blur'}
					],
					code:[
						{required: true,message: "请输入验证码",trigger: 'blur'}
					],
				},
				dialogEIDVisible: false,
	  chooseEIDParmas:{},
			}
		},
		props:['info'],
		computed:{
			...mapState('loginModule',
				[
					'pubkey', //登录加密时的密钥
			]),
			getCodeText(){
				return this.second === 0 ? '获取验证码' : `${this.second}s后重新获取`;
			},
		},
		methods:{
			...mapActions('loginModule',[
				'smsCodeV',  //验证手机验证码
				'smsCode',  //发送验证码
				"uPhoneLogin" //登录请求
			]),
			...mapMutations(["renewToken", "renewUserInfo"]),
			...mapActions("purchaseMudule", [
				"quoteCreate" //新增报价
			]),
			...mapMutations('mysaleMudule', [
				'setOfferSuccessType',
			]),
			returnLogin(){ //返回登录
				this.$emit('changePage','login');
			},


			confirm(){

				this.$refs['loginFrom'].validate((valid) => {
					if(valid){
						this.uPhoneLogin({
							...this.obj,
							id: this.info ?
								this.info.buyerId :
								this.$route.query.uid ?
								this.$route.query.uid :
								null, //分享人id
							shareType: this.info && this.info.buyerId ?
								"0" :
								this.$route.query.uid ?
								"1" :
								undefined //分享的用户类型
						}).then(res => {
							let {
								code,
								data,
								msg
							} = res.data;
							if (code === "0" && data.token) {
								//登录成功
								this.renewToken(res.data.data.token); //更新token
								this.renewUserInfo(res.data.data);
								//保存操作信息（登录成功后）
								localStorage.setItem(
									"loginSign",
									JSON.stringify({
										isLogin: this.isLogin ? "1" : "0",
										isRememberPas: this.isRememberPas ? "1" : "0"
									})
								);
								//保存账号密码
								// localStorage.setItem("loginInfo", JSON.stringify(this.loginInfo));
								if (this.info) {
									//登录并报价
									if (data.userType === "0") {
										//报价
										this.goOffer();
									} else {
										ElMessageBox.confirm(
												"当前登录的账号是普通账号不能参与报价，是否跳转到首页?",
												"提示", {
													confirmButtonText: "确认",
													cancelButtonText: "取消",
													type: "warning"
												}
											)
											.then(() => {
												this.jumpPage(); //跳转到登录首页
											})
											.catch(() => {});
									}
								} else {
									//直接登录
									//跳转页面
									this.jumpPage();
								}
							} else {
								ElMessage({
									showClose: true,
									message: msg || data.msg,
									type: "error"
								});
							}
						})
					}
				})
			},

			openEID() {
      //打开选公司
      this.dialogEIDVisible = true;
    },

    confirmEID(v) {
      this.dialogEIDVisible = true;
	  this.obj.enterpriseId=v.enterpriseId
      this.confirm();
    },
    goLoginInUser() {
      this.$refs["loginFrom"].validate((valid) => {
        if (valid) {
          this.chooseEIDParmas.type = "USERNAME";
          this.chooseEIDParmas.value = this.obj.phone;
          this.openEID();
        }
      });
    },
			getCode(){ //获取验证码
				if(this.obj.phone){
					this.smsCode(this.obj.phone).then(res => {
						let {code,data} = res.data;
						if(code === '0'){
							ElMessage({
								message:'发送成功',
								type: 'success',
							})
							this.second = 60;
							this.timer = this.timer || setInterval(() => {
								if(this.second > 0){
									this.second--;
								}else{
									clearInterval(this.timer);
									this.second = 0;
									this.timer = null;
								}
							},1e3)
						}
					})
				}else{
					ElMessage({
						message:'请输入手机号',
						type: 'warning',
					})
				}
			},
			encryption(val) { //加密密码
				// 实例化加密对象
				const encrypt = new JSEncrypt()
				// 设置加密公钥
				encrypt.setPublicKey(this.pubkey)
				// 加密结果
				const encrypted = encrypt.encrypt(val)
				// 返回加密结果
				return encrypted
			},
			goOffer() {
				//报价
				this.quoteCreate({
					itemRequestVOList: this.data,
					quoteRequestVO: {
						inquiryId: this.info.id,
						buyerEnterpriseId: this.info.buyerEnterpriseId
					}
				}).then(res => {
					let {
						data,
						code
					} = res.data;
					if (code === "0") {
						ElMessage({
							type: "success",
							message: "报价成功"
						});
						this.$emit("bjSuccess");
						//跳转到我的报价页面
						this.$router.push({
							path: "/mysale/customerOffer"
						});
					}
				});
			},
			jumpPage() {
				this.setOfferSuccessType(true);
				this.$router.push({
					path: "/home/index"
				});
			},
		}
	}
</script>

<style scoped lang="less">
	.info-form {
		width: 470px;
		margin: 0 auto;
		/deep/ .el-form-item__content {
			margin-left: 0 !important;
		}
	}
	
	.l-item {
		display: flex;
		align-items: center;
		font-size: 14px;
		.l-item-tail {
			flex: 1;
			text-align: right;
			cursor: pointer;
		}
	}
	.r-row{
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		span{
			text-align: right;
			line-height: 40px;
			padding-right: 12px;
			box-sizing: border-box;
		}
	}
	.r-footer{
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
