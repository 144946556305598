<template>
  <el-form
    :label-position="labelPosition"
    label-width="100px"
    :model="loginInfo"
    class="info-form"
    :rules="loginRules"
    ref="loginFrom"
  >
    <!--  二维码提示扫码   -->
    <img
      v-if="!isShowQr && isSharePage"
      :src="qrLogin"
      alt=""
      class="qrLogin"
      width="100"
      height="100"
      @click="isShowQr = true"
    />
    <img
      v-if="isShowQr && isSharePage"
      :src="phoneImg"
      alt=""
      class="qrLogin"
      width="100"
      height="100"
      @click="isShowQr = false"
    />

    <div v-if="!isShowQr">
      <el-form-item label="" prop="username">
        <el-input
          v-model="loginInfo.username"
          placeholder="请输入账户/用户名"
          prefix-icon="el-icon-user"
        />
      </el-form-item>
      <el-form-item label="" prop="password">
        <el-input
          v-model="loginInfo.password"
          placeholder="请输入密码"
          prefix-icon="el-icon-lock"
          type="password"
        />
      </el-form-item>
      <div class="l-item">
        <el-checkbox v-model="isLogin" label="自动登录"></el-checkbox>
        <el-checkbox v-model="isRememberPas" label="记住密码"></el-checkbox>
        <div class="l-item-tail" @click="forgetPassword">忘记密码？</div>
      </div>
      <div class="l-item" style="padding-top: 20px;">
        <div class="l-item-tail" style="color: #5CACEE;" @click="codeLogin">
          验证码登录
        </div>
      </div>
      <el-form-item style="margin-top: 40px;">
        <el-button style="width: 100%;" type="primary" @click="goLoginInUser"
          >安全登录
        </el-button>
      </el-form-item>
      <el-button
        style="width: 100%;"
        text
        @click="auRegister"
        v-if="$route.query.uid"
        >注册账号
      </el-button>
    </div>

    <!--  二维码扫码区域  -->
    <div v-else class="qrContainer">
      <wxlogin
        self_redirect="false"
        appid="wx75ee94f1e9d5bc07"
        scope="snsapi_login"
        :state="getState"
        :redirect_uri="loginSuccessUrl"
      ></wxlogin>
    </div>

    <chooseEID
      @confirm="confirmEID"
      @close="
        () => {
          dialogEIDVisible = false;
        }
      "
      :dialogFormVisible="dialogEIDVisible"
      :parmas="chooseEIDParmas"
    >
    </chooseEID>
  </el-form>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { JSEncrypt } from "jsencrypt-rsa";
import { ElMessage, ElMessageBox } from "element-plus";
import wxlogin from "vue-wxlogin";

export default {
  data() {
    return {
      loginInfo: {
        enterpriseId: "",
        username: "", //用户名
        password: "", //密码
      },
      isLogin: false, //是否自动登录
      isRememberPas: false, //是否记住密码
      /** 是否显示登录二维码 */
      isShowQr: false,
      qrLogin: require("@/assets/qrLogin.png"),
      phoneImg: require("@/assets/shouji.png"),
      dialogEIDVisible: false,
	  chooseEIDParmas:{},

    };
  },
  props: {
    info: Object,
    data: {
      type: Array,
      default: function() {
        return [];
      },
    },
    enterpriseInfo: Object,
    /** 如果不是在分享页面使用当前组件 则需要设置 isSharePage：false ； 必传属性 */
    isSharePage: {
      type: Boolean,
      default: true,
      required: true,
    },
  },
  components: {
    wxlogin,
  },
  computed: {
    ...mapState("loginModule", [
      "loginRules", //登录验证信息
      "pubkey", //登录加密时的密钥
    ]),
    loginSuccessUrl() {
      //重定向地址
      return encodeURIComponent(`https://www.panlei.cn/login/sharePage`);
    },
    getParams() {
      //获取页面参数
      let { code, id, belongType, state } = this.$route.query;
      if (code) {
        id = state.split("$$$")[0];
        belongType = state.split("$$$")[1];
      }
      return {
        code,
        id,
        belongType,
      };
    },
    getState() {
      return this.getParams.id + "$$$" + this.getParams.belongType;
    },
  },
  methods: {
    ...mapActions("loginModule", [
      "uWechatLogin", //扫码登录  这个页面不用这个
      "uShareWechatLogin", //扫码登录 这个页面用这个
      "login", //登录请求
    ]),
    ...mapMutations("mysaleMudule", ["setOfferSuccessType"]),
    ...mapMutations(["renewToken", "renewUserInfo"]),
    ...mapActions("purchaseMudule", [
      "quoteCreate", //新增报价
    ]),

    openEID() {
      //打开选公司
      this.dialogEIDVisible = true;
    },

    confirmEID(v) {
      this.dialogEIDVisible = true;
	  this.loginInfo.enterpriseId=v.enterpriseId
      this.autoLogin();
    },
    goLoginInUser() {
      this.$refs["loginFrom"].validate((valid) => {
        if (valid) {
          this.chooseEIDParmas.type = "USERNAME";
          this.chooseEIDParmas.value = this.loginInfo.username;
          this.openEID();
        }
      });
    },
    autoLogin() {
      //登录
      this.$refs["loginFrom"].validate((valid) => {
        if (valid) {
          //提交
          this.login({
            enterpriseId: this.loginInfo.enterpriseId,
            username: this.loginInfo.username, //账号
            password: this.encryption(this.loginInfo.password), //密码
            id: this.info
              ? this.info.buyerId
              : this.$route.query.uid
              ? this.$route.query.uid
              : null, //分享人id
            shareType:
              this.info && this.info.buyerId
                ? "0"
                : this.$route.query.uid
                ? "1"
                : undefined, //分享的用户类型
          }).then((res) => {
            let { code, data, msg } = res.data;
            if (code === "0" && data.token) {
              //登录成功
              this.renewToken(res.data.data.token); //更新token
              this.renewUserInfo(res.data.data);
              //保存操作信息（登录成功后）
              localStorage.setItem(
                "loginSign",
                JSON.stringify({
                  isLogin: this.isLogin ? "1" : "0",
                  isRememberPas: this.isRememberPas ? "1" : "0",
                })
              );
              //保存账号密码
              localStorage.setItem("loginInfo", JSON.stringify(this.loginInfo));
              if (this.info) {
                //登录并报价
                if (data.userType === "0") {
                  //报价
                  this.goOffer();
                } else {
                  ElMessageBox.confirm(
                    "当前登录的账号是普通账号不能参与报价，是否跳转到首页?",
                    "提示",
                    {
                      confirmButtonText: "确认",
                      cancelButtonText: "取消",
                      type: "warning",
                    }
                  )
                    .then(() => {
                      this.jumpPage(); //跳转到登录首页
                    })
                    .catch(() => {});
                }
              } else {
                //直接登录
                //跳转页面
                this.jumpPage();
              }
            } else {
              ElMessage({
                showClose: true,
                message: msg || data.msg,
                type: "error",
              });
            }
          });
        }
      });
    },
    encryption(val) {
      //加密密码
      // 实例化加密对象
      const encrypt = new JSEncrypt();
      // 设置加密公钥
      encrypt.setPublicKey(this.pubkey);
      // 加密结果
      const encrypted = encrypt.encrypt(val);
      // 返回加密结果
      return encrypted;
    },
    jumpPage() {
      this.setOfferSuccessType(true);
      this.$router.push({
        path: "/home/index",
      });
    },
    goOffer() {
      //报价
      this.quoteCreate({
        itemRequestVOList: this.data,
        quoteRequestVO: {
          inquiryId: this.info.id,
          buyerEnterpriseId: this.info.buyerEnterpriseId,
        },
      }).then((res) => {
        let { data, code } = res.data;
        if (code === "0") {
          ElMessage({
            type: "success",
            message: "报价成功",
          });
          this.$emit("bjSuccess");
          //跳转到我的报价页面
          this.$router.push({
            path: "/mysale/customerOffer",
          });
        }
      });
    },
    auRegister() {
      //注册账号
      this.$emit("changePage", "register");
    },
    getEnterpriseInfo(id) {
      searchEnterpriseById;
    },
    forgetPassword() {
      //忘记密码
      this.$emit("changePage", "forgetPassword");
    },
    codeLogin() {
      //验证码登录
      this.$emit("changePage", "codeLogin");
    },
  },
  created() {
    var loginInfo = this.loginInfo,
      sign = null;
    try {
      loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
      sign = JSON.parse(localStorage.getItem("loginSign"));
    } catch (e) {
      //TODO handle the exception
    }
    this.isRememberPas = sign
      ? !!(sign.isRememberPas && sign.isRememberPas !== "0")
      : false;
    this.isLogin = sign ? !!(sign.isLogin && sign.isLogin !== "0") : false;
    if ((this.isRememberPas || this.isLogin) && loginInfo) {
      this.loginInfo = loginInfo;
      if (this.isLogin) {
        this.$nextTick(() => {
          this.autoLogin(); //自动登录
        });
      }
    }
  },
};
</script>

<style scoped lang="less">
.info-form {
  width: 370px;
  margin: 0 auto;

  /deep/ .el-form-item__content {
    margin-left: 0 !important;
  }
}

.qrLogin {
  position: absolute;
  right: 0;
  top: 60px;
}

.l-item {
  display: flex;
  align-items: center;
  font-size: 14px;

  .l-item-tail {
    flex: 1;
    text-align: right;
    cursor: pointer;
  }
}
</style>
