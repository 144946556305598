import { ElMessage } from 'element-plus'

export function uploadFileOnSuccess(res, file, fileList){
	return new Promise((resolve,reject) => {
		if(
			(res.code === '0' && !res.data) ||
			(res.code === '0' && res.data && res.data.fileId)
		){ //成功
			resolve();
		}else{ //失败
			var msg = res.msg || (res.data ? res.data.msg : '');
			ElMessage({
				type:'error',
				message:msg,
			})
			reject();
		}
	})
}