<template>
  <div class="lida-table-col" ref="lida-table-col">
    <el-popover
        placement="top"
        :width="popoverWidth"
        trigger="hover"
        :content="row[prop]"
        v-if="isShowPopover"
        :offset="30"
    >
      <template #reference>
        <span class="popover-span">{{ row[prop] }}</span>
      </template>
    </el-popover>
    <span v-else>{{ row[prop] }}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShowPopover: false,
    }
  },
  props: ['row', 'prop', 'popoverWidth'],
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        //计算宽度 重新渲染
        var dom = this.$refs['lida-table-col'];
        if (dom) { 
          dom = dom.childNodes;
          if (dom.length) {
            dom = dom[0];
            this.isShowPopover = !(dom.clientWidth === dom.scrollWidth)
          }
        }
      }, 0)
    })
  }
}
</script>

<style scoped lang="less">
.lida-table-col {
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 12px;
    box-sizing: border-box;
    max-width: 100%;
    display: block;
    font-size: 13px;
  }
}

.popover-span {
  font-size: 13px;
}
</style>
