import {
    createRouter,
    createWebHashHistory,
    createWebHistory,
} from "vue-router"
import store from '../store.js';

var maxlength = 15;  //同时缓存的最大路由数

// 2. 定义路由配置
const routes = [{
    path: "/",
    redirect: '/login'
}];

//创建路由实例
const router = createRouter({
    history: createWebHistory(),
    routes,
});

const noLginArr = [  //不需要登录的页面
    '/',
    'http://jujey9.natappfree.cc/lidamall/u/wechat/login/',
    'contract',
]

const matchingPath = function (to, arr) {
    var path = ['/'] + to.path.split('/')[1];
    var shu = store.state.LDrouters;
    if (!shu.find(item => item.path === '/supplierMessage/index' || item.path === '/mysale/orderSetup')) {
        shu = shu.concat(
            [{meta: {title: '采购中心'}, path: '/supplierMessage/index', closable: true, active: true},
                {meta: {title: '销售中心'}, path: '/mysale/orderSetup', closable: true, active: true}]
        )
    }
    shu.map(v => v.active = false);
    if (to.path === '/login/index') { //跳转到首页时清空值
        shu = [
            {meta: {title: '首页'}, path: '/home/index', closable: true, active: true},
            {meta: {title: '采购中心'}, path: '/supplierMessage/index', closable: true, active: true},
                {meta: {title: '销售中心'}, path: '/mysale/orderSetup', closable: true, active: true}
        ];
    } else {
        if (shu.some(v => v.path === to.path)) {
            shu[shu.findIndex(v => v.path === to.path)].active = true;
        } else {
            to.active = true;
            var obj = JSON.parse(JSON.stringify(to));
            if (shu.length >= maxlength) {
                shu.splice(1, 1);
            }
            shu.push(obj);
        }
    }
    store.commit('renewLDrouters', [...shu]);
}

router.beforeEach((to, from, next) => {


    if (router.getRoutes().some(item => to.path === item.path)) { //判断是否匹配到路由
        if (to.meta.title) {
            matchingPath(to, router.getRoutes());
            //document.title = to.meta.title;   //修改标题名字
            var token = store.state.token;
            if (
                token ||  //判断是否有token
                to.path.split('/').filter(item => item)[0] === 'login' ||   //是否是登录页面
                noLginArr.indexOf(to.path) !== -1  //是否是不需要登录的页面 
            ) {
                next();
            } else { //否则跳转到首页
                next({path: '/'});
            }
        } else {
            next({...to, replace: true});
        }
    } else { //如果没匹配到自动跳转到首页
        next({path: '/'});
    }
})


console.log(routes);
export default router;
