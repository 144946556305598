<template>
  <el-form :model="values" :label-width="labelWidth" :rules="rules" ref="form">
    <div class="lida-form" v-if="formColumns.length">
      <el-form-item
        :style="formItemStyle"
        v-for="(item, index) in formColumns"
        v-bind="item"
        :key="index"
      >
        <el-input
          v-model="values[item.prop]"
          :disabled="item.disabled"
          v-if="!item.type || item.type === 'input'"
          :placeholder="item.placeholder?item.placeholder:'请输入厂家、系列、型号、订货号...查价格'"
          style="width:300px"
        />
        <el-date-picker
          v-model="values[item.prop]"
          type="daterange"
          range-separator="-"
          unlink-panels
          start-placeholder="开始日"
          end-placeholder="截止日"
          style="width: 100%;"
          :disabled="item.disabled"
          v-else-if="item.type === 'date'"
          :shortcuts="shortcuts"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD HH:mm:ss"
        ></el-date-picker>
        <el-select
          v-model="values[item.prop]"
          placeholder="请选择"
          :disabled="item.disabled"
          v-else-if="item.type === 'select'"
          clearable
        >
          <el-option
            v-for="son in item.options"
            :key="son[item.optionsValue || 'value']"
            :label="son[item.optionsLabel || 'label']"
            :value="son[item.optionsValue || 'value']"
          />
        </el-select>
        <el-cascader
          v-model="values[item.prop]"
          :options="areaInfo"
          :disabled="item.disabled"
          :props="{ expandTrigger: 'hover' }"
          style="width: 100%;"
          v-else-if="item.type === 'area'"
        ></el-cascader>
      </el-form-item>
      <slot name="formItem"></slot>
      <el-button @click="search" size="mini" v-if="showSearchBtn"
        >搜索</el-button
      >
      <el-button @click="reset" size="mini" v-if="showResetBtn">重置</el-button>
      <slot></slot>
    </div>
  </el-form>
</template>

<script>
import area from "@/common/json/area.json";
import { handleTreeData } from "@/common/js/common.js";
import moment from "moment/moment";
export default {
  data() {
    return {
      values: {}, //表单值
      areaInfo: handleTreeData(area, "name", "name"),
      shortcuts: [
        {
          text: '今日',
          value: () => {
            const start = moment().format('YYYY-MM-DD 00:00:00')
            const end = moment().format('YYYY-MM-DD 23:59:59')
            return [start, end]
          },
        },
        {
          text: '昨日',
          value: () => {
            const start = moment().subtract(1, 'days').format('YYYY-MM-DD 00:00:00')
            const end = moment().subtract(1, 'days').format('YYYY-MM-DD 23:59:59')
            return [start, end]
          },
        },
        {
          text: '本周',
          value: () => {
            const start = moment(moment().week(moment().week()).startOf('week').valueOf()).format('YYYY/MM/DD 00:00:00');
            const end = moment(moment().week(moment().week()).endOf('week').valueOf()).format('YYYY/MM/DD 23:59:59');
            return [start, end]
          },
        },
        {
          text: '上周',
          value: () => {
            const start = moment(moment().week(moment().week() - 1).startOf('week').valueOf()).format('YYYY/MM/DD 00:00:00');
            const end = moment(moment().week(moment().week() - 1).endOf('week').valueOf()).format('YYYY/MM/DD 23:59:59');
            return [start, end]
          },
        },
        {
          text: '本月',
          value: () => {
            const start = moment(moment().month(moment().month()).startOf('month').valueOf()).format('YYYY/MM/DD 00:00:00');
            const end = moment(moment().month(moment().month()).endOf('month').valueOf()).format('YYYY/MM/DD 23:59:59');
            return [start, end]
          },
        },
        {
          text: '上月',
          value: () => {
            const start = moment(moment().month(moment().month() - 1).startOf('month').valueOf()).format('YYYY/MM/DD 00:00:00');
            const end = moment(moment().month(moment().month() - 1).endOf('month').valueOf()).format('YYYY/MM/DD 23:59:59');
            return [start, end]
          },
        },
        {
          text: '本季',
          value: () => {
            const start = moment(moment().quarter(moment().quarter()).startOf('quarter').valueOf()).format('YYYY/MM/DD 00:00:00');
            const end = moment(moment().quarter(moment().quarter()).endOf('quarter').valueOf()).format('YYYY/MM/DD 23:59:59');
            return [start, end]
          },
        },
        {
          text: '本年',
          value: () => {
            const start = moment(moment().year(moment().year()).startOf('year').valueOf()).format('YYYY/MM/DD HH:mm:ss');
            const end = moment(moment().year(moment().year()).endOf('year').valueOf()).format('YYYY/MM/DD HH:mm:ss');
            return [start, end]
          },
        },
        {
          text: '上年',
          value: () => {
            const start = moment(moment().year(moment().year() - 1).startOf('year').valueOf()).format('YYYY/MM/DD HH:mm:ss');
            const end = moment(moment().year(moment().year() - 1).endOf('year').valueOf()).format('YYYY/MM/DD HH:mm:ss');
            return [start, end]
          },
        },
      ],
    };
  },
  props: {
    formValues: Object, //默认表单值
    span: Number, //没行显示formItem的个数
    formColumns: Array, //表单配置
    showSearchBtn: Boolean, //搜索按钮的隐藏显示
    showResetBtn: Boolean, //重置按钮的隐藏显示
    labelWidth: {
      type: [Array, String],
      default: null,
    },
    rules: Array,
  },
  computed: {
    formItemStyle() {
      return {
        width: 100 / this.span + "%",
        "margin-bottom": "10px",
        "margin-right": "20px",
      };
    },
  },
  methods: {
    search() {
      this.$emit("search", this.values);
    },
    reset() {
      for (var key in this.values) {
        this.values[key] = null;
      }
      this.search();
    },
  },
  created() {
    this.values = this.formValues;
  },
  watch: {
    formValues(v) {
      this.values = v;
    },
  },
};
</script>

<style lang="less">
.lida-form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  .el-button {
    margin-bottom: 10px;
  }
}
</style>
