export default{
    /** 我要采购模块 */
    inquiry: '/inquiry/page', // 我的询价
    purchase: '/purchase/page', // 我的白沟
    removeInquiry: '/inquiry/removeInquiry', // 删除询价
    revokeInquiry: '/inquiry/revokeInquiry', // 撤销询价
    /** -------------------------------------- */
    /** 我要销售模块 */
    quote: '/quote/page', // 客户报价
    
    listTree: '/quote/set/listTree', //报价设置树形
    /** -------------------------------------- */
}