<template>
	<div class="warp">
		<template v-if="!callDialog">
			<div class="title-warp">
				<span class="title-text">社交帐号绑定</span>
			</div>
			<!-- 已绑定   -->
			<div class="content-warp" v-if="isBind">
				<img :src="weChatBindImg" alt="" width="50" height="50" />
				<div>
					<p style="color:#333">微信</p>
					<p style="color:#999">已绑定</p>
					<p style="color:blue;text-decoration:underline;font-style:oblique;" @click="cancelBind">
						立即解绑
					</p>
				</div>
			</div>
			
			<!-- 未绑定   -->
			<div class="content-warp" v-else>
				<img :src="weChatNoBind" alt="" width="50" height="50" />
				<div>
					<p style="color:#333">微信</p>
					<p style="color:#999">未绑定</p>
					<p style="color:blue;text-decoration:underline;font-style:oblique" @click="dialogVisible = true">
						立即绑定
					</p>
				</div>
			</div>
		</template>
		
		<!-- 绑定的弹窗   -->
		<el-dialog v-model="dialogVisible" width="30%">
			<div class="dialog-content-warp">
				<wxlogin self_redirect="false" appid="wx75ee94f1e9d5bc07" scope="snsapi_login"
					:redirect_uri="loginSuccessUrl()"></wxlogin>
			</div>
		</el-dialog>

		<!--  解绑的弹窗  -->
		<el-dialog v-model="cancelVisible" width="30%">
			<div>
				<el-form :model="form" class="form-warp">
					<div class="form-title">
						<span class="title">解除绑定</span><span class="desc">解除绑定后将不能使用微信账号登录盘雷</span>
					</div>

					<el-form-item label="手机号" prop="phone" label-width="80px">
						<el-input v-model="form.phone" placeholder="请输入手机号" prefix-icon="el-icon-user" />
					</el-form-item>

					<div class="r-row">
						<el-form-item label="短信验证码" prop="code" style="flex: 1;">
							<el-input v-model="form.code" placeholder="请输入" />
						</el-form-item>
						<el-button style="margin-top: 6px;margin-left: 10px;" type="primary" @click="getCode"
							:disabled="second !== 0" size="mini">{{ getCodeText }}</el-button>
					</div>

					<div class="confirm-btn">
						<el-button type="primary" style="margin-left: 40px;" size="mini" @click="confirm">确认</el-button>
					</div>

				</el-form>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import wxlogin from "vue-wxlogin";
	import {
		ElMessage
	} from "element-plus";
	import {
		mapActions,
		mapState,
		mapMutations,
	} from "vuex";
	const formLabelWidth = "120px";

	/**
	 *   @Author : 王雨波
	 *   @Date : 2022/1/12
	 *   @Version : 1.0.0
	 *   @Last Modified by : 王雨波
	 *   @Last Modified time : 2022/1/12
	 *   @desc 微信绑定和解绑组件
	 **/
	export default {
		name: "weChatBind",
		props: {
			isBind: {
				type: Boolean
			},
			callDialog:{
				type: Boolean,
				default: false,
			},
			isShow:{
				type:Boolean,
				default:false,
			}
		},
		components: {
			wxlogin
		},

		data() {
			return {
				weChatBindImg: require("@/assets/wechat.png"),
				weChatNoBind: require("@/assets/WechatGray.png"),
				dialogVisible: false,
				cancelVisible: false,
				second: 0, //倒计时秒
				timer: null, //定时器
				loginSuccessUrl() {
					return encodeURIComponent("https://www.panlei.cn/system/index");
				},
				form: {
					phone: '', //
					code: '', //验证码
				}
			};
		},
		methods: {
			...mapActions("systemModule", [
				"uBindCancelBind" //解绑微信
			]),
			...mapActions([
				"uWechatBind" //绑定微信号
			]),
			...mapActions('loginModule', [
				'smsCodeV', //验证手机验证码
				'smsCode', //发送验证码
			]),
			getCode() {
				//获取验证码
				if (this.form.phone) {
					this.smsCode(this.form.phone).then(res => {
						let {
							code,
							data
						} = res.data;
						if (code === "0") {
							ElMessage({
								message: "发送成功",
								type: "success"
							});
							this.second = 60;
							this.timer =
								this.timer ||
								setInterval(() => {
									if (this.second > 0) {
										this.second--;
									} else {
										clearInterval(this.timer);
										this.second = 0;
										this.timer = null;
									}
								}, 1e3);
						}
					});
				} else {
					ElMessage({
						message: "请输入手机号",
						type: "warning"
					});
				}
			},
			refresh() {
				this.$emit("refresh");
			},

			/** 取消绑定 */
			async cancelBind() {
				this.cancelVisible = true;
			},

			/** 确认取消 */
			async confirm() {
				try {

					/** 验证 验证码 */
					this.smsCodeV(`?phone=${this.form.phone}&code=${this.form.code}`).then(res => {
						let {
							code,
							data
						} = res.data;
						if (code === '0') {
							this.uBindCancelBind().then(res => {
								const {
									data,
									code
								} = res.data;
								if (code === "0") {
									ElMessage({
										type: "success",
										message: "解绑成功"
									});
									this.refresh();
								}
							}).catch(e => console.log(e))

						} else {
							ElMessage({
								type: "error",
								message: "验证失败"
							});
						}
					})
				} catch (e) {
					console.log(e);
				}
				this.cancelVisible = false
			}

		},
		created() {
			this.userInfo =
				localStorage && JSON.parse(localStorage?.getItem("userInfo"));
			if (this.$route.query.code) {
				//判断如果有code
				this.uWechatBind(
					`?code=${this.$route.query.code}&userId=${this.userInfo.userId}`
				).then(res => {
					let {
						code,
						data
					} = res.data;
					if (code === "0") {
						ElMessage({
							type: "success",
							message: "绑定成功"
						});
						//刷新页面去掉code
						var obj = JSON.parse(JSON.stringify(this.$route.query));
						delete obj.code;

						this.refresh();
						this.$router.replace({
							query: {
								...obj
							}
						});
					}
				});
			}
		},
		computed: {
			getCodeText() {
				return this.second === 0 ? "获取验证码" : `${this.second}s后重新获取`;
			}
		},
	};
</script>

<style scoped lang="less">
	.warp {
		display: flex;
		flex-direction: column;
		width: 800px;

		.title-warp {
			height: 50px;
			width: 100%;
			background-color: #f5f5f5;
			border: 1px solid #eee;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			.title-text {
				padding-left: 20px;
			}
		}

		.content-warp {
			width: 100%;
			height: 150px;
			border: 1px solid #eee;
			border-top: none;
			display: flex;

			img {
				padding: 20px;
			}

			div {
				p {
					padding: 5px 0;
				}
			}
		}

		.dialog-content-warp {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.form-warp {
			padding: 20px;

			.form-title {
				display: flex;
				padding-bottom: 20px;
				justify-content: flex-start;
				align-items: flex-end;

				.title {
					font-size: 20px;
					font-weight: bold;
					color: #333;
				}

				.desc {
					font-size: 14px;
					color: #999;
					padding-left: 20px;
				}
			}
		}

		.r-row {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			span {
				text-align: right;
				line-height: 40px;
				padding-right: 12px;
				box-sizing: border-box;
			}
		}

		.confirm-btn {
			display: flex;
			justify-content: center;
		}
	}
</style>
