<template>
  <el-form
    :label-position="labelPosition"
    label-width="100px"
    :model="info"
    class="info-form"
    :rules="rules"
    ref="loginFrom"
  >
    <el-form-item label="手机号" prop="phone">
      <el-input
        v-model="info.phone"
        placeholder="请输入手机号"
        prefix-icon="el-icon-user"
      />
    </el-form-item>
    <div class="r-row">
      <el-form-item label="短信验证码" prop="code" style="flex: 1">
        <el-input v-model="info.code" placeholder="请输入" />
      </el-form-item>
      <el-button
        style="margin-top: 6px; margin-left: 10px"
        type="primary"
        @click="getCode"
        :disabled="second !== 0"
        size="mini"
        >{{ getCodeText }}</el-button
      >
    </div>
    <el-form-item label="新密码" prop="password">
      <el-input
        v-model="info.password"
        placeholder="请输入新密码"
        prefix-icon="el-icon-user"
      />
    </el-form-item>
    <el-form-item label="确认新密码" prop="confirmPassword">
      <el-input
        v-model="info.confirmPassword"
        placeholder="请确认新密码"
        prefix-icon="el-icon-user"
      />
    </el-form-item>
    <div class="r-footer">
      <el-button
        type="success"
        v-if="!notShowBack"
        size="mini"
        @click="returnLogin"
        >返回登录</el-button
      >
      <el-button
        type="primary"
        style="margin-left: 40px"
        size="mini"
        @click="confirm"
        >确认</el-button
      >
    </div>
  </el-form>
</template>

<script>
import { ElMessage } from "element-plus";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      info: {
        password: "", //密码
        phone: "", //账号
        confirmPassword: "", //确认密码
        code: "", //验证码
      },
      timer: null, //定时器
      second: 0, //倒计时秒
      rules: {
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
        confirmPassword: [
          { required: true, message: "请确认新密码", trigger: "blur" },
        ],
      },
    };
  },
  props: {
    notShowBack: Boolean,
  },
  computed: {
    ...mapState("loginModule", [
      "pubkey", //登录加密时的密钥
    ]),
    getCodeText() {
      return this.second === 0 ? "获取验证码" : `${this.second}s后重新获取`;
    },
  },
  methods: {
    ...mapActions("loginModule", [
      "uRetrievePassword", //用户找回密码
      "smsCodeV", //验证手机验证码
      "smsCode", //发送验证码
    ]),
    returnLogin() {
      //返回登录
      this.$emit("changePage", "login");
    },
    confirm() {
      this.$refs["loginFrom"].validate((valid) => {
        if (valid) {
          if (this.info.confirmPassword !== this.info.password) {
            return ElMessage({
              message: "两次输入的密码不一致",
              type: "warning",
            });
          }
          this.smsCodeV(
            `?phone=${this.info.phone}&code=${this.info.code}`
          ).then((res) => {
            let { code, data } = res.data;
            if (code === "0") {
              var params = JSON.parse(JSON.stringify(this.info));
              delete params.code;
              delete params.confirmPassword;
              //params.password = this.encryption(params.password);  //加密密码
              this.uRetrievePassword(
                `?password=${params.password}&phone=${params.phone}`
              ).then((res) => {
                let { code, data } = res.data;
                if (code === "0") {
                  ElMessage({
                    message: "重置密码成功",
                    type: "success",
                  });
                  this.returnLogin(); //返回到登录页面
                }
              });
            }
          });
        }
      });
    },
    getCode() {
      //获取验证码
      if (this.info.phone) {
        this.smsCode(this.info.phone).then((res) => {
          let { code, data } = res.data;
          if (code === "0") {
            ElMessage({
              message: "发送成功",
              type: "success",
            });
            this.second = 60;
            this.timer =
              this.timer ||
              setInterval(() => {
                if (this.second > 0) {
                  this.second--;
                } else {
                  clearInterval(this.timer);
                  this.second = 0;
                  this.timer = null;
                }
              }, 1e3);
          }
        });
      } else {
        ElMessage({
          message: "请输入手机号",
          type: "warning",
        });
      }
    },
    encryption(val) {
      //加密密码
      // 实例化加密对象
      const encrypt = new JSEncrypt();
      // 设置加密公钥
      encrypt.setPublicKey(this.pubkey);
      // 加密结果
      const encrypted = encrypt.encrypt(val);
      // 返回加密结果
      return encrypted;
    },
  },
};
</script>

<style scoped lang="less">
.info-form {
  width: 470px;
  margin: 0 auto;
  /deep/ .el-form-item__content {
    margin-left: 0 !important;
  }
}

.l-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  .l-item-tail {
    flex: 1;
    text-align: right;
    cursor: pointer;
  }
}
.r-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  span {
    text-align: right;
    line-height: 40px;
    padding-right: 12px;
    box-sizing: border-box;
  }
}
.r-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
