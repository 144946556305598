import main from '@/module/loginModule/viewPage/main.vue';
export default {
	path: "/login",
	component:main,
	redirect:"/login/index",
	children:[
		{
			path: "index",
			component:() => import('@/module/loginModule/viewPage/login.vue'),
			meta:{title:"登录"},
		},
		{
			path: "register",
			component:() => import('@/module/loginModule/viewPage/register/index'),
			meta:{title:"注册"},
			redirect: '/login/register/client',
			children: [
				{
					path: "distributor",
					name: 'Distributor',
					component:() => import('@/module/loginModule/viewPage/register/distributor.vue'),
					meta:{title:"供应商注册"},
				},
				{
					path: "client",
					component:() => import('@/module/loginModule/viewPage/register/client.vue'),
					meta:{title:"客户注册"},
				}
			]
		},
		{
			path:"sharePage",
			component:() => import('@/module/loginModule/viewPage/sharePage'),
			meta:{title:"分享"},
		},
	]
}