import request from '@/common/js/request.js';
import api from '@/common/js/api.js';

export default {
    namespaced: true, //区分模块
    state: {
        turnInquiryInfo: null,  //转成询价单的信息
        documentState: { //询价单状态
            WAIT_QUOTE: "待报价",
            COMPLETE_QUOTE: "已报价",
            WAIT_CONTRACT: "待发合同",   //到这一步生成订单
            WAIT_BACK_CONTRACT: "待回传合同",
            FINANCE_PAYMENT: "待付款",
            FINANCE_PENDING: "待收款",
            SHIP_PENDING: '待发货',
            RECEIVE_PENDING: "已发货",
            COMPLETED: "已完成",
            REVOKE: "已撤销",
            OVERDUE: "已过期",
            STOP: "已终止",
            CLOSE: "已关闭",
        },
        orderStatus: [ //订单的筛选状态
            {title: '待发合同', type: 'WAIT_CONTRACT'},
            {title: '待回传合同', type: 'WAIT_BACK_CONTRACT'},
            {title: '待付款', type: 'FINANCE_PAYMENT'},
            {title: '待收款', type: 'FINANCE_PENDING'},
            {title: '待发货', type: 'SHIP_PENDING'},
            {title: '已发货', type: 'RECEIVE_PENDING'},
            {title: '已完成', type: 'COMPLETED'},
        ],
        payStatus: {  //支付状态
            FINANCE_PAYMENT: '待付款',
            FINANCE_COLLECTION: '待收款',
            COLLECTION: '已收款',
        },
        isPay: [
            {title: '未支付', type: '0'},
            {title: '全部支付', type: '1'},
            {title: '部分支付', type: '2'},
        ],
        shipStatus: [
            {title: '未发货', type: 'NIL'},
            {title: '部分发货', type: 'PD'},
            {title: '全部发货', type: 'AD'},
        ],
        documentStateDel: ['REVOKE', 'OVERDUE', 'STOP'],
        paymentTypes: [  //付款方式
            {title: '合同全款到帐后订货发货', type: 'PAY_ON_001'},
            {title: '预付30%定金订货。合同余款到帐后发货', type: 'PAY_ON_002'},
            {title: '预付0%定金订货。货到收货地付清合同余款交货', type: 'PAY_ON_003'},
            {title: '货到付合同全款,收取现金如协商同意付支票或承兑，需货到当场给付', type: 'PAY_ON_004'},
            {title: '预付30%定金。合同余款交货日起15沃内结清!', type: 'PAY_ON_005'},
            {title: '预付30%定金。合同余款交货日起30天内结清!', type: 'PAY_ON_006'},
            {title: '预付30%定金。合同余款交货日起45天内结清!', type: 'PAY_ON_007'},
            {title: '预付30%定金。合同余款交货日起60天内结清!', type: 'PAY_ON_008'},
            {title: '预付50%定金。合同余款交货日起15天内结清! 逾期每天按合同总金额1%罚款', type: 'PAY_ON_009'},
            {title: '预付50%定金。合同余款交货日起30天内结清! 逾期每天按合同总金额1%罚款', type: 'PAY_ON_010'},
            {title: '预付50%定金。合同余款交货日起45天内结清! 逾期每天按合同总金额1%罚款', type: 'PAY_ON_011'},
            {title: '预付50%定金。合同余款交货日起60天内结清! 逾期每天按合同总金额1%罚款', type: 'PAY_ON_012'},
            {title: '预付10%定金订货,付0%发货，40%余款15沃内结清', type: 'PAY_ON_013'},
            {title: '预付10%定金订货，付50%发货，40%余款3天内结清', type: 'PAY_ON_014'},
            {title: '全款送货日起15天内结清!逾期每天按合同总金额1%罚款', type: 'PAY_ON_015'},
            {title: '全款送货日起30天内结清!逾期每天按合同总金额1%罚款', type: 'PAY_ON_016'},
            {title: '全款送货日起45天内结清!逾期每天按合同总金额1罚款', type: 'PAY_ON_017'},
            {title: '全款送货日起60天内结清!逾期每天按合同总金额1罚款', type: 'PAY_ON_018'},
            {title: '合同总金额下月25号前结清全款!逾期每天按合同总金额1%罚款', type: 'PAY_ON_019'},
            {title: '货到付款', type: 'PAY_ON_020'},
            {title: '交货日起30天内结清。30天月结', type: 'PAY_ON_021'},
        ],
        courierCompany: [  //快递公司
            {title: '顺丰速运', type: 'shunfeng'},
            {title: '顺丰快运', type: 'shunfengkuaiyun'},
            {title: '德邦快递', type: 'debangkuaidi'},
            {title: '京东快运', type: 'jingdongkuaiyun'},
            {title: '京东物流', type: 'jd'},
            {title: '极兔速递', type: 'jtexpress'},
            {title: '申通快递', type: 'shentong'},
            {title: '圆通速递', type: 'yuantong'},
            {title: '韵达快递', type: 'yunda'},
            {title: '韵达快运', type: 'yundakuaiyun'},
            {title: '优速', type: 'youshuwuliu'},
            {title: '优速通达', type: 'yousutongda'},
            {title: '中通快递', type: 'zhongtong'},
            {title: '中通快运', type: 'zhongtongkuaiyun'},
            {title: '中通国际', type: 'zhongtongguoji'},
            {title: '邮政快递包裹', type: 'youzhengguonei'},
            {title: 'EMS', type: 'ems'},
            {title: '邮政标准快递', type: 'youzhengbk'},
            {title: '德邦', type: 'debangwuliu'},
            {title: '联昊通', type: 'lianhaowuliu'},
            {title: '安能快运', type: 'annengwuliu'},
            {title: '跨越速运', type: 'kuayue'},
            {title: '速尔快递', type: 'suer'},
            {title: '百世快运', type: 'baishiwuliu'},
            {title: '壹米滴答', type: 'yimidida'},
            {title: '宅急送', type: 'zhaijisong'},
            {title: '中铁快运', type: 'ztky'},
            {title: '加运美', type: 'jiayunmeiwuliu'},
            {title: '信丰物流', type: 'xinfengwuliu'},
            {title: 'UPS', type: 'ups'},
            {title: 'Fedex-国际件-中文', type: 'fedexcn'},
            {title: '安能快递', type: 'ane66'},
            {title: 'TNT', type: 'tnt'},
            {title: '嘉里大通', type: 'jialidatong'},
            {title: '天地华宇', type: 'tiandihuayu'},
            {title: '盛辉物流', type: 'shenghuiwuliu'},
            {title: 'OCS', type: 'ocs'},
            {title: '天天快递', type: 'tiantian'},
            {title: '中铁飞豹', type: 'zhongtiewuliu'},
            {title: '佳吉快运', type: 'jiajiwuliu'},
            {title: '恒路物流', type: 'hengluwuliu'},
            {title: '大田物流', type: 'datianwuliu'},
            {title: '远成物流', type: 'yuanchengwuliu'},
            {title: '全一快递', type: 'quanyikuaidi'},
            {title: '中邮物流', type: 'zhongyouwuliu'},
            {title: '百福东方', type: 'baifudongfang'},
            {title: '民航快递', type: 'minghangkuaidi'},
            {title: '全峰快递', type: 'quanfengkuaidi'},
            {title: '传喜物流', type: 'chuanxiwuliu'},
            {title: '明亮物流', type: 'mingliangwuliu'},
            {title: '韩国邮政', type: 'koreapostcn'},
            {title: '运通快运', type: 'ytky168'},
            {title: '圣安物流', type: 'shenganwuliu'},
            {title: '穗佳物流', type: 'suijiawuliu'},
            {title: '飞康达', type: 'feikangda'},
            {title: '凡宇快递', type: 'fanyukuaidi'},
            {title: '其他快递', type: 'other'},

        ],
        protects: [ //保证期选项
            {title: '过保', type: '1'},
            {title: '未过保', type: '0'},
        ],
        moneys: [ //币种选择
            {title: '人民币', type: 'CNY'},
            {title: '美元', type: 'USD'},
        ],
        /** 2022.1.5 wyb  修改和增加这个项目类型 由于2和1完全一样 也满足else的判断 所以应该不用改页面的判断条件 */
        projectType: [ //项目类型
            {title: '无项目（备品备件）', type: 0},
            {title: '有项目（有项目报备）', type: 1},
            {title: '有项目（无项目报备）', type: 2},
        ],
        projectState: [ //项目状态
            {title: '投标中', type: '1'},
            {title: '二次定标', type: '2'},
            {title: '采购', type: '3'},
        ],
        invoiceStatusText: [ //是否开票
            {title: '未开票', type: '0'},
            {title: '已开票', type: '1'},
        ],
    },
    getters: {},
    mutations: {
        renewTurnInquiryInfo(state, data) { //需要转成询价单的信息
            state.turnInquiryInfo = data;
        }
    },
    actions: {
        bannerCategoryCurrent(context, params) { //获取顶级分类
            return request.post(api.bannerCategoryCurrent, {params})
        },
        supplyCategoryCurrent(context, params) { //获取顶级分类
            return request.post(api.supplyCategoryCurrent, {params})
        },
        saleCategoryCurrent(context, params) { //获取顶级分类
            return request.post(api.saleCategoryCurrent, params)
        },
        listSkuCategoryForFirst(context, params) { //获取顶级分类
            return request.get(api.listSkuCategoryForFirst, {params})
        },
        listSkuCategoryForChildren(context, params) { //获取子集分类
            return request.get(api.listSkuCategoryForChildren, {params})
        },
        jsonV1(context, id) { //获取页面数据压缩流
            return request({
                method: 'get',
                responseType: 'arraybuffer',
                url: api.jsonV1 + '/' + id,
            })
        },
        skuPage(context, params) { //获取商品列表ES型
            return request.post(api.skuPage, params);
        },
        skuPlatformPage(context, params) { //获取商品列表默认型
            return request.post(api.skuPlatformPage, params);
        },
        listSkuCategoryForChildrenOne(context, params) { //获取子集分类一级
            return request.get(api.listSkuCategoryForChildrenOne, {params})
        },
        skuSpecList(context, params) { //获取规格列表
            return request.post(api.skuSpecList, params)
        },
        selectionCreate(context, params) { //加入购物车
            return request.post(api.selectionCreate, params)
        },
        selectionCreateInquiry(context, params) {  //生成询价单(从购物车新增)
            return request.post(api.selectionCreateInquiry, params)
        },
        selectionRemoveAll(context, {type}) { //修改购物车
            return request.post(`${api.selectionRemoveAll}?type=${type}`)
        },
        selectionUpdate(context, params) { //修改购物车
            return request.post(api.selectionUpdate, params)
        },
        deleteSelection(context, id) { //移除购物车商品
            return request.delete(`${api.selection}/${id}`)
        },
        selectionList(context, params) { //获取购物车列表
            return request.get(api.selectionList, {params})
        },
        /** --------------- zhd ------------------ */
        inquiryReq(context, params) {
            return request.post(api.inquiry, params)
        },
        purchaseReq(context, params) {
            return request.post(api.purchase, params)
        },
        purchaseDelete(context, documentId) {
            return request.delete(api.purchaseDetails + '/' + documentId)
        },
        removeInquiryReq(context, id) {
            return request.delete(`${api.removeInquiry}?id=${id}`)
        },
        revokeInquiryReq(context, id) {
            return request.put(`${api.revokeInquiry}?id=${id}`)
        },
        /** --------------------------------------- */
        enterpriseListByEnterpriseId(context, params) { //获取询价企业列表
            return request.get(api.enterpriseListByEnterpriseId, {params})
        },
        inquiryPageBuyer(context, params) { //买家询价列表
            return request.post(api.inquiryPageBuyer, params)
        },
        inquiryInfo(context, params) { //询价详情
            return request.get(api.inquiryInfo, {params})
        },
        invitation(context, params) { //获取二维码
            return request.post(api.invitation, params)
        },
        userListByShareEnterpriseId(context, params) { //获取询价客户
            return request.get(api.userListByShareEnterpriseId, {params})
        },
        toAutoQuoteByInquiryId(context, params) { //自动报价
            return request.get(api.toAutoQuoteByInquiryId, {params})
        },
        userListPageByShareEnterpriseId(context, params) { //获取询价客户
            return request.post(api.userListPageByShareEnterpriseId, params)
        },
        enterpriseListPageByEnterpriseId(context, params) { //获取询价客户
            return request.post(api.enterpriseListPageByEnterpriseId, params)
        },

        inquiryCreate(context, params) { //新增询价单(直接新增)
            return request.post(api.inquiryCreate, params)
        },
        quoteCreate(context, params) { //新增报价
            return request.post(api.quoteCreate, params)
        },
        purchaseCartCreate(context, params) { //加入采购购物车
            return request.post(api.purchaseCartCreate, params)
        },
        purchaseCartListCart(context, params) { //采购购物车列表
            return request.get(api.purchaseCartListCart, {params})
        },
        purchaseCartCreatePurchaseAdmin(context, params) { // 生成采购单(平台采购)
            return request.post(api.purchaseCartCreatePurchaseAdmin, params)
        },
        inquiryPurchaseInfo(context, params) { //询价加入采购购物车
            return request.post(api.inquiryPurchaseInfo, params)
        },
        purchaseCart(context, id) { //删除购物车商品
            return request.get(`${api.purchaseCart}/${id}`)
        },
        purchaseCartCreatePurchase(context, id) { //生成采购单
            return request.post(`${api.purchaseCartCreatePurchase}?inquiryId=${id}`)
        },
        purchaseCollectionUndo(context, params) { //采购单发货作废
            return request.post(api.purchaseCollectionUndo, params)
        },
        purchaseUndo(context, id) { //采购单作废
            return request.post(`${api.purchaseUndo}/${id}`)
        },
        quoteModifyQuoteItem(context, params) { //修改报价信息
            return request.post(api.quoteModifyQuoteItem, params)
        },
        purchaseUpdateBackContractFile(context, params) { //回传合同
            return request.post(api.purchaseUpdateBackContractFile, params)
        },
        purchaseUpdatePayment(context, params) { //确认付款
            return request.post(api.purchaseUpdatePayment, params)
        },
        categoryListCategory(context, id) { //获取大中小分类列表
            var url = `${api.categoryListCategory}${id ? '?parentId=' + id : ''}`;
            return request.get(url);
        },
        purchaseCartUpdate(context, params) {  //采购购物车修改商品信息
            return request.post(api.purchaseCartUpdate, params)
        },
        purchaseCartCreatePurchaseByQuote(context, params) { //
            return request.post(api.purchaseCartCreatePurchaseByQuote, params);
        },
        pageByCreateInquiry(context, params) { //可询价企业
            return request.post(api.pageByCreateInquiry, params);
        },
        listBrandInfo(context, params) { //可询价企业对应的品牌接口
            return request.post(api.listBrandInfo, params);
        },
        listSkuCategoryByCategoryId(context, params) { //商品类目列表-通过分类查询
            return request.post(api.listSkuCategoryByCategoryId, params);
        },
    }
}
