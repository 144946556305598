import request from '@/common/js/request.js';
import api from '@/common/js/api.js';
import {downloadExcel} from "@/common/js/downloadExcel.js";
export default {
	namespaced: true, //区分模块
	state: {
		stockType:[ //入库类型
			{title:'普通入库',type:0},
			{title:'收货入库',type:1},
			{title:'导入入库',type:4},
			{title:'导入出库',type:5},
		],
		outStockType:[
			{title:'普通出库',type:2},
			{title:'销售出库',type:3},
			{title:'导入入库',type:4},
			{title:'导入出库',type:5},
		],
		options:[]
	},
	getters: {
		
	},
	mutations: {
		setOptions(state,arr){
			state.options=arr
		}
	},
	actions: {
		changeSetting({ commit }, data) {
			commit('setOptions', data)
		  },
		
		stockPage(context,params){ //获取我的库存列表
			return request.post(api.stockPage,params)
		},
		stockInStock(context,params){ //入库
			return request.post(api.stockInStock,params)
		},
		stockListShipByBuyerId(context,params){ //查询我的收货单列表
			return request.post(api.stockListShipByBuyerId,params)
		},
		stockListByShipId(context,id){ //收获的明细
			return request.get(`${api.stockListByShipId}?shipId=${id}`)
		},
		stockPageIn(context,params){ //入库明细列表
			return request.post(api.stockPageIn,params)
		},
		stockInfo(context,id){ //入库出库详情
			return request.get(`${api.stockInfo}?id=${id}`)
		},
		stockPageOut(context,params){ //出库明细列表
			return request.post(api.stockPageOut,params)
		},
		stockOutStock(context,params){ //出库
			return request.post(api.stockOutStock,params)
		},
		stockListBySellerIdStockOut(context,params){ //查询我的销售单列表
			return request.get(api.stockListBySellerIdStockOut,{params})
		},
		stockListItemByDocumentId(context,id){ //查询我的销售单明细
			return request.get(`${api.stockListItemByDocumentId}?documentId=${id}`)
		},
		stockDownload(context,params){ //库房模板下载
			return request.get(api.stockDownload,params)
		},
		stockImportFile(context,params){  //库房表格导入
			return request.post(api.stockImportFile,params)
		},
		
		optLidaTemp(context,params){  //库房表格导入
			return request.get(api.optLidaTemp+"/"+params)
		},
		stockImport(context,params){  //库房表格导入
			return request.post(api.stockImport,params)
		},
		  // 下载excel
		  downloadExcel({ commit }, { url, fileName = 'excel' }) {
			return request.get(api.exportUrl + '/' + url, { responseType: 'arraybuffer' }).then(({ data }) => {
			  downloadExcel(data, fileName)
			  return data
			})
		  },
	}
}
