import homePage from '@/components/homePage/index.vue';

export default {
    path: '/supplierMessage',
    component: homePage,
    redirect: '/supplierMessage/index',
	meta:{title:"采购中心"},
    children: [
        {
            path: "index",
            component: () => import('@/module/supplierMessageModule/viewPage/index.vue'),
            meta: {title: "采购中心"},
            closable: true,
            active: true,
        },
    ]
}
