import homePage from '@/components/homePage/index.vue';
export default {
	path: "/home",
	component:homePage,
	redirect:"/home/index",
	children:[
		{
			path: "index",
			component:() => import('@/module/homeModule/viewPage/index.vue'),
			meta:{title:"首页"},
		},
	]
}